import React, { useEffect, useState } from "react";
import "./faqs.scss";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";


const Faqs = () => {
    const [activeKey, setActiveKey] = useState("0");
    const handleSelect = (key) => {
        setActiveKey(key);
    };
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [visibleGroups, setVisibleGroups] = useState(1);


    const handleLoadMore = () => {
        setVisibleGroups((prev) => prev + 1);
    };
    const handleShowLess = () => {
        setVisibleGroups(1);
      };

    return (
        <>
            <section className="mainfaqs faqs-miners">
                <div className="custom-container">
                    <div className="innerfaq">
                        <h2 className="head">{t('Nfqhead')} <span>{t('Nfqhead1')}</span></h2>
                        <Accordion activeKey={activeKey} onSelect={handleSelect}>
                        {visibleGroups >= 1 && (
                            <>
                            <Accordion.Item
                                eventKey="0"
                                className={activeKey === "0" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>01.</span> {t('Nfq1')}
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">

                                    {t('Nfa1')}
                                    </p>
                                    <ul>
                                        <li><span>{t('Nfa2')}</span></li>
                                        <li><span>{t('Nfa3')}</span></li>
                                        <li><span>{t('Nfa4')}</span></li>
                                    </ul>
                                    <p className="para">{t('Nfa5')}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="1"
                                className={activeKey === "1" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>02.</span> {t('Nfq2')}
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">{t('Nfqa21')}</p>
                                    <p className="para">{t('Nfqa22')}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="2"
                                className={activeKey === "2" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>03.</span>{t('Nfq3')}
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">{t('Nfa31')}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="3"
                                className={activeKey === "3" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>04.</span>{t('Nfq4')}
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">{t('Nfa41')}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="4"
                                className={activeKey === "4" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>05.</span>{t('Nfq5')}
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">{t('Nfa51')}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            </>
                        )}
{visibleGroups >= 2 && (
                            <>
                            <Accordion.Item
                                eventKey="5"
                                className={activeKey === "5" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>06.</span>{t('Nfq6')}
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">{t('Nfa61')}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="6"
                                className={activeKey === "6" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>07.</span>{t('Nfq7')}
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">{t('Nfa71')}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="7"
                                className={activeKey === "7" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>08.</span>{t('Nfq8')}
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">{t('Nfa81')}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="8"
                                className={activeKey === "8" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>09.</span>{t('Nfq9')}
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">{t('Nfa91')}</p>
                                    <p className="para">{t('Nfa92')} </p>
                                    <ul>
                                        <li>{t('Nfa93')}</li>
                                        <li>{t('Nfa94')}</li>
                                    </ul>
                                    <p className="para">{t('Nfa95')}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="9"
                                className={activeKey === "9" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>10.</span>{t('Nfq10')}
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>{t('Nfa101')}</li>
                                        <li>{t('Nfa102')}</li>
                                        <li>{t('Nfa103')}</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            </>
)}
{visibleGroups >= 3 && (
                            <>
                            <Accordion.Item
                                eventKey="10"
                                className={activeKey === "10" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>11.</span>{t('Nfq11')}
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">{t('Nfa111')}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="11"
                                className={activeKey === "11" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>12.</span>{t('Nfq12')}
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">{t('Nfa121')}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="12"
                                className={activeKey === "12" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>13.</span>{t('Nfq13')}
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">{t('Nfa131')}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="13"
                                className={activeKey === "13" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>14.</span>{t('Nfq14')}
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">{t('Nfa141')}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="14"
                                className={activeKey === "14" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>15.</span>{t('Nfq15')}
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">{t('Nfa151')}</p>
                                    <h6>{t('Nfa152')}</h6>
                                    <ul>
                                        <li>{t('Nfa153')}</li>
                                        <li>{t('Nfa154')}</li>
                                    </ul>
                                    <h6>{t('Nfa156')}</h6>
                                    <ul>
                                        <li>{t('Nfa157')}</li>
                                        <li>{t('Nfa158')}</li>
                                        <li>{t('Nfa159')}</li>
                                        <li>{t('Nfa1510')}</li>
                                    </ul>
                                    <h6>{t('Nfa1511')}</h6>
                                    <ul>
                                        <li>{t('Nfa1512')}</li>
                                        <li>{t('Nfa1513')}</li>
                                        <li>{t('Nfa1514')}</li>
                                    </ul>
                                    <h6>{t('Nfa1515')}</h6>
                                    <ul>
                                        <li>{t('Nfa1516')}</li>
                                        <li>{t('Nfa1517')}                                       </li>
                                    </ul>
                                    <h6>{t('Nfa1518')}</h6>
                                    <ul>
                                        <li>{t('Nfa1519')}</li>
                                        <li>{t('Nfa1520')}</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            </>)}
                            {visibleGroups >= 4 && (
                            <>
                            <Accordion.Item
                                eventKey="15"
                                className={activeKey === "15" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>16.</span>{t('Nfq16')}
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>{t('Nfa161')}</li>
                                        <li>{t('Nfa162')}</li>
                                        <li>{t('Nfa163')}</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="16"
                                className={activeKey === "16" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>17.</span>{t('Nfq17')}
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">{t('Nfa171')}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            {/* <Accordion.Item
                                eventKey="17"
                                className={activeKey === "17" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>18.</span>{t('Nfq18')}
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">{t('Nfa181')}</p>
                                </Accordion.Body>
                            </Accordion.Item> */}
                            <Accordion.Item
                                eventKey="18"
                                className={activeKey === "18" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>18.</span>{t('Nfq19')}
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">{t('Nfa191')}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="19"
                                className={activeKey === "19" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>19.</span>{t('Nfq20')}
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">{t('Nfa201')}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            </> )}

                            {visibleGroups >= 5 && (
                            <>
                            {/* <Accordion.Item
                                eventKey="20"
                                className={activeKey === "20" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>21.</span>What is a MultiMiner and how is it different from regular Miners?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">A MultiMiner generates tokens from multiple premium projects simultaneously, rather than just one project. The first MultiMiner includes four projects (Incentiv, Olympus AI, Prodex, and Rain) with different allocation percentages for each project. Token generation for each project starts from its respective TGE date.</p>
                                </Accordion.Body>
                            </Accordion.Item> */}
                            <Accordion.Item
                                eventKey="20"
                                className={activeKey === "21" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>20.</span>{t('Nfq21')}
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">{t('Nfa211')}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            {/* <Accordion.Item
                                eventKey="21"
                                className={activeKey === "22" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>22.</span>{t('Nfq22')}
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">{t('Nfa221')}</p>
                                </Accordion.Body>
                            </Accordion.Item> */}
                            <Accordion.Item
                                eventKey="22"
                                className={activeKey === "23" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>21.</span>{t('Nfq23')}
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">{t('Nfa231')}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            </>)}
                        </Accordion>



                        {visibleGroups * 5 < 22 ? (
                            <button className="btn-green btn-loadmore" onClick={handleLoadMore}>{t('Nloadmore')}</button>
                        ) : (
                            <button className="btn-green btn-loadmore" onClick={handleShowLess}>{t('Nloadless')}</button>
                        )}



                    </div>
                </div>
            </section>
        </>
    );
};

export default Faqs;