import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { nodeMinerContract } from "../../../../utils/contractHelpers";
import Environment from "../../../../utils/Environment";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";

const Information = () => {
  const { t } = useTranslation();
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const [showMore, setShowMore] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const months = Array.from({ length: 18 }, (_, i) => i + 1);
  const tokensReleased = [
    "3%", "4%", "4%", "4%", "4%", "5%", "6%", "7%", "8%", "9%", "10%", "11%",
    "7%", "7%", "5%", "3%", "2%", "1%"
  ];

  const visibleItems = isMobile && !showMore ? 5 : months.length;
  return (
    <>
      <section className="information-section">
        <div className="first-para" style={{ margin: "0" }}>
          <h2>
            {t('Gmp1')} <span></span>
          </h2>
          <p>
            {t('Gmp2')}
          </p>
          <p>
            {t('Gmp3')}
          </p>
          <p>
            {t('Gmp4')}
          </p>
        </div>
        <div className="first-para">
          <h6>{t('Gmp5')}</h6>
          <p>
            {t('Gmp6')}
          </p>
          <ul>
            <li>
              <span>{t('Gmp7')}</span>
            </li>
            <li>
              <span>{t('Gmp8')}</span>{" "}
            </li>
            <li>
              <span>{t('Gmp9')}</span>
            </li>
            <li>
              <span>{t('Gmp10')}</span>
            </li>
          </ul>
          <p>
            {t('Gmp11')}
          </p>
        </div>
        <div className="first-para">
          <h2>
            {t('Gmp12')} <span></span>
          </h2>
          <ul>
            <li>
              {t('Gmp13')}
            </li>
            <li>
              {t('Gmp14')}
            </li>
            <li>
              {t('Gmp15')}
            </li>
            <li>
              {t('Gmp16')}

            </li>
            <li>
              {t('Gmp17')}

            </li>
            <li>
              {t('Gmp18')}
            </li>
            <li>
              {t('Gmp19')}
            </li>
            <li>
              {t('Gmp20')}
            </li>
            <li>
              {t('Gmp21')}
            </li>
          </ul>
        </div>
        <div className="first-para">
          <h2>
            {t('Gmp22')}<span></span>
          </h2>
          <p>
            <span>
              {t('Gmp23')}
            </span>
          </p>
          <ul>
            <li>
              {t('Gmp24')}
            </li>
            <li>
              {t('Gmp25')}
            </li>
            <li>
              {t('Gmp26')}
            </li>
          </ul>
          <h6>{t('Gmp27')}</h6>
          <ul className="mt-3">
            <li>{t('Gmp28')}</li>
            <li>{t('Gmp29')}</li>
          </ul>
        </div>
        <div className="first-para">
          <h2>
            {t('Gmp30')}<span></span>
          </h2>
          <ul>
            <li>
              {t('Gmp31')}
            </li>
            <li>
              {t('Gmp32')}
            </li>
            <li>
              <span>
                {t('Gmp34')}{" "}
              </span>
            </li>
            <li>
              {t('Gmp35')}
            </li>
          </ul>
        </div>


        <div className="first-para">
          <h2>
            {t('Nonlinerartext')}<span></span>
          </h2>
          <div className="main-img" onClick={() => window.innerWidth <= 768 && setShow1(true)}
           >
            <img src="\assets\gemsnodeslanding\vesting\vestingdark.png" alt="img" className="img-fluid hide-in-light-theme" />
            <img src="\assets\gemsnodeslanding\vesting\vestinglight.png" alt="img" className="img-fluid d-none show-in-light-theme" />
          </div>
        </div>

        <div className="valuespermonth valuespermonthdetailpage">
          {
            window.innerWidth > 600 ?
              <>
                <div className="firstrow">
                  <h6>Months</h6>
                  {months.slice(0, visibleItems).map((month, index) => (
                    <p key={index}>{month}</p>
                  ))}
                </div>
                <div className="firstrow">
                  <h6>Tokens Released (%)</h6>
                  {tokensReleased.slice(0, visibleItems).map((token, index) => (
                    <p key={index}>{token}</p>
                  ))}
                </div>
              </> : <>
                <div className="firstrow">
                  <h6>Months</h6>
                  {months.slice(0, visibleItems).map((month, index) => (
                    <p key={index}>{month}</p>
                  ))}
                </div>
                <div className="firstrow">
                  <h6>Tokens Released (%)</h6>
                  {tokensReleased.slice(0, visibleItems).map((token, index) => (
                    <p key={index}>{token}</p>
                  ))}
                </div>
              </>
          }



        </div>
        {isMobile && (
          <button className="btn-showmorenodes" onClick={() => setShowMore(!showMore)}>
            {showMore ? "Show Less" : "Show More"}
          </button>
        )}





        <div className="first-para">
          <h2>
            {t('Gmp36')}<span></span>
          </h2>
          <ul>
            <li>
              <span>{t('Gmp37')}</span>
            </li>
            <li>
              <span>{t('Gmp38')}</span>
            </li>
            <li>
              <span>{t('Gmp39')}</span>
            </li>
            <li>
              <span>{t('Gmp40')}</span>
            </li>
            <li>
              <span>{t('Gmp41')}</span>
            </li>
            <li>
              <span>{t('Gmp42')}</span>
            </li>
          </ul>
        </div>
        <div className="first-para">
          <h2>
            {t('Gmp43')}<span></span>
          </h2>
          <ul>
            <li>
              {t('Gmp44')}
            </li>
            <li>
              {t('Gmp45')}
            </li>
            <li>

              {t('Gmp46')}
            </li>
            <li>
              {t('Gmp47')}
            </li>
          </ul>
        </div>
      </section>

      <Modal className='common-modal-style image-preview distribution-modl' show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-0 paddingsetmobile">
          <img src="\assets\gemsnodeslanding\vesting\vestingdark.png" alt="img" className="img-fluid hide-in-light-theme force-landscape" />
          <img src="\assets\gemsnodeslanding\vesting\vestinglight.png" alt="img" className="img-fluid d-none show-in-light-theme force-landscape" />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Information;
