import React, { useEffect } from "react";
import Navbar from "../../landing/header/Navbar";
import { Link } from "react-router-dom";
import Banner from "./Banner/Banner";

const ProdexDetailPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="wrapper-detailpage">
        <Navbar />
        <Banner />
      </div>
    </>
  );
};

export default ProdexDetailPage;
