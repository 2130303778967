import React, { useEffect, useState } from 'react'
import "./join.scss"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Join = () => {
    const {t} = useTranslation();
    const targetTime = 1738659600000;
    const [timeLeft, setTimeLeft] = useState(targetTime - Date.now());

    useEffect(() => {
        const timer = setInterval(() => {
            const remaining = targetTime - Date.now();
            setTimeLeft(remaining);
            if (remaining <= 0) {
                clearInterval(timer);
                setTimeLeft(0);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [targetTime]);

    const formatTime = (ms) => {
        const totalSeconds = Math.floor(ms / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return `${hours}h ${minutes}m ${seconds}s`;
    };
    return (
        <>
            <section className="miners-join">
                <img src="\assets\gemsnodeslanding\join\join-bg.png" alt="img" className='img-fluid join-bg' />
                <div className="custom-container">
                    <div className="main-content">
                        <h4>{t('joinfuture')} <br />
                            <span>{t('growth')}</span></h4>
                        <p>{t('buildpara')}</p>
                        {
                            timeLeft > 0 ? <div className="timer-set-miners m-auto">
                                <h6><span>Sale Starts in:</span> {formatTime(timeLeft)}</h6>
                            </div> : <div className="twice-btns">
                                <a
                                    href="https://buyminers.gems.vip/"
                                    target="_blank"
                                    className="btn-green"
                                >
                                    {t('btnmercury')}
                                </a>
                                <a
                                    href="https://buynodes.gems.vip/"
                                    target="_blank"
                                    className="btn-transparent"
                                >
                                    {t('btnnode')}
                                </a>
                            </div>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default Join