import React from "react";
import "./discover.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Discover = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="discover-section">
        <img
          src="\assets\landing\discover\discover-bg.png"
          alt="img"
          className="img-fluid discover-bg"
        />
        <div className="custom-container">
          <div className="parent">
            <div className="main-content">
              <h4>
                {t("Discover1")} <span>{t("Discover2")}</span>
              </h4>
              <p>{t("Discover3")}</p>
              <Link to="/launchpad" className="btn-green">
                {t("Discover4")}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Discover;
