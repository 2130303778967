import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "./useWeb3";
import environment from "../utils/Environment";
import { dopDynamicContract } from "../utils/contractHelpers";

export const LastClaimedDopMonth = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = environment.dopDynamic;
  const contract = dopDynamicContract(tokenAddress, web3);

  const LastClaimedMonth = useCallback(
    async (account) => {
      try {
        console.log("in last month jhook: ", account, contract, tokenAddress);

        var approved;
        approved = await contract.methods.lastClaimedMonth(account).call();
        console.log("in last month hook response: ", approved);

        return approved;
      } catch (err) {
        console.log("aganssaf", err);
      }
    },
    [account, contract]
  );

  return { LastClaimedMonth: LastClaimedMonth };
};

export default LastClaimedDopMonth;
