import React, { useEffect, useRef, useState } from "react";
import "./banner.scss";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Banner = () => {
  const { t } = useTranslation();

  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      video.muted = true; // Ensure muted for autoplay
      const playPromise = video.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            console.log("Autoplay started");
          })
          .catch((error) => {
            console.log("Autoplay prevented, user interaction needed", error);
          });
      }
    }
  }, []);



  const targetTime = 1738659600000;
  const [timeLeft, setTimeLeft] = useState(targetTime - Date.now());

  useEffect(() => {
    const timer = setInterval(() => {
      const remaining = targetTime - Date.now();
      setTimeLeft(remaining);
      if (remaining <= 0) {
        clearInterval(timer);
        setTimeLeft(0);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [targetTime]);

  const formatTime = (ms) => {
    const totalSeconds = Math.floor(ms / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <>
      <section className="main-banner main-bannernodesminers">
        <img
          src="\assets\landing\banner\banner-shadow.png"
          alt="img"
          className="img-fluid banner-shadow hide-in-light-theme"
        />
        <img
          src="\assets\landing\banner\banner-shadow-light.png"
          alt="img"
          className="img-fluid banner-shadow d-none show-in-light-theme"
        />
        <video
          className="main-banner-video hide-in-light-theme"
          muted="muted"
          playsinline="playsinline"
          autoPlay
          loop
          width="100%"
          id="myVideo"
        >
          <source src="\assets\videos\new-banner-video.mp4" type="video/mp4" />
        </video>
        <video
          className="main-banner-video d-none show-in-light-theme"
          muted="muted"
          playsinline="playsinline"
          autoPlay
          loop
          width="100%"
          id="myVideo"
        >
          <source
            src="\assets\videos\new-banner-video-light.mp4"
            type="video/mp4"
          />
        </video>
        <div className="custom-container">
          <div className="parent">
            <div className="left-side">
              <div className="main-content">
                <h5>Nodes & Miners</h5>
                <h4>
                  {t('Pathway')}
                  <br /> <span>{t('Future')}</span>
                </h4>
                <p>
                  {t('nodsandminerspara')}
                </p>
                {
                  timeLeft > 0 ? <div className="timer-set-miners">
                    <h6><span>Sale Starts in:</span> {formatTime(timeLeft)}</h6>
                  </div> : <div className="twice-btns">
                    <a
                      href="https://buyminers.gems.vip/"
                      target="_blank"
                      className="btn-green"
                    >
                      {t('btnmercury')}
                    </a>
                    <a
                      href="https://buynodes.gems.vip/"
                      target="_blank"
                      className="btn-transparent"
                    >
                      {t('btnnode')}
                    </a>
                  </div>
                }



              </div>
            </div>
            <div className="right-side">
              <div className="wrapper-img">
                {/* <img src="\assets\gemsnodeslanding\banner-img.png" alt="img" className="img-fluid" /> */}
                {/* <video
                  muted="muted"
                  playsinline="playsinline"
                  autoPlay
                  loop
                  width="100%"
                  id="myVideo"
                >
                  <source src="https://res.cloudinary.com/drt6vurtt/video/upload/v1738249093/gems/Introduction_to_Gems_Nodes_and_Miners_u0z4ao.mp4" type="video/mp4" />
                </video> */}
                <video
                  ref={videoRef}
                  playsInline
                  muted
                  autoPlay
                  loop
                  controls
                  width="100%"
                  id="myVideo"
                >
                  <source
                    src="https://res.cloudinary.com/drt6vurtt/video/upload/v1738249093/gems/Introduction_to_Gems_Nodes_and_Miners_u0z4ao.mp4"
                    type="video/mp4"
                  />
                </video>

              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  );
};

export default Banner;