import React, { useEffect, useState } from "react";
import BannerApply from "./BannerApply";
import "../Apply/apply.scss";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Api_Url } from "../../utils/ApiUrl";

const ApplyForMarketing = () => {
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [fullName, setFullName] = useState("");
  const [cvLoader, setCVLoader] = useState(false);

  const [email, setEmail] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [cvLink, setCVLink] = useState("");
  const [cvfile, setCVFile] = useState("");

  const countryList = [
    "Argentina",
    "Bahamas",
    "Bangladesh",
    "Belgium",
    "Brazil",
    "Canada",
    "China",
    "El Salvador",
    "Estonia",
    "France",
    "Germany",
    "India",
    "Indonesia",
    "Italy",
    "Japan",
    "Lithuania",
    "Luxembourg",
    "Malta",
    "Mexico",
    "Morocco",
    "Netherlands",
    "Nigeria",
    "Philippines",
    "Portugal",
    "Russia",
    "Singapore",
    "Slovenia",
    "South Africa",
    "South Korea",
    "Spain",
    "Switzerland",
    "Thailand",
    "Turkey",
    "UAE",
    "Ukraine",
    "United Kingdom",
    "Vietnam",
    "Other",
  ];
  const [errors, setErrors] = useState({});

  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const uploadCV = async (e) => {
    setCVLoader(true);
    const file = e.target.files[0];
    setCVFile(file);
    const formData = new FormData();
    if (file) {
      formData.append("document", file);
    }
    // const data = {
    //   countryLeaderPDF: file,
    // };
    var config = {
      method: "post",
      url: `${Api_Url}/users/pdf-upload`,
      data: formData,
    };
    await axios(config)
      .then((res) => {
        setCVLink(res?.data?.url);
        let tempError = errors;
        tempError.cvLink = "";
        setErrors(tempError);
        setCVLoader(false);
      })
      .catch((err) => {
        console.log("err response: ", err);
        let tempError = errors;
        tempError.cvLink = "File too large";
        setErrors(tempError);

        setCVLoader(false);
      });
  };

  const Apply = async () => {
    if (ValidateForm()) {
      const data = {
        fullName: fullName,
        email: email,
        linedInLink: linkedIn,
        documentUrl: cvLink,
      };
      var config = {
        method: "post",
        url: `${Api_Url}/temp/marketing-manager-form`,
        data: data,
      };
      await axios(config)
        .then((res) => {
          setCVFile("");
          setFullName("");
          setCVLink("");

          setEmail("");
          setLinkedIn("");

          handleShow1();
        })
        .catch((err) => {
          console.log("err response: ", err?.response?.data?.message);
          if (err?.response?.data?.message?.includes("email")) {
            let tempError = errors;
            tempError.email = "Email already exists";
            setErrors(tempError);
          }
        });
    }
  };

  function isValidEmail(email) {
    var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  }
  const validateLinkedInLink = (link) => {
    const regex = /^https:\/\/www\.linkedin\.com\/in\/[a-zA-Z0-9-_]+\/?$/;
    return regex.test(link);
  };

  const ValidateForm = () => {
    // let input = userInput;
    let errors = {};
    let isValid = true;

    if (!fullName) {
      isValid = false;
      errors["fullName"] = "*Please enter Full Name";
    }

    if (!email || !isValidEmail(email)) {
      isValid = false;
      errors["email"] = "*Please enter a valid Email Address";
    }

    if (!linkedIn || !validateLinkedInLink(linkedIn)) {
      isValid = false;
      errors["linkedIn"] = "*Please enter a valid linkedIn Account";
    }

    if (!cvLink) {
      isValid = false;
      errors["cvLink"] = "*Please upload a CV";
    }

    setErrors(errors);
    return isValid;
  };
  return (
    <>
      <div className="wrapper-apply">
        <BannerApply />
        <section className="apply-section">
          <div className="custom-container">
            <div className="parent">
              <div className="left-side paddingless">
                <div className="main-content">
                  <h4 style={{ fontSize: "26px", marginBottom: "25px" }}>
                    {/* {t("Marketman")} */}
                    {t("Applynew4")} <span>{t("Applynew5")}</span>
                  </h4>
                  <div className="location-div">
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.9999 14.1699C9.86988 14.1699 8.12988 12.4399 8.12988 10.2999C8.12988 8.15994 9.86988 6.43994 11.9999 6.43994C14.1299 6.43994 15.8699 8.16994 15.8699 10.3099C15.8699 12.4499 14.1299 14.1699 11.9999 14.1699ZM11.9999 7.93994C10.6999 7.93994 9.62988 8.99994 9.62988 10.3099C9.62988 11.6199 10.6899 12.6799 11.9999 12.6799C13.3099 12.6799 14.3699 11.6199 14.3699 10.3099C14.3699 8.99994 13.2999 7.93994 11.9999 7.93994Z"
                        fill="black"
                      />
                      <path
                        d="M11.9999 22.76C10.5199 22.76 9.02993 22.2 7.86993 21.09C4.91993 18.25 1.65993 13.72 2.88993 8.33C3.99993 3.44 8.26993 1.25 11.9999 1.25C11.9999 1.25 11.9999 1.25 12.0099 1.25C15.7399 1.25 20.0099 3.44 21.1199 8.34C22.3399 13.73 19.0799 18.25 16.1299 21.09C14.9699 22.2 13.4799 22.76 11.9999 22.76ZM11.9999 2.75C9.08993 2.75 5.34993 4.3 4.35993 8.66C3.27993 13.37 6.23993 17.43 8.91993 20C10.6499 21.67 13.3599 21.67 15.0899 20C17.7599 17.43 20.7199 13.37 19.6599 8.66C18.6599 4.3 14.9099 2.75 11.9999 2.75Z"
                        fill="black"
                      />
                    </svg> */}
                    <h6>{t("Applynew6")} </h6>
                  </div>
                  <h6>{t("Jobpurpose")}</h6>
                  <p className="paranew">{t("Marketpara1")}</p>
                  <p className="paranew">{t("Marketpara2")}</p>
                  <p className="paranew">{t("Marketpara3")}</p>
                  <h5 className="newheading">{t("A2")}</h5>
                  <ul>
                    <h6>1. {t("Marketstra")}</h6>
                    <li>{t("Marketstrapara1")}</li>
                    <li>{t("Marketstrapara2")}</li>
                  </ul>
                  <h6>2. {t("BrandManag")}</h6>
                  <p className="paranew">{t("Brandpara1")}</p>
                  <h6>3. {t("Compaignmana")}</h6>
                  <p className="paranew">{t("Compaignmanapara1")}</p>
                  <h6>4. {t("ContentCreation")}</h6>
                  <p className="paranew">{t("COntentpara1")}</p>
                  <h6>5. {t("Useraquision")}</h6>
                  <p className="paranew">{t("Userpara1")}</p>
                  <h6>6. {t("Analyticndreport")}</h6>
                  <p className="paranew">{t("Analyticpara1")}</p>
                  <h6>7. {t("EventManag")}</h6>
                  <p className="paranew">{t("Eventpara1")}</p>
                  {/* <h6>{t('Requndexp')}</h6>
                  <ul>
                    <li><span>{t('Bachdegree')}</span> {t('Bachdegrepara')} </li>
                    <li><span>{t('Expinmarmang')}</span> {t('Expinmarmangpara')}</li>
                    <li><span>{t('Strongunder')}</span> {t('Strongunderpara')} </li>
                    <li><span>{t('Provenrecord')}</span> {t('Provenrecordpara')}</li>
                    <li><span>{t('Excelcommuni')}</span></li>
                    <li><span>{t('Proficiencywithmarkettool')}</span> {t('Proficiencywithmarkettoolpara')}</li>
                  </ul>
                  <h6>{t('Personelatt')}</h6>
                  <ul>
                    <li><span>{t('Creativityndthinking')}</span></li>
                    <li><span>{t('Strongskill')}</span> {t('StrongskillPara')}</li>
                    <li><span>{t('Abilitytowork')}</span> {t('Abilitytoworkpara')}</li>
                    <li><span>{t('Highattentiontodetail')}</span> {t('Highattentiontodetailpara')}</li>
                    <li><span>{t('Passionfor')}</span> {t('Passionforpara')}</li>
                  </ul> */}
                </div>
              </div>
              <div className="right-side">
                <div className="apply-box" style={{ borderLeft: "none" }}>
                  <h6 className="main-head">
                    {t("Applyfor1")} <span>{t("Position")}</span>
                  </h6>
                  <div className="bottom-content">
                    <div className="option-field">
                      <input
                        type="text"
                        placeholder={t("Fullname")}
                        value={fullName}
                        onChange={(e) => {
                          const input = e.target.value;
                          setFullName(input);
                          let tempError = errors;
                          tempError.fullName = "";
                          setErrors(tempError);
                        }}
                      />
                      <div
                        style={{ position: "unset" }}
                        className="text-danger"
                      >
                        <small>{errors.fullName}</small>
                      </div>
                    </div>
                    <div className="option-field">
                      <input
                        type="text"
                        placeholder={t("Email")}
                        value={email}
                        onChange={(e) => {
                          const input = e.target.value;
                          setEmail(input);
                          let tempError = errors;
                          tempError.email = "";
                          setErrors(tempError);
                        }}
                      />
                      <div
                        style={{ position: "unset" }}
                        className="text-danger"
                      >
                        <small>{errors.email}</small>
                      </div>
                    </div>
                    <div className="option-field">
                      <input
                        type="text"
                        placeholder="Linkedin Profile"
                        value={linkedIn}
                        onChange={(e) => {
                          const input = e.target.value;
                          setLinkedIn(input);
                          let tempError = errors;
                          tempError.linkedIn = "";
                          setErrors(tempError);
                        }}
                      />
                      <div
                        style={{ position: "unset" }}
                        className="text-danger"
                      >
                        <small>{errors.linkedIn}</small>
                      </div>
                    </div>
                    <div className="apply-btn">
                      {cvLink ? (
                        <div className="set-after-upload">
                          <div className="inside-set">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M11.25 17.0625H6.75C2.6775 17.0625 0.9375 15.3225 0.9375 11.25V6.75C0.9375 2.6775 2.6775 0.9375 6.75 0.9375H10.5C10.8075 0.9375 11.0625 1.1925 11.0625 1.5C11.0625 1.8075 10.8075 2.0625 10.5 2.0625H6.75C3.2925 2.0625 2.0625 3.2925 2.0625 6.75V11.25C2.0625 14.7075 3.2925 15.9375 6.75 15.9375H11.25C14.7075 15.9375 15.9375 14.7075 15.9375 11.25V7.5C15.9375 7.1925 16.1925 6.9375 16.5 6.9375C16.8075 6.9375 17.0625 7.1925 17.0625 7.5V11.25C17.0625 15.3225 15.3225 17.0625 11.25 17.0625Z"
                                fill="#A4A4A4"
                              />
                              <path
                                d="M16.5 8.06249H13.5C10.935 8.06249 9.9375 7.06499 9.9375 4.49999V1.49999C9.9375 1.27499 10.0725 1.06499 10.2825 0.982492C10.4925 0.892492 10.7325 0.944992 10.8975 1.10249L16.8975 7.10249C17.055 7.25999 17.1075 7.50749 17.0175 7.71749C16.9275 7.92749 16.725 8.06249 16.5 8.06249ZM11.0625 2.85749V4.49999C11.0625 6.43499 11.565 6.93749 13.5 6.93749H15.1425L11.0625 2.85749Z"
                                fill="#A4A4A4"
                              />
                              <path
                                d="M9.75 10.3125H5.25C4.9425 10.3125 4.6875 10.0575 4.6875 9.75C4.6875 9.4425 4.9425 9.1875 5.25 9.1875H9.75C10.0575 9.1875 10.3125 9.4425 10.3125 9.75C10.3125 10.0575 10.0575 10.3125 9.75 10.3125Z"
                                fill="#A4A4A4"
                              />
                              <path
                                d="M8.25 13.3125H5.25C4.9425 13.3125 4.6875 13.0575 4.6875 12.75C4.6875 12.4425 4.9425 12.1875 5.25 12.1875H8.25C8.5575 12.1875 8.8125 12.4425 8.8125 12.75C8.8125 13.0575 8.5575 13.3125 8.25 13.3125Z"
                                fill="#A4A4A4"
                              />
                            </svg>
                            {cvfile?.name ? cvfile?.name : <>{t("Upload")}</>}
                            <input
                              type="file"
                              className="d-none"
                              id="upload"
                              accept=".pdf"
                              onChange={(e) => {
                                uploadCV(e);
                                let tempError = errors;
                                tempError.cvLink = "";
                                setErrors(tempError);
                              }}
                            />
                          </div>
                          <svg
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setCVFile("");
                              setCVLink("");
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M15.75 5.04748C15.735 5.04748 15.7125 5.04748 15.69 5.04748C11.7225 4.64998 7.7625 4.49998 3.84 4.89748L2.31 5.04748C1.995 5.07748 1.7175 4.85248 1.6875 4.53748C1.6575 4.22248 1.8825 3.95248 2.19 3.92248L3.72 3.77248C7.71 3.36748 11.7525 3.52498 15.8025 3.92248C16.11 3.95248 16.335 4.22998 16.305 4.53748C16.2825 4.82998 16.035 5.04748 15.75 5.04748Z"
                              fill="#FE0000"
                            />
                            <path
                              d="M6.375 4.29C6.345 4.29 6.315 4.29 6.2775 4.2825C5.9775 4.23 5.7675 3.9375 5.82 3.6375L5.985 2.655C6.105 1.935 6.27 0.9375 8.0175 0.9375H9.9825C11.7375 0.9375 11.9025 1.9725 12.015 2.6625L12.18 3.6375C12.2325 3.945 12.0225 4.2375 11.7225 4.2825C11.415 4.335 11.1225 4.125 11.0775 3.825L10.9125 2.85C10.8075 2.1975 10.785 2.07 9.99 2.07H8.025C7.23 2.07 7.215 2.175 7.1025 2.8425L6.93 3.8175C6.885 4.095 6.645 4.29 6.375 4.29Z"
                              fill="#FE0000"
                            />
                            <path
                              d="M11.4075 17.0626H6.5925C3.975 17.0626 3.87 15.6151 3.7875 14.4451L3.3 6.89256C3.2775 6.58506 3.5175 6.31506 3.825 6.29256C4.14 6.27756 4.4025 6.51006 4.425 6.81756L4.9125 14.3701C4.995 15.5101 5.025 15.9376 6.5925 15.9376H11.4075C12.9825 15.9376 13.0125 15.5101 13.0875 14.3701L13.575 6.81756C13.5975 6.51006 13.8675 6.27756 14.175 6.29256C14.4825 6.31506 14.7225 6.57756 14.7 6.89256L14.2125 14.4451C14.13 15.6151 14.025 17.0626 11.4075 17.0626Z"
                              fill="#FE0000"
                            />
                            <path
                              d="M10.245 12.9375H7.7475C7.44 12.9375 7.185 12.6825 7.185 12.375C7.185 12.0675 7.44 11.8125 7.7475 11.8125H10.245C10.5525 11.8125 10.8075 12.0675 10.8075 12.375C10.8075 12.6825 10.5525 12.9375 10.245 12.9375Z"
                              fill="#FE0000"
                            />
                            <path
                              d="M10.875 9.9375H7.125C6.8175 9.9375 6.5625 9.6825 6.5625 9.375C6.5625 9.0675 6.8175 8.8125 7.125 8.8125H10.875C11.1825 8.8125 11.4375 9.0675 11.4375 9.375C11.4375 9.6825 11.1825 9.9375 10.875 9.9375Z"
                              fill="#FE0000"
                            />
                          </svg>
                        </div>
                      ) : cvLoader ? (
                        <p>{t("Applynew7")}</p>
                      ) : (
                        <label htmlFor="upload">
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M12.7167 18.5417H7.28333C3.19167 18.5417 1.44167 16.7917 1.44167 12.7001V12.5917C1.44167 8.89173 2.9 7.1084 6.16667 6.80006C6.5 6.77506 6.81667 7.02506 6.85 7.36673C6.88333 7.7084 6.63333 8.01673 6.28333 8.05006C3.66667 8.29173 2.69167 9.52506 2.69167 12.6001V12.7084C2.69167 16.1001 3.89167 17.3001 7.28333 17.3001H12.7167C16.1083 17.3001 17.3083 16.1001 17.3083 12.7084V12.6001C17.3083 9.5084 16.3167 8.27506 13.65 8.05006C13.3083 8.01673 13.05 7.71673 13.0833 7.37506C13.1167 7.0334 13.4083 6.77506 13.7583 6.8084C17.075 7.09173 18.5583 8.8834 18.5583 12.6084V12.7167C18.5583 16.7917 16.8083 18.5417 12.7167 18.5417Z"
                              fill="black"
                            />
                            <path
                              d="M10 13.1249C9.65833 13.1249 9.375 12.8416 9.375 12.4999V3.0166C9.375 2.67493 9.65833 2.3916 10 2.3916C10.3417 2.3916 10.625 2.67493 10.625 3.0166V12.4999C10.625 12.8416 10.3417 13.1249 10 13.1249Z"
                              fill="black"
                            />
                            <path
                              d="M12.7917 5.50003C12.6333 5.50003 12.475 5.4417 12.35 5.3167L10 2.9667L7.65 5.3167C7.40834 5.55837 7.00834 5.55837 6.76667 5.3167C6.525 5.07503 6.525 4.67503 6.76667 4.43337L9.55834 1.6417C9.8 1.40003 10.2 1.40003 10.4417 1.6417L13.2333 4.43337C13.475 4.67503 13.475 5.07503 13.2333 5.3167C13.1167 5.4417 12.95 5.50003 12.7917 5.50003Z"
                              fill="black"
                            />
                          </svg> */}
                          {cvfile?.name && cvLink ? (
                            cvfile?.name
                          ) : (
                            <>{t("Upload")}</>
                          )}
                          <input
                            type="file"
                            className="d-none"
                            id="upload"
                            accept=".pdf"
                            onChange={(e) => {
                              uploadCV(e);
                              let tempError = errors;
                              tempError.cvLink = "";
                              setErrors(tempError);
                            }}
                          />
                        </label>
                      )}
                      <div
                        style={{ position: "unset" }}
                        className="text-danger"
                      >
                        <small>{errors.cvLink}</small>
                      </div>
                    </div>
                    <button
                      className="btn-green submit-btn"
                      disbaled={cvLoader == true ? true : false}
                      onClick={Apply}
                    >
                      {t("Submit")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal
        className="common-modal-style addteammembermodal"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("M2")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="addedmodal">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="89"
              height="88"
              viewBox="0 0 89 88"
              fill="none"
            >
              <path
                d="M77.084 37.9845L77.084 11.417L35.6306 51.7939L10.918 34.7697L10.918 59.3998L35.6306 77.583L77.084 37.9845Z"
                fill="#D1DADD"
              />
            </svg>
            <h6 className="addedhead">{t("M1")}</h6>
            <button className="btn-green w-100" onClick={() => handleClose1()}>
              {t("M3")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ApplyForMarketing;
