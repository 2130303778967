import React, { useState, useEffect } from "react";
import "./claimschedule.scss";
import Navbar from "../landing/header/Navbar";
import { Link, useHistory } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { Api_Url_Leia_Claims } from "../../utils/ApiUrl";
import useAuth from "../../hooks/useAuth";
import axios from "axios";
import List from "./listSchedule";

import LastClaimedDopMonth from "../../hooks/LastClaimedMoneth";
import { dopDynamicContract } from "../../utils/contractHelpers";
import Web3 from "web3";
import Environment from "../../utils/Environment";

const ClaimSchedule = () => {
  const [loader, setLoader] = useState(false);
  const [profile, setProfile] = useState(null);
  const [five, setFive] = useState(0);
  const [eight, setEight] = useState(0);
  const [last, setLast] = useState(0);

  const [lastClaimedMonth, setLastClaimedMonth] = useState(false);
  const [claimedLeia, setClaimedLeia] = useState(0);
  const [remainingClaim, setRemainingClaim] = useState(0);

  const { account } = useWeb3React();
  const { logout } = useAuth();

  const { LastClaimedMonth } = LastClaimedDopMonth();
  const history = useHistory();

  const getLastClaimedMonth = async (account) => {
    const web3 = new Web3(
      "https://cold-responsive-friday.quiknode.pro/15bbbcf85ec27b075486f39524c5741f49222932/"
      // "https://eth-sepolia.g.alchemy.com/v2/n_4iaIl35yi2Vc0AV3-eg9rsdbG95Tfg"
    );
    const tokenAddress = Environment.dopDynamic;

    const contract = dopDynamicContract(tokenAddress, web3);

    try {
      let res = await contract.methods.lastClaimedMonth(account).call();
      if (res) {
        setLastClaimedMonth(res);
      }
    } catch (err) {
      console.log("errxxxxx", err);
    }

    // const lastMonth = await LastClaimedMonth(account);
    // console.log("kjbkjkkj:", lastMonth);
    // setLastClaimedMonth(lastMonth);
  };

  useEffect(() => {
    if (account) {
      getProfile();
      getLastClaimedMonth(account);
    }
  }, [account]);

  useEffect(() => {
    let timeoutId;
    if (!account) {
      timeoutId = setTimeout(() => {
        updateWallet();
      }, 2000);
    }

    // Cleanup the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, [account]);

  const updateWallet = async () => {
    if (!account) {
      const locaWallet = localStorage.getItem("connectorId");
      console.log("here");
      await logout(locaWallet);
      localStorage.clear();
      history.push("/");
    }
  };

  const getProfile = async () => {
    let token = localStorage.getItem("dopToken");
    setLoader(true);
    var config = {
      method: "get",
      url: `${Api_Url_Leia_Claims}/users/profile`,

      headers: {
        Authorization: "Bearer " + token,
      },
      data: {},
    };
    await axios(config)
      .then(function (res) {
        const resss = res?.data.data;
        let date = new Date(resss?.rewardCreatedAt).toLocaleDateString();
        setProfile(resss);
        setLoader(false);
      })
      .catch(function (err) {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (profile) {
      let sum = profile?.dopPurchased + profile?.dopPurchasedByNft;
      let fi = sum * 0.05;
      let ei = sum * 0.0864;
      let li = sum * 0.0859;
      setFive(fi);
      setEight(ei);
      setLast(li);
    }
  }, [profile]);

  const schedule = [
    {
      id: 1,
      date: "2025-01-07T10:00:00Z",
      percentage: 5,
      claimContract: "0xF49e4197324059e819690a0588dec5AB25d3985C",
    },
    {
      id: 2,
      date: "2025-02-07T10:00:00Z",
      percentage: 8.64,
      claimContract: "0xec135D3aEe90a5119681Ce4317BA42281edA7b49",
    },
    {
      id: 3,
      date: "2025-03-07T10:00:00Z",
      percentage: 8.64,
      claimContract: "0xDC75915aE34C4Ba788dd31Da531f449773d717B9",
    },
    {
      id: 4,
      date: "2025-04-07T15:30:00Z",
      percentage: 8.64,
      claimContract: "0x58898b131e9e48E73af56d2357EbAA2f72Bc3dCD",
    },
    {
      id: 5,
      date: "2025-05-07T15:35:00Z",
      percentage: 8.64,
      claimContract: "0x58898b131e9e48E73af56d2357EbAA2f72Bc3dCD",
    },
    {
      id: 6,
      date: "2025-06-07T15:40:00Z",
      percentage: 8.64,
      claimContract: "0x58898b131e9e48E73af56d2357EbAA2f72Bc3dCD",
    },
    {
      id: 7,
      date: "2025-07-07T15:45:00Z",
      percentage: 8.64,
      claimContract: "0x58898b131e9e48E73af56d2357EbAA2f72Bc3dCD",
    },
    {
      id: 8,
      date: "2025-08-07T15:50:00Z",
      percentage: 8.64,
      claimContract: "0x58898b131e9e48E73af56d2357EbAA2f72Bc3dCD",
    },
    {
      id: 9,
      date: "2025-09-07T15:55:00Z",
      percentage: 8.64,
      claimContract: "0x58898b131e9e48E73af56d2357EbAA2f72Bc3dCD",
    },
    {
      id: 10,
      date: "2025-10-07T16:00:00Z",
      percentage: 8.64,
      claimContract: "0x58898b131e9e48E73af56d2357EbAA2f72Bc3dCD",
    },
    {
      id: 11,
      date: "2025-11-07T16:05:00Z",
      percentage: 8.64,
      claimContract: "0x58898b131e9e48E73af56d2357EbAA2f72Bc3dCD",
    },
    {
      id: 12,
      date: "2025-12-07T16:10:00Z",
      percentage: 8.59,
      claimContract: "0x58898b131e9e48E73af56d2357EbAA2f72Bc3dCD",
    },
  ];

  return (
    <>
      <Navbar />
      <section className="claim-schedule">
        <div className="custom-container">
          <div className="main-head">
            <Link to="/mypurchases">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <path
                  d="M7.38892 14.5645L4.88751 14.5645L1.49447 8.50318L3.99587 8.50318L7.38892 14.5645Z"
                  fill="#30F1B6"
                />
                <path
                  d="M7.38892 2.42969L4.88751 2.42969L1.49447 8.49096L3.07096 9.53883L3.99587 8.49096L7.38892 2.42969Z"
                  fill="#30F1B6"
                />
                <path
                  d="M13.1867 9.64258L6.24165 9.64258L7.51637 7.35687L14.4614 7.35687L13.1867 9.64258Z"
                  fill="#30F1B6"
                />
              </svg>
              Back
            </Link>
            <div className="twice-elem">
              <p>Vested Claim</p>
              <h6>
                <img
                  src="/assets/presaletokens/leia.png"
                  alt="img"
                  className="img-fluid"
                />
                LEIA
              </h6>
            </div>
          </div>
          <div className="inline-elem">
            <div className="left-side">
              <div className="single single-first">
                <div className="left-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M13.1295 19.4613C10.2195 21.1414 6.49861 20.1444 4.81856 17.2344C3.13851 14.3245 4.13553 10.6036 7.04546 8.92354C9.95539 7.24349 13.6763 8.24051 15.3564 11.1504C17.0364 14.0604 16.0394 17.7813 13.1295 19.4613Z"
                      stroke="#D1DADD"
                      stroke-width="1.62529"
                    />
                    <path
                      d="M10.3086 5.85899C12.9007 3.72095 16.7353 4.08905 18.8733 6.68117C21.0113 9.27328 20.6432 13.1078 18.0511 15.2459"
                      stroke="#D1DADD"
                      stroke-width="1.62529"
                    />
                    <rect
                      x="10.0503"
                      y="11.5636"
                      width="3.816"
                      height="3.816"
                      transform="rotate(45 10.0503 11.5636)"
                      fill="#D1DADD"
                    />
                  </svg>
                </div>
                <div className="twice-text">
                  <p>Tokens Purchased</p>
                  <h6>
                    {" "}
                    {(
                      profile?.dopPurchased + profile?.dopPurchasedByNft
                    )?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    LEIA
                  </h6>
                </div>
              </div>
              <div className="single single-second">
                <div className="left-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11 15H13C13.5304 15 14.0391 14.7893 14.4142 14.4142C14.7893 14.0391 15 13.5304 15 13C15 12.4696 14.7893 11.9609 14.4142 11.5858C14.0391 11.2107 13.5304 11 13 11H10C9.4 11 8.9 11.2 8.6 11.6L3 17"
                      stroke="#D1DADD"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7 21L8.6 19.6C8.9 19.2 9.4 19 10 19H14C15.1 19 16.1 18.6 16.8 17.8L21.4 13.4C21.7859 13.0353 22.0111 12.5323 22.0261 12.0016C22.0411 11.4708 21.8447 10.9559 21.48 10.57C21.1153 10.1841 20.6123 9.95889 20.0816 9.94389C19.5508 9.92888 19.0359 10.1253 18.65 10.49L14.45 14.39"
                      stroke="#D1DADD"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2 16L8 22"
                      stroke="#D1DADD"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16.0001 11.9C17.6017 11.9 18.9001 10.6016 18.9001 9.00001C18.9001 7.39838 17.6017 6.10001 16.0001 6.10001C14.3985 6.10001 13.1001 7.39838 13.1001 9.00001C13.1001 10.6016 14.3985 11.9 16.0001 11.9Z"
                      stroke="#D1DADD"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6 8C7.65685 8 9 6.65685 9 5C9 3.34315 7.65685 2 6 2C4.34315 2 3 3.34315 3 5C3 6.65685 4.34315 8 6 8Z"
                      stroke="#D1DADD"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="twice-text">
                  <p>Tokens Claimed</p>
                  <h6>
                    {" "}
                    {claimedLeia.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    LEIA
                  </h6>
                </div>
              </div>
              <div className="single single-third">
                <div className="left-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
                      stroke="#D1DADD"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.0901 10.37C19.0354 10.7224 19.8766 11.3075 20.5358 12.0712C21.1951 12.8349 21.6512 13.7524 21.8618 14.7391C22.0725 15.7257 22.031 16.7495 21.7411 17.7158C21.4513 18.6822 20.9224 19.5598 20.2035 20.2676C19.4846 20.9754 18.5988 21.4905 17.6281 21.7652C16.6573 22.04 15.633 22.0655 14.6498 21.8395C13.6666 21.6134 12.7562 21.1431 12.0029 20.472C11.2496 19.8009 10.6777 18.9507 10.3401 18"
                      stroke="#D1DADD"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7 6H8V10"
                      stroke="#D1DADD"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16.7101 13.88L17.4101 14.59L14.5901 17.41"
                      stroke="#D1DADD"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="twice-text">
                  <p>Tokens Pending Claim</p>
                  <h6>
                    {remainingClaim.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    LEIA
                  </h6>
                </div>
              </div>
            </div>
            {/* <div className="right-side">
              <div className="claim-box">
                <p>Claim all pending tokens</p>
                <button className="btn-claim">Claim All $LEIA</button>
              </div>
            </div> */}
          </div>
          <div className="bottom-content">
            <h6 className="head-text">Claiming Schedule</h6>
            <div className="table-responsive claiming-schedule-table">
              <table class="table">
                <thead>
                  <tr>
                    <th>
                      <div className="parent-tag">Claim No.</div>
                    </th>
                    <th>
                      <div className="parent-tag text-center">Date</div>
                    </th>
                    <th>
                      <div className="parent-tag text-center">Percentage</div>
                    </th>
                    <th>
                      <div className="parent-tag text-center">
                        CLAIM AVAILABLE IN
                      </div>
                    </th>
                    <th>
                      <div className="parent-tag text-center">Amount</div>
                    </th>
                    <th>
                      <div className="parent-tag text-center">ACTION</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {schedule?.map((item, index) => {
                    return (
                      <List
                        five={five}
                        eight={eight}
                        last={last}
                        item={item}
                        index={index}
                        setClaimedLeia={setClaimedLeia}
                        setRemainingClaim={setRemainingClaim}
                        totalLeia={
                          profile?.dopPurchased + profile?.dopPurchasedByNft
                        }
                        lastClaimedMonth={lastClaimedMonth}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ClaimSchedule;
