import React, { useEffect, useState } from 'react'
import Information from '../Information/Information'
import { useTranslation } from 'react-i18next'
import Document from '../Documents/Document';
import GetBalance from "../../../../hooks/DataFetchers/getBalance";
import CuurentRound from "../../../../hooks/DataFetchers/round";
import Timer from "../../../../hooks/DataFetchers/time.js";
import CuurentRoundl from "../../../../hooks/DataFetchers/roundl";
import Timerl from "../../../../hooks/DataFetchers/timel.js";
import axios from "axios";
import { ProgressBar } from 'react-bootstrap';
const Banner = () => {
  const { t } = useTranslation()

  return (
    <>
      <section className="banner-detailpage">
        <img src="\assets\alldetailpages\nodes\banner\banner-bg.png" alt="img" className='img-fluid banner-bg' />
        <div className="custom-container">
          <div className="main-logo">
            <img src="\assets\alldetailpages\nodes\banner\main-logo.png" alt="img" className='img-fluid' />
          </div>
        </div>
      </section>
      <section className="bottom-part-section">
        <div className="custom-container">
          <div className="parent">
            <div className="left-side">
              <div className="token-info-box">
                <div className="token-img">
                  <img src="\assets\alldetailpages\nodes\banner\token-logo.png" alt="img" className='img-fluid' />
                </div>
                <div className="token-title">
                  <h6>Gems Nodes</h6>
                  <p>$GMN</p>
                </div>
                {/* <div className="social-icons">
                  <a href="#" target='_blank'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <g clip-path="url(#clip0_756_4270)">
                      <path d="M10.0766 9.08958C10.1741 8.39734 10.2224 7.69908 10.2214 7.00002C10.2224 6.30096 10.1741 5.6027 10.0766 4.91046H12.8134C13.2971 6.26152 13.2971 7.73852 12.8134 9.08958H10.0766ZM6.9997 13.1756C6.39662 13.1756 5.78099 12.5566 5.3089 11.4775C5.09324 10.9847 4.91762 10.4229 4.78615 9.81304H9.21325C9.08178 10.4226 8.90616 10.9845 8.6905 11.4775C8.21841 12.5566 7.60278 13.1756 6.9997 13.1756ZM4.65395 9.08958C4.45067 7.70396 4.45067 6.29609 4.65395 4.91046H9.34545C9.44825 5.60221 9.49921 6.30068 9.49791 7.00002C9.49921 7.69937 9.44825 8.39783 9.34545 9.08958H4.65395ZM1.18599 9.08958C0.702326 7.73852 0.702326 6.26152 1.18599 4.91046H3.92278C3.72947 6.2968 3.72947 7.70325 3.92278 9.08958H1.18599ZM6.9997 0.824463C7.60278 0.824463 8.21841 1.44322 8.6905 2.5225C8.90616 3.01534 9.08178 3.57717 9.21325 4.18701H4.78615C4.91762 3.57741 5.09324 3.01558 5.3089 2.5225C5.78099 1.44322 6.39662 0.824463 6.9997 0.824463ZM12.4998 4.18677H9.95191C9.80717 3.4703 9.60598 2.80909 9.35365 2.23278C9.14933 1.76527 8.91774 1.36941 8.66421 1.0505C9.48632 1.27817 10.252 1.67463 10.9124 2.21461C11.5728 2.7546 12.1134 3.42625 12.4998 4.18677ZM5.33519 1.05026C5.08238 1.36941 4.85104 1.76503 4.64647 2.2323C4.39415 2.80909 4.1932 3.4703 4.04822 4.18628H1.49959C1.8861 3.42586 2.42677 2.75429 3.08715 2.21435C3.74753 1.67442 4.51314 1.27797 5.33519 1.05026ZM1.49959 9.81328H4.04749C4.19223 10.5297 4.39342 11.191 4.64575 11.7673C4.85032 12.2345 5.08166 12.6302 5.33447 12.9493C4.51251 12.7216 3.74701 12.3251 3.08675 11.7852C2.42649 11.2452 1.88596 10.5737 1.49959 9.81328ZM8.66421 12.9493C8.91702 12.6302 9.1486 12.2345 9.35293 11.7673C9.60526 11.1905 9.8062 10.5293 9.95118 9.81328H12.4998C12.1134 10.5738 11.5728 11.2455 10.9124 11.7855C10.252 12.3256 9.48633 12.7221 8.66421 12.9498V12.9493Z" fill="#657B82" />
                    </g>
                    <defs>
                      <clipPath id="clip0_756_4270">
                        <rect width="14" height="14" fill="white" />
                      </clipPath>
                    </defs>
                  </svg></a>
                  <a href="#" target='_blank'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path d="M10.6423 1.3125H12.5719L8.35619 6.13083L13.3157 12.6875H9.43245L6.39095 8.71092L2.91078 12.6875H0.979945L5.48911 7.53375L0.731445 1.3125H4.71328L7.46253 4.94725L10.6423 1.3125Z" fill="#657B82" />
                  </svg></a>
                  <a href="#" target='_blank'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <g clip-path="url(#clip0_756_4279)">
                      <path d="M5.49335 8.85566L5.26177 12.113C5.5931 12.113 5.7366 11.9707 5.90869 11.7997L7.4621 10.3152L10.6809 12.6724C11.2713 13.0014 11.6872 12.8282 11.8464 12.1293L13.9593 2.22899L13.9599 2.22841C14.1471 1.35574 13.6443 1.01449 13.0691 1.22857L0.649937 5.98332C-0.197646 6.31232 -0.184813 6.78482 0.505854 6.99891L3.68094 7.98649L11.056 3.37174C11.4031 3.14191 11.7187 3.26907 11.4591 3.49891L5.49335 8.85566Z" fill="#657B82" />
                    </g>
                    <defs>
                      <clipPath id="clip0_756_4279">
                        <rect width="14" height="14" fill="white" />
                      </clipPath>
                    </defs>
                  </svg></a>
                  <a href="#" target='_blank'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path d="M7.89677 6.99989C7.89677 9.19549 6.12902 10.9754 3.94838 10.9754C1.76775 10.9754 0 9.19549 0 6.99989C0 4.8043 1.76775 3.02441 3.94838 3.02441C6.12899 3.02441 7.89677 4.8043 7.89677 6.99989Z" fill="#657B82" />
                    <path d="M12.2287 6.99978C12.2287 9.06656 11.3448 10.742 10.2545 10.742C9.16416 10.742 8.28027 9.06656 8.28027 6.99978C8.28027 4.933 9.16416 3.25757 10.2545 3.25757C11.3448 3.25757 12.2287 4.933 12.2287 6.99978Z" fill="#657B82" />
                    <path d="M14 6.99984C14 8.85158 13.6891 10.3527 13.3056 10.3527C12.9221 10.3527 12.6113 8.85158 12.6113 6.99984C12.6113 5.14809 12.9221 3.64697 13.3056 3.64697C13.6891 3.64697 14 5.14809 14 6.99984Z" fill="#657B82" />
                  </svg></a>
                  <a href="#" target='_blank'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path d="M13.2852 3.71944C13.134 3.15716 12.6906 2.71392 12.1284 2.56252C11.1014 2.28149 6.993 2.28149 6.993 2.28149C6.993 2.28149 2.88479 2.28149 1.85772 2.55186C1.30633 2.70311 0.852196 3.15724 0.700951 3.71944C0.430664 4.74644 0.430664 6.87629 0.430664 6.87629C0.430664 6.87629 0.430664 9.01687 0.700951 10.0331C0.852356 10.5953 1.29552 11.0386 1.8578 11.19C2.89561 11.4711 6.99316 11.4711 6.99316 11.4711C6.99316 11.4711 11.1014 11.4711 12.1284 11.2007C12.6907 11.0494 13.134 10.6061 13.2854 10.0439C13.5556 9.01687 13.5556 6.8871 13.5556 6.8871C13.5556 6.8871 13.5664 4.74644 13.2852 3.71944ZM5.68499 8.84391V4.90866L9.1013 6.87629L5.68499 8.84391Z" fill="#657B82" />
                  </svg></a>
                  <a href="#" target='_blank'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <g clip-path="url(#clip0_756_4296)">
                      <path d="M11.8516 2.54914C10.9593 2.13972 10.0024 1.83806 9.00197 1.66528C8.98375 1.66194 8.96554 1.67028 8.95616 1.68693C8.83309 1.90582 8.69678 2.19134 8.60133 2.41578C7.52527 2.25467 6.4547 2.25467 5.40071 2.41578C5.30523 2.18637 5.16397 1.90582 5.04035 1.68693C5.03097 1.67083 5.01276 1.66249 4.99455 1.66528C3.99464 1.83749 3.03777 2.13914 2.14489 2.54914C2.13715 2.55247 2.13054 2.55802 2.12613 2.56524C0.311165 5.27678 -0.186054 7.92169 0.0578526 10.5338C0.0589464 10.5466 0.0661378 10.5588 0.0760636 10.5665C1.27353 11.4459 2.4335 11.9798 3.57191 12.3337C3.59012 12.3392 3.60942 12.3326 3.62102 12.3176C3.8903 11.9498 4.13035 11.5621 4.33616 11.1543C4.34831 11.1304 4.33671 11.1021 4.31188 11.0926C3.93112 10.9482 3.56857 10.7721 3.2198 10.5721C3.19221 10.556 3.19 10.5166 3.2154 10.4977C3.28879 10.4427 3.36221 10.3854 3.43229 10.3277C3.44498 10.3171 3.46264 10.3149 3.47754 10.3215C5.76873 11.3676 8.24922 11.3676 10.5134 10.3215C10.5283 10.3143 10.5459 10.3166 10.5592 10.3271C10.6293 10.3849 10.7027 10.4426 10.7766 10.4976C10.802 10.5165 10.8003 10.556 10.7727 10.5721C10.424 10.776 10.0614 10.9482 9.68012 11.0921C9.65529 11.1015 9.64424 11.1304 9.65641 11.1543C9.86666 11.5615 10.1067 11.9492 10.371 12.317C10.3821 12.3326 10.4019 12.3392 10.4201 12.3337C11.564 11.9798 12.724 11.4459 13.9215 10.5665C13.932 10.5587 13.9386 10.5471 13.9397 10.5343C14.2316 7.51443 13.4508 4.89121 11.8698 2.56576C11.8659 2.55802 11.8593 2.55247 11.8516 2.54914ZM4.67837 8.94328C3.98857 8.94328 3.42018 8.31 3.42018 7.53223C3.42018 6.75447 3.97752 6.12119 4.67837 6.12119C5.38469 6.12119 5.94759 6.76005 5.93654 7.53223C5.93654 8.31 5.37916 8.94328 4.67837 8.94328ZM9.33028 8.94328C8.64051 8.94328 8.07211 8.31 8.07211 7.53223C8.07211 6.75447 8.62946 6.12119 9.33028 6.12119C10.0366 6.12119 10.5995 6.76005 10.5885 7.53223C10.5885 8.31 10.0367 8.94328 9.33028 8.94328Z" fill="#657B82" />
                    </g>
                    <defs>
                      <clipPath id="clip0_756_4296">
                        <rect width="14" height="14" fill="white" />
                      </clipPath>
                    </defs>
                  </svg></a>
                </div> */}
              </div>
              <div className="presale-box">
                {/* <div className="timer-parent">
                  <h6 className='main-head'>Presale Ends In</h6>
                  <div className="timer">
                    <div className="text">
                      <h6>0</h6>
                      <p>Days</p>
                    </div>
                    <span>:</span>
                    <div className="text">
                      <h6>0</h6>
                      <p>Hours</p>
                    </div>
                    <span>:</span>
                    <div className="text">
                      <h6>0</h6>
                      <p>MINUTES</p>
                    </div>
                    <span>:</span>
                    <div className="text">
                      <h6>0</h6>
                      <p>Seconds</p>
                    </div>
                  </div>
                </div> */}
                <div className="presale-detail">
                  <h6 className="main-head">Presale details</h6>
                  <div className="bottom-detail">
                    <div className="twice-text">
                      <p>Status</p>
                      <h6 style={{ color: "var(--btn-green-bg)" }}>Live</h6>
                    </div>
                    <div className="twice-text">
                      <p>Price</p>
                      <h6>$99</h6>
                    </div>
                  </div>
                  {/* <div className="hardcap-ifexist">
                    <div className="upper-detail">
                      <p>Hard Cap</p>
                      <h6><span>125,234,134</span>/285,714,285 OAI</h6>
                    </div>
                    <ProgressBar now={60} />
                  </div> */}
                  <a href="https://buynodes.gems.vip/" target="_blank" className="btn-green btn-buy">Buy Now</a>
                </div>
              </div>
            </div>
            <div className="right-side">
              <ul class="nav nav-tabs banner-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">{t('Gems-detailt2')}</button>
                </li>
                {/* <li class="nav-item" role="presentation">
                  <button class="nav-link" id="document-tab" data-bs-toggle="tab" data-bs-target="#document-tab-pane" type="button" role="tab" aria-controls="document-tab-pane" aria-selected="false">{t('Gems-detailt4')}</button>
                </li> */}
                {/* <li class="nav-item" role="presentation">
                                <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">{t('Gems-detailt3')}</button>
                            </li> */}
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0"><Information /></div>
                {/* <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0"><Media /></div> */}
                <div class="tab-pane fade" id="document-tab-pane" role="tabpanel" aria-labelledby="document-tab" tabindex="0"><Document /></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Banner
