import React, { useEffect, useState } from "react";
import "./signupproject.scss";
import { Modal } from "react-bootstrap";
import { Api_Url } from "../../utils/ApiUrl";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import BannerApply from "./BannerApply";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const Signupproject = () => {
  const { t } = useTranslation();

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [loader, setLoader] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [selectedCompanySize, setSelectedCompanySize] = useState(
    t("Signupforproject-t4")
  );
  const [companyError, setCompanyError] = useState("");
  const [nameError, setNameError] = useState("");
  const [twitter, setTwitter] = useState("");
  const [twitterError, setTwitterError] = useState("");
  const [telegram, setTelegram] = useState("");
  const [telegramError, setTelegramError] = useState("");
  const [email, setEmail] = useState("");
  const [emailerrorregister, setEmailErrorRegister] = useState("");
  const [projectIndustry, setProjectIndustry] = useState("");
  const [projectIndustryError, setProjectIndustryError] = useState("");
  const [website, setWebsite] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [name, setname] = useState("");
  const [numberError, setNumberError] = useState("");
  const [position, setPosition] = useState("");
  const [positionError, setPositionError] = useState("");

  const handleItemClick = (size) => {
    setSelectedCompanySize(size);
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  // Function to validate social media links
  const isValidSocialLink = (link, type) => {
    let regex;
    if (type === "twitter") {
      regex =
        /^(?:https?:\/\/)?(?:www\.)?(twitter\.com|x\.com)\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/i;
    } else if (type === "telegram") {
      regex = /^(?:https?:\/\/)?t\.me\/\+?[a-zA-Z0-9\-_]+$/;
    } else if (type === "website") {
      regex =
        /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(?:\/\S*)?$/i;
    }
    return regex.test(link);
  };

  const submit = (e) => {
    e.preventDefault();
    if (email.length === 0) {
      setEmailErrorRegister(t("Signupforproject-e1"));
    } else if (!isValidEmail(email)) {
      setEmailErrorRegister(t("Signupforproject-e2"));
    }
    if (projectName.length === 0) {
      setNameError(t("Signupforproject-e3"));
    }
    if (website.length === 0) {
      setWebsiteError(t("Signupforproject-e4"));
    } else if (!isValidSocialLink(website, "website")) {
      setWebsiteError(t("Signupforproject-e5"));
    }
    if (name.length === 0) {
      setNumberError(t("Signupforproject-e6"));
    }
    if (selectedCompanySize === "Company Size") {
      setCompanyError(t("Signupforproject-e7"));
    }
    if (position.length === 0) {
      setPositionError(t("Signupforproject-e8"));
    }
    if (projectIndustry.length === 0) {
      setProjectIndustryError(t("Signupforproject-e9"));
    }
    if (twitter.length === 0) {
      setTwitterError(t("Signupforproject-e10"));
    } else if (!isValidSocialLink(twitter, "twitter")) {
      setTwitterError(t("Signupforproject-e11"));
    }
    if (telegram.length === 0) {
      setTelegramError(t("Signupforproject-e12"));
    } else if (!isValidSocialLink(telegram, "telegram")) {
      setTelegramError(t("Signupforproject-e13"));
    } else {
      var data = JSON.stringify({
        projectName: projectName,
        twitterLink: twitter,
        telegramLink: telegram,
        companySize: selectedCompanySize,
        projectIndustry: projectIndustry,
        website: website,
        projectCreatorPosition: position,
        projectCreatorName: name,
        email: email,
      });
      setLoader(true);

      var config = {
        method: "post",
        url: `${Api_Url}/temp/creator-signup`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          handleShow1();
          setLoader(false);
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.message);
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="wrapper-apply">
        <BannerApply />
        <section className="apply-section sectionforsignupproject">
          <div className="custom-container">
            <div className="parent">
              <div className="left-side" style={{ borderRight: "none" }}>
                <div className="main-content">
                  <h4>
                    {/* {t('Unlockwallet')} */}
                    {t("Signupproject1")} <br />{" "}
                    <span>{t("Signupproject2")}</span>
                  </h4>
                  <p className="para">{t("UNclockpara")} </p>
                  <div className="inner-twice">
                    <div className="twice-data">
                      <img
                        src="\assets\signup\communitydriven-dark.svg"
                        alt="img"
                        className="img-fluid hide-in-light-theme"
                      />
                      <img
                        src="\assets\signup\communitydriven.svg"
                        alt="img"
                        className="img-fluid d-none show-in-light-theme"
                      />
                      <div className="text">
                        <h5>{t("COmmunitydriven")}</h5>
                        <p>{t("Leveragepara")}</p>
                      </div>
                    </div>
                    <div className="twice-data">
                      <img
                        src="\assets\signup\easycompaign-dark.svg"
                        alt="img"
                        className="img-fluid hide-in-light-theme"
                      />
                      <img
                        src="\assets\signup\easycompaign.svg"
                        alt="img"
                        className="img-fluid d-none show-in-light-theme"
                      />
                      <div className="text">
                        <h5>{t("Easysetup")}</h5>
                        <p>{t("Effortpara")}</p>
                      </div>
                    </div>
                    <div className="twice-data">
                      <img
                        src="\assets\signup\proveneffect-dark.svg"
                        alt="img"
                        className="img-fluid hide-in-light-theme"
                      />
                      <img
                        src="\assets\signup\proveneffect.svg"
                        alt="img"
                        className="img-fluid d-none show-in-light-theme"
                      />
                      <div className="text">
                        <h5>{t("Proven")}</h5>
                        <p>{t("Ensurepara")}</p>
                      </div>
                    </div>
                    <div className="twice-data">
                      <img
                        src="\assets\signup\customizable-dark.svg"
                        alt="img"
                        className="img-fluid hide-in-light-theme"
                      />
                      <img
                        src="\assets\signup\customizable.svg"
                        alt="img"
                        className="img-fluid d-none show-in-light-theme"
                      />
                      <div className="text">
                        <h5>{t("Customizable")}</h5>
                        <p>{t("Rewardpara")}</p>
                      </div>
                    </div>
                    <div className="twice-data">
                      <img
                        src="\assets\signup\excess-dark.svg"
                        alt="img"
                        className="img-fluid hide-in-light-theme"
                      />
                      <img
                        src="\assets\signup\excess.svg"
                        alt="img"
                        className="img-fluid d-none show-in-light-theme"
                      />
                      <div className="text">
                        <h5>{t("Signupproject3")}</h5>
                        <ul>
                          <li>{t("Signupproject4")}</li>
                          <li>{t("Signupproject5")}</li>
                          <li>{t("Signupproject6")}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-side">
                <Tabs
                  defaultActiveKey="premium"
                  id="uncontrolled-tab-example"
                  className="signup-nav"
                >
                  <Tab eventKey="premium" title={t("Signupproject13")}>
                    <div className="apply-box">
                      <h6 className="main-head">
                        {/* {t('Fillinthe')} */}
                        {t("Signupproject7")}{" "}
                        <span>
                          {" "}
                          {t("Signupproject8")}
                          {/* {t('Detail')} */}
                        </span>
                      </h6>
                      <p className="para">{t("PleasefillPara")}</p>
                      <div className="bottom-content">
                        <div className="option-field">
                          <input
                            value={projectName}
                            onChange={(e) => (
                              setProjectName(e.target.value), setNameError("")
                            )}
                            type="text"
                            placeholder={t("Signupforproject-t3")}
                          />
                          {nameError ? (
                            <p className="error-message">{nameError}</p>
                          ) : null}
                        </div>
                        <div className="option-field">
                          <div className="dropdown">
                            <button
                              className="dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {selectedCompanySize === "Company Size" ? (
                                <>{selectedCompanySize}</>
                              ) : (
                                <h6 style={{ color: "#FFF" }}>
                                  {selectedCompanySize}
                                </h6>
                              )}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="8"
                                viewBox="0 0 15 8"
                                fill="none"
                              >
                                <path
                                  d="M0.535156 0.5L0.535157 3.47056L7.76848 7.5L7.76848 4.52943L0.535156 0.5Z"
                                  fill="#D1DADD"
                                />
                                <path
                                  d="M15 0.500244L15 3.47081L7.76667 7.50024L7.76667 4.52968L15 0.500244Z"
                                  fill="#D1DADD"
                                />
                              </svg>
                            </button>
                            {companyError ? (
                              <p className="error-message">{companyError}</p>
                            ) : null}
                            <ul className="dropdown-menu">
                              <li>
                                <a
                                  className="dropdown-item"
                                  // href="#"
                                  onClick={() => (
                                    handleItemClick("1-10"), setCompanyError("")
                                  )}
                                >
                                  1-10
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  // href="#"
                                  onClick={() => (
                                    handleItemClick("10-50"),
                                    setCompanyError("")
                                  )}
                                >
                                  10-50
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  // href="#"
                                  onClick={() => (
                                    handleItemClick("50-100"),
                                    setCompanyError("")
                                  )}
                                >
                                  50-100
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  // href="#"
                                  onClick={() => (
                                    handleItemClick("100+"), setCompanyError("")
                                  )}
                                >
                                  above 100
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="option-field">
                          <input
                            value={projectIndustry}
                            onChange={(e) => (
                              setProjectIndustry(e.target.value),
                              setProjectIndustryError("")
                            )}
                            type="text"
                            placeholder={t("Signupforproject-t5")}
                          />
                          {projectIndustryError ? (
                            <p className="error-message">
                              {projectIndustryError}
                            </p>
                          ) : null}
                        </div>
                        <div className="option-field">
                          <input
                            value={website}
                            onChange={(e) => (
                              setWebsite(e.target.value), setWebsiteError("")
                            )}
                            type="text"
                            placeholder={t("Signupforproject-t6")}
                          />
                          {websiteError ? (
                            <p className="error-message">{websiteError}</p>
                          ) : null}
                        </div>
                        <div className="option-field">
                          <input
                            value={twitter}
                            onChange={(e) => (
                              setTwitter(e.target.value), setTwitterError("")
                            )}
                            type="text"
                            placeholder={t("Signupforproject-t7")}
                          />
                          {twitterError ? (
                            <p className="error-message">{twitterError}</p>
                          ) : null}
                        </div>
                        <div className="option-field">
                          <input
                            value={telegram}
                            onChange={(e) => (
                              setTelegram(e.target.value), setTelegramError("")
                            )}
                            type="text"
                            placeholder={t("Signupforproject-t8")}
                          />
                          {telegramError ? (
                            <p className="error-message">{telegramError}</p>
                          ) : null}
                        </div>
                        <div className="twice-fields">
                          <div className="option-field">
                            <input
                              value={name}
                              onChange={(e) => (
                                setname(e.target.value), setNumberError("")
                              )}
                              type="text"
                              placeholder={t("Signupforproject-t9")}
                            />
                            {numberError ? (
                              <p className="error-message">{numberError}</p>
                            ) : null}
                          </div>
                          <div className="option-field">
                            <input
                              value={position}
                              onChange={(e) => (
                                setPosition(e.target.value),
                                setPositionError("")
                              )}
                              type="text"
                              placeholder={t("Signupforproject-t10")}
                            />
                            {positionError ? (
                              <p className="error-message">{positionError}</p>
                            ) : null}
                          </div>
                        </div>
                        <div className="option-field">
                          <input
                            value={email}
                            onChange={(e) => (
                              setEmail(e.target.value),
                              setEmailErrorRegister("")
                            )}
                            type="text"
                            placeholder={t("Signupforproject-t11")}
                          />
                          {emailerrorregister ? (
                            <p className="error-message">
                              {emailerrorregister}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div onClick={submit} className="btn-sign">
                        {loader ? (
                          <a href="#" className="btn-continue">
                            <img
                              src="\assets\loadingp.png"
                              alt="img"
                              className="img-fluid loading"
                            />
                            {t("Signupforproject-t13")}
                          </a>
                        ) : (
                          <a className="btn-continue">
                            {t("Signupforproject-t12")}
                          </a>
                        )}
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="self" title={t("Signupproject9")}>
                    <div className="apply-box">
                      <div className="self-serve">
                        <h5>{t("Signupproject9")}</h5>
                        <p>{t("Signupproject10")}</p>
                        <p>{t("Signupproject11")}</p>
                        <a
                          href="https://creators.gems.vip/signup"
                          target="_blank"
                          className="btn-green"
                        >
                          {t("Signupproject12")}
                        </a>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal
        className="common-modal-style addteammembermodal"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Signupforproject-t14")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="addedmodal">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="89"
              height="88"
              viewBox="0 0 89 88"
              fill="none"
            >
              <path
                d="M77.084 37.9845L77.084 11.417L35.6306 51.7939L10.918 34.7697L10.918 59.3998L35.6306 77.583L77.084 37.9845Z"
                fill="#D1DADD"
              />
            </svg>
            <h6 className="addedhead">{t("Signupforproject-t15")}</h6>
            <button className="btn-green w-100" onClick={() => handleClose1()}>
              {t("Signupforproject-t16")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Signupproject;
