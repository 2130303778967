import { useCallback } from "react";
import useWeb3 from "../hooks/useWeb3";
import environment from "../utils/Environment";
import { getGemsTokenContract } from "../utils/contractHelpers";

const ApproveTokens = () => {
  const web3 = useWeb3();
  const approveTokens = useCallback(
    async (account, token) => {
      let nd = 19000;
      nd = web3.utils.toWei(nd.toString(), "ether");
      console.log("token address: ", environment?.[token]);

      const contract = getGemsTokenContract(environment?.[token], web3);

      try {
        const gas = await contract.methods
          .approve(environment.minerInsurance, nd)
          .estimateGas({ from: account });

        const details = await contract.methods
          .approve(environment.minerInsurance, nd)
          .send({
            from: account,
            gas,
          });
        return details;
      } catch (error) {
        throw error;
      }
    },
    [web3]
  );
  return { approveTokens: approveTokens };
};
export default ApproveTokens;
