import React, { useState } from 'react'
import { Player } from "video-react";
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap';

const Information = () => {
    const { t } = useTranslation()

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);

    return (
        <>
            <section className="information-section">
                <div className="first-para" style={{ margin: "0" }}>
                    <h2>{t('Dop-detail4')} <span></span></h2>
                    <p>Prodex is revolutionizing the e-commerce industry with a decentralized platform that merges Web3 transparency and incentives with e-commerce usability, creating a community-driven product catalog and marketplace.
                    </p>
                    <p>With a vision of becoming the “Decentralized Amazon,” Prodex addresses inefficiencies in traditional e-commerce by lowering fees, providing shared ownership, and offering contributor rewards. By empowering sellers, contributors, and buyers, Prodex is reshaping the $5 trillion market.</p>

                </div>
                <div className="first-para">
                    <h2>WHAT MAKES PRODEX UNIQUE <span></span></h2>
                    <ol>
                        <li><span>Community-Driven Catalog:</span>   Prodex operates as a “Wikipedia” for products, enabling users to add, edit, and verify listings while earning tokens and incentives.</li>
                        <li><span>Lower Fees:</span>    With only a 2% fee, Prodex significantly reduces costs for sellers while rewarding contributors.</li>
                        <li><span>Ownership and Incentives:</span>    Contributors earn shared revenue from product pages they enhance, ensuring fairness and motivation to improve the platform.</li>
                        <li><span> Transparency and Trust:</span>   Verified product information and transparent transactions create a secure and reliable environment for buyers and sellers.</li>
                        <li><span>Crypto Integration:</span>   Buyers benefit from lower prices, verified information, and the ability to make purchases with cryptocurrency.</li>
                    </ol>
                </div>
                <div className="first-para">
                    <h2>REVOLUTIONIZING E-COMMERCE DEVELOPMENT <span></span></h2>
                    <ul>
                        <li><span>Empowering Contributors:</span>   Prodex incentivizes contributions to the product catalog, enabling users to build digital assets and earn from their efforts.</li>
                        <li><span>Market Accessibility:</span>    The platform opens the e-commerce market to diverse sellers while providing buyers with more options and verified data.</li>
                        <li><span>Targeting Niche Categories:</span>    Focusing on passionate social communities (e.g., tech gadgets, collectibles) allows Prodex to scale efficiently.</li>
                    </ul>
                </div>
                <div className="first-para">
                    <h2>Tokenomics: <span></span></h2>
                    <ul>
                        <li><span>30%</span> - Contributes</li>
                        <li><span>30%</span>   - Miners</li>
                        <li><span>20%</span>   - Launchpad, Advisors & Airdrops  </li>
                        <li><span>5%</span>   - Partnerships</li>
                        <li><span>15%</span>  - Development
                        </li>
                    </ul>
                </div>
                <div className="first-para">
                    <h2>ROADMAP TO SUCCESS <span></span></h2>
                    <h6>2024 – Foundation & Alpha Phase</h6>
                    <ul>
                        <li><span>Q3</span>  – Planning, whitepaper, tokenomics, product catalog development</li>
                        <li><span>Q4</span> – Alpha Phase: Community-driven product indexing, verification, & rewards.</li>
                    </ul>
                    <h6>2025 – Marketplace Launch & Adoption</h6>
                    <ul>
                        <li><span>Q1</span> – Beta Phase: Store creation, product listing integration, escrow payments, buyer flow.</li>
                        <li><span>Q2</span> – Soft Launch: Contributor-driven expansion, token generation event (TGE)</li>
                        <li><span>Q3</span> – Go-to-Market: Seller onboarding , partnerships, business development.</li>
                        <li><span>Q4</span> – Full Public Launch</li>
                    </ul>
                </div>
                <div className="first-para">
                    <p>Prodex is not just redefining e-commerce; it’s paving the way for a decentralized, transparent, and equitable future for one of the largest online markets.</p>
                </div>
                
                <div className="first-para">
                    <h2>{t('Dop-detail31')} <span></span></h2>
                    <div className="twice-tokenomics" onClick={() => {setShow1(true)}} style={{cursor: "pointer"}}>
                        <div className="tokenomic-img">
                        <img src="\assets\alldetailpages\prodex\roadmap-light.png" alt="img" className='img-fluid d-none show-in-light-theme' />
                        <img src="\assets\alldetailpages\prodex\roadmap-dark.png" alt="img" className='img-fluid hide-in-light-theme' />
                        </div>
                    </div>
                </div>
              


            </section>

            <Modal className='common-modal-style image-preview distribution-modl' show={show1} onHide={handleClose1} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className='p-0'>
                <img src="\assets\alldetailpages\prodex\roadmap-light.png" alt="img" className='img-fluid d-none show-in-light-theme' />
                <img src="\assets\alldetailpages\prodex\roadmap-dark.png" alt="img" className='img-fluid hide-in-light-theme' />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Information
