import React, { useState } from "react";
import { useSelector } from "react-redux";

function Loader({ text }) {
  // const theme = useSelector((state) => state.UserReducer.theme);

  return (
    <>
      <div className="">
        <div
          className="position-fixed w-100 mainLoader"
          style={{
            zIndex: 999999999,
            // marginTop: -200,
            height: "100%",
            marginLeft: 0,
            background: "var(--modal-backshadow)",
            backdropFilter: "blur(20px)",
            top: "0px",
            left: "0px",
          }}
        >
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div
              className="d-flex flex-wrap align-items-center justify-content-center"
              style={{ flexDirection: "column" }}
            >
              <img
                width={150}
                className="mt-n4 hide-in-light-theme"
                src="https://res.cloudinary.com/drt6vurtt/image/upload/v1735208538/gems/dark-theme-loader_xxaehr.svg"
                alt="loader"
              />
              <img
                width={150}
                className="mt-n4 d-none show-in-light-theme"
                src="https://res.cloudinary.com/drt6vurtt/image/upload/v1735208539/gems/light-theme-loader_idwbfz.svg"
                alt="loader"
              />
              <p className="text-center noora">{text}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Loader;
