import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { getGemsTokenContract } from "../../utils/contractHelpers";
// import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const AllowanceTokens = () => {
  // const [balance, setBalance] = useState(null)
  //   const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.minerInsurance;

  const allowanceTokens = useCallback(
    async (account, token) => {
      // console.log(tokenAddress,account,'noor');
      console.log("token address: ", Environment?.[token]);

      const contract = getGemsTokenContract(Environment?.[token], web3);
      try {
        const dec = await contract.methods.decimals().call();
        const buy = await contract.methods
          .allowance(account, tokenAddress)
          .call();

        const allow = parseInt(buy.toString()) / 10 ** dec;

        return allow;
      } catch (error) {
        throw error;
      }
    },
    [web3]
  );
  return { allowanceTokens: allowanceTokens };
};
export default AllowanceTokens;
