import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "./useWeb3";
import environment from "../utils/Environment";
import { dopContract, dopDynamicContract } from "../utils/contractHelpers";
import axios from "axios";

export const ClaimDopsDynamic = () => {
  const { account, chainId } = useWeb3React();
  const web3 = useWeb3();

  const waitForTransaction = async (txHash) => {
    while (true) {
      const receipt = await web3.eth.getTransactionReceipt(txHash);
      if (receipt) {
        return receipt; // Return the receipt once it's confirmed
      }
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before polling again
    }
  };
  const Mint = useCallback(
    async (
      amountToClaim,
      totalClaims,
      claimedAmount,
      claimMonths,
      merkleProof,
      deadline,
      v,
      r,
      s,
      claimAddress
      //   callback
    ) => {
      console.log(
        "quantity payload",
        account,
        amountToClaim,
        totalClaims,
        claimedAmount,
        claimMonths,
        merkleProof,
        deadline,
        v,
        r,
        s,
        claimAddress
      );
      try {
        // let gasPrice = await web3.eth.getGasPrice();
        // gasPrice = parseInt(gasPrice) + 10000000000;
        const contract = dopDynamicContract(claimAddress, web3);

        console.log("quantity payload 1", account, claimAddress);
        var gasFunPrice;
        web3.eth.getGasPrice().then((result) => {
          var result2 = parseInt(result) + 3000000000;
          gasFunPrice = result2.toString();
        });

        console.log("quantity payload", account);
        const claimed = await contract.methods
          .claimLeia(
            amountToClaim,
            totalClaims,
            claimedAmount,
            claimMonths,
            merkleProof,
            deadline,
            v,
            r,
            s
          )
          .send({ from: account });
        //   .on("transactionHash", (tx) => {
        //     console.log("tx:::", tx);
        //     // callback();
        //     return tx;
        //   });

        const receipt = await waitForTransaction(claimed.transactionHash);
        return true;
        // return claimed;
      } catch (e) {
        console.log("quantity payload e", e);
        return false;
      }
    },
    [web3, account]
  );
  return { ClaimDynamic: Mint };
};

export default ClaimDopsDynamic;
