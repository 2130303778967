import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Slider from "react-slick";

function SampleNextArrow(props) {
  const { className, onClick, hideArrow } = props;
  return !hideArrow ? (
    <div className={className} onClick={onClick}>
      <img
        src="\next-arrow.svg"
        alt="Next"
        className="img-fluid d-none show-in-light-theme"
      />
      <img
        src="\next-arrow-dark.svg"
        alt="Next"
        className="img-fluid hide-in-light-theme"
      />
    </div>
  ) : (
    <div
      className={className}
      onClick={onClick}
      style={{ pointerEvents: "none", opacity: "0.5" }}
    >
      <img
        src="\next-arrow.svg"
        alt="Next"
        className="img-fluid d-none show-in-light-theme"
      />
      <img
        src="\next-arrow-dark.svg"
        alt="Next"
        className="img-fluid hide-in-light-theme"
      />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick, hideArrow } = props;
  return !hideArrow ? (
    <div className={className} onClick={onClick}>
      <img
        src="\prev-icon.svg"
        alt="Prev"
        className="img-fluid d-none show-in-light-theme"
      />
      <img
        src="\prev-icon-dark.svg"
        alt="Prev"
        className="img-fluid hide-in-light-theme"
      />
    </div>
  ) : (
    <div
      className={className}
      onClick={onClick}
      style={{ pointerEvents: "none", opacity: "0.5" }}
    >
      <img
        src="\prev-icon.svg"
        alt="Prev"
        className="img-fluid d-none show-in-light-theme"
      />
      <img
        src="\prev-icon-dark.svg"
        alt="Prev"
        className="img-fluid hide-in-light-theme"
      />
    </div>
  );
}

const MineProjectSlide = () => {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = 1.3;

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.3,
    slidesToScroll: 1,
    draggable: true,
    swipe: true,
    beforeChange: (current, next) => setCurrentSlide(next),
    nextArrow: <SampleNextArrow hideArrow={currentSlide >= totalSlides - 1} />,
    prevArrow: <SamplePrevArrow hideArrow={currentSlide === 0} />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 3.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
          draggable: true,
          swipe: true,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          draggable: true,
          swipe: true,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          draggable: true,
          swipe: true,
        },
      },
    ],
  };
  return (
    <>
      <section className="process-projects mineproject-cards">
        <div className="custom-container padd-right-sm">
          <div className="main-heading">
            <h5>{t('projectmine')}</h5>
            <p>
              {t('firstseries')} {" "}
            </p>
          </div>
          <div className="bottom-cards">
            {currentSlide >= totalSlides - 1 ? (
              <>
                <img
                  src="\cardshadow-light.png"
                  alt="img"
                  className="img-fluid cardshadow-lightleft d-none show-in-light-theme"
                />
                <img
                  src="\cardshadow-dark.png"
                  alt="img"
                  className="img-fluid cardshadow-lightleft hide-in-light-theme"
                />
              </>
            ) : (
              <>
                <img
                  src="\cardshadow-light.png"
                  alt="img"
                  className="img-fluid cardshadow-light d-none show-in-light-theme"
                />
                <img
                  src="\cardshadow-dark.png"
                  alt="img"
                  className="img-fluid cardshadow-light hide-in-light-theme"
                />
              </>
            )}
            <div className="slider">
              <Slider {...settings}>
                <div className="single-card">
                  <Link to="/incentiv">
                    <div className="main-token-img">
                      <img
                        width={70}
                        height={70}
                        src="\assets\presaletokens\incentiv.png"
                        alt="img"
                        className="img-fluid"
                      />
                    </div>

                    <div className="token-info">
                      <h6>
                        Incentiv{" "}
                        <span>
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="12"
                            viewBox="0 0 13 12"
                            fill="none"
                          >
                            <path
                              d="M7.45048 0.257202L8.48835 3.53194L12.1008 3.45449L12.7619 5.38046L9.79188 7.32689L10.9857 10.5534L9.2544 11.7429L6.38016 9.67079L3.50592 11.7429L1.77458 10.5534L2.96845 7.32689L0 5.379L0.661029 3.45303L4.27352 3.53048L5.3114 0.257202H7.45048Z"
                              fill="#30F1B6"
                            />
                          </svg>
                          {t('btnverified')}
                        </span>
                      </h6>
                      <p className="sub-title">INC</p>
                      <p className="para onmobiletext">
                        {t('layer1')}{" "}
                      </p>
                    </div>
                    <div className="listing-data">
                      <img src="\assets\gemsnodeslanding\tokencardbg.png" alt="img" className="img-fluid tokencardbg" />
                      <h5>{t('listingprice')}</h5>
                      <h6>$0.026</h6>
                    </div>
                    <div className="tokenmined">
                      <p>INC {t('textminiminer')}</p>
                      <h6>1,500 INC</h6>
                    </div>
                    <div className="tokenmined">
                      <p>INC {t('minedwithminer')}</p>
                      <h6>31,802.27 INC</h6>
                    </div>
                    <div className="tokenmined">
                      <p>INC {t('megaminer')}</p>
                      <h6>166,961.93 INC</h6>
                    </div>
                  </Link>
                  <Link to="/incentiv" className="btn-buy">
                    {t('btnview')}
                  </Link>
                  <p className="bottom-mini-para">{t('listingpara')}</p>
                </div>
                <div className="single-card">
                  <Link to="/rain">
                    <div className="main-token-img">
                      <img
                        width={70}
                        height={70}
                        src="\assets\alldetailpages\miners\otherprojects\rain-token.png"
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                    <div className="token-info">
                      <h6>
                        RAIN{" "}
                        <span>
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="12"
                            viewBox="0 0 13 12"
                            fill="none"
                          >
                            <path
                              d="M7.45048 0.257202L8.48835 3.53194L12.1008 3.45449L12.7619 5.38046L9.79188 7.32689L10.9857 10.5534L9.2544 11.7429L6.38016 9.67079L3.50592 11.7429L1.77458 10.5534L2.96845 7.32689L0 5.379L0.661029 3.45303L4.27352 3.53048L5.3114 0.257202H7.45048Z"
                              fill="#30F1B6"
                            />
                          </svg>
                          {t('btnverified')}
                        </span>
                      </h6>
                      <p className="sub-title">RAIN</p>
                      <p className="para onmobiletext">
                        {t('descriptionrain')}
                      </p>
                    </div>
                    <div className="listing-data">
                      <img src="\assets\gemsnodeslanding\tokencardbg.png" alt="img" className="img-fluid tokencardbg" />
                      <h5>{t('listingprice')}</h5>
                      <h6>$0.00168</h6>
                    </div>
                    <div className="tokenmined">
                      <p>RAIN {t('textminiminer')}</p>
                      <h6>118,800 RAIN</h6>
                    </div>
                    <div className="tokenmined">
                      <p>RAIN {t('minedwithminer')}</p>
                      <h6>2,518,740 RAIN</h6>
                    </div>
                    <div className="tokenmined">
                      <p>RAIN {t('megaminer')}</p>
                      <h6>13,223,385 RAIN</h6>
                    </div>
                  </Link>

                  <Link to="/rain" className="btn-buy">
                    {t('btnview')}
                  </Link>
                  <p className="bottom-mini-para">{t('listingpara')}</p>
                </div>
                <div className="single-card">
                  <Link to="/olympus">
                    <div className="main-token-img">
                      <img
                        width={70}
                        height={70}
                        src="\assets\presaletokens\olympus.png"
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                    <div className="token-info">
                      <h6>
                        Olympus AI{" "}
                        <span>
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="12"
                            viewBox="0 0 13 12"
                            fill="none"
                          >
                            <path
                              d="M7.45048 0.257202L8.48835 3.53194L12.1008 3.45449L12.7619 5.38046L9.79188 7.32689L10.9857 10.5534L9.2544 11.7429L6.38016 9.67079L3.50592 11.7429L1.77458 10.5534L2.96845 7.32689L0 5.379L0.661029 3.45303L4.27352 3.53048L5.3114 0.257202H7.45048Z"
                              fill="#30F1B6"
                            />
                          </svg>
                          {t('btnverified')}
                        </span>
                      </h6>
                      <p className="sub-title">OAI</p>
                      <p className="para onmobiletext">
                        {t('descriptionolympus')}
                      </p>
                    </div>
                    <div className="listing-data">
                      <img src="\assets\gemsnodeslanding\tokencardbg.png" alt="img" className="img-fluid tokencardbg" />
                      <h5>{t('listingprice')}</h5>
                      <h6>$0.79</h6>
                    </div>
                    <div className="tokenmined">
                      <p>OAI {t('textminiminer')}</p>
                      <h6>250.46 OAI</h6>
                    </div>
                    <div className="tokenmined">
                      <p>OAI {t('minedwithminer')}</p>
                      <h6>5,310.06 OAI</h6>
                    </div>
                    <div className="tokenmined">
                      <p>OAI {t('megaminer')}</p>
                      <h6>27,877.83 OAI</h6>
                    </div>
                  </Link>
                  <Link to="/olympus" className="btn-buy">
                    {t('btnview')}
                  </Link>
                  <p className="bottom-mini-para">{t('listingpara')}</p>
                </div>
                <div className="single-card">
                  <Link to="/prodex">
                    <div className="main-token-img">
                      <img
                        width={70}
                        height={70}
                        src="\assets\alldetailpages\miners\otherprojects\prodexx-token.png"
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                    <div className="token-info">
                      <h6>
                        Prodex{" "}
                        <span>
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="12"
                            viewBox="0 0 13 12"
                            fill="none"
                          >
                            <path
                              d="M7.45048 0.257202L8.48835 3.53194L12.1008 3.45449L12.7619 5.38046L9.79188 7.32689L10.9857 10.5534L9.2544 11.7429L6.38016 9.67079L3.50592 11.7429L1.77458 10.5534L2.96845 7.32689L0 5.379L0.661029 3.45303L4.27352 3.53048L5.3114 0.257202H7.45048Z"
                              fill="#30F1B6"
                            />
                          </svg>
                          {t('btnverified')}
                        </span>
                      </h6>
                      <p className="sub-title">Prodex</p>
                      <p className="para onmobiletext">
                        {t('prodexdes')}
                      </p>
                    </div>
                    <div className="listing-data">
                      <img src="\assets\gemsnodeslanding\tokencardbg.png" alt="img" className="img-fluid tokencardbg" />
                      <h5>{t('listingprice')}</h5>
                      <h6>$0.056</h6>
                    </div>
                    <div className="tokenmined">
                      <p>PRX {t('textminiminer')}</p>
                      <h6>1,782 PRX</h6>
                    </div>
                    <div className="tokenmined">
                      <p>PRX {t('minedwithminer')}</p>
                      <h6>37,781.1 PRX</h6>
                    </div>
                    <div className="tokenmined">
                      <p>PRX {t('megaminer')}</p>
                      <h6>198,350.78 PRX</h6>
                    </div>
                  </Link>

                  <Link to="/prodex" className="btn-buy">
                    {t('btnview')}
                  </Link>
                  <p className="bottom-mini-para">{t('listingpara')}</p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MineProjectSlide;