import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import Environment from "../../utils/Environment";

const AllProjectsReferral = ({ role, setMainLoader1 }) => {
  const [allProjects, setAllProjects] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  // const [mainLoader1, setMainLoader1] = useState(false);

  const { account } = useWeb3React();

  const fetchProjects = async (page = 1) => {
    if (role === "self") {
      setMainLoader1(true);
      const offset = page; // Assuming 'offset' is the page number
      const config = {
        method: "get",
        url: `${
          Environment?.Api_Url
        }/referral/buyer-listing?by=${account.toLowerCase()}&limit=10&offset=${offset}`,
        headers: {},
      };

      try {
        const response = await axios(config);
        const data = response.data;
        setAllProjects(data?.data?.buyerParticipation || []);
        setTotalPages(data?.data?.pages || 0);
        setTotalCount(data?.data?.count || 0);
        setMainLoader1(false);
      } catch (error) {
        setMainLoader1(false);
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    if (role && account) {
      // console.log("inhere")
      if (role === "self") {
        fetchProjects(currentPage);
      }
    }
    // else if(!account){
    //     // console.log("outhere")
    //     setAllProjects([])
    //     // fetchProjects(currentPage);
    // }
  }, [role, account, currentPage]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  console.log("allProjects", allProjects);

  return (
    <div className="custom-table">
      <div className="table-responsive">
        <table className="table m-0">
          <thead>
            <tr>
              <th>Project Name</th>
              <th className="text-center">Total Purchases</th>
              <th className="text-center">Amount Spent</th>
              <th className="text-center">Amount Purchased</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {allProjects?.map((project, index) => (
              <tr key={project._id || index}>
                <td>
                  <div className="twice-items">
                    <img
                      src={project.projectImage}
                      alt={`${project.projectName} Logo`}
                      className="img-fluid"
                    />
                    <h6>{project.projectName}</h6>
                  </div>
                </td>
                <td className="text-center">{project.totalPurchases}</td>
                <td className="text-center">
                  $
                  {project?.totalSaleConverted?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {project.dopPurchased.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  {project.privateSale}
                </td>
                <td>
                  <Link to={`/allpurchasesreferral/${project?._id}`}>
                    All Purchases
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="custom-pagination">
        <p>
          SHOWING {(currentPage - 1) * 10 + 1}-
          {Math.min(currentPage * 10, totalCount)} OF {totalCount}
        </p>
        <ul className="pagination">
          {/* Previous Button */}
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <a
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <g opacity="0.5">
                  <path
                    d="M7.88904 1.93555L5.38763 1.93555L1.99459 7.99682L4.496 7.99682L7.88904 1.93555Z"
                    fill="#939FA3"
                  />
                  <path
                    d="M7.88904 14.0703L5.38763 14.0703L1.99459 8.00904L3.57108 6.96117L4.496 8.00904L7.88904 14.0703Z"
                    fill="#939FA3"
                  />
                  <path
                    d="M8.01655 6.85742H14.9616L13.6869 9.14313H6.74182L8.01655 6.85742Z"
                    fill="#939FA3"
                  />
                </g>
              </svg>
            </a>
          </li>

          {/* Page Numbers */}
          {Array.from({ length: totalPages }, (_, index) => (
            <li
              key={index}
              className={`page-item ${
                currentPage === index + 1 ? "active" : ""
              }`}
            >
              <a
                className="page-link"
                onClick={() => handlePageChange(index + 1)}
                href="#"
              >
                {index + 1}
              </a>
            </li>
          ))}

          {/* Next Button */}
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <a
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <path
                  d="M9.11108 1.93555L11.6125 1.93555L15.0055 7.99682L12.5041 7.99682L9.11108 1.93555Z"
                  fill="#30F1B6"
                />
                <path
                  d="M9.11108 14.0703L11.6125 14.0703L15.0055 8.00904L13.429 6.96117L12.5041 8.00904L9.11108 14.0703Z"
                  fill="#30F1B6"
                />
                <path
                  d="M3.31318 6.85742H10.2582L8.98351 9.14313H2.03845L3.31318 6.85742Z"
                  fill="#30F1B6"
                />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AllProjectsReferral;
