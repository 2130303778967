import React, { useEffect, useState } from 'react'
import Information from '../Information/Information'
import { useTranslation } from 'react-i18next'
import Document from '../Documents/Document';

const Banner = () => {
  const { t } = useTranslation()
  const [timeshow1, setTimeshow1] = useState(false);
  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);
  const [day1, setDay1] = useState(0);
  const [hour1, setHour1] = useState(0);
  const [min1, setMin1] = useState(0);
  const [sec1, setSec1] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const time = 1742814000000;
      const diff = time - now;
      if (diff <= 0) {
        clearInterval(interval);
        setTimeshow1(true);
        return;
      }
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const secs = Math.floor((diff % (1000 * 60)) / 1000);
      setDay1(days);
      setHour1(hours);
      setMin1(mins);
      setSec1(secs);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const time = 1743933600000;
      const diff = time - now;
      if (diff <= 0) {
        clearInterval(interval);
        setTimeshow1(true);
        return;
      }
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const secs = Math.floor((diff % (1000 * 60)) / 1000);
      setDay(days);
      setHour(hours);
      setMin(mins);
      setSec(secs);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <section className="banner-detailpage">
        <img src="\assets\alldetailpages\rain\banner\banner-bg.png" alt="img" className='img-fluid banner-bg' />
        <div className="custom-container">
          <div className="main-logo">
            <img src="\assets\alldetailpages\rain\banner\tokenlogo.svg" alt="img" className='img-fluid' />
          </div>
        </div>
      </section>
      <section className="bottom-part-section">
        <div className="custom-container">
          <div className="parent">
            <div className="left-side">
              <div className="token-info-box">
                <div className="token-img">
                  <img src="\assets\alldetailpages\rain\banner\main-token.png" alt="img" className='img-fluid' />
                </div>
                <div className="token-title">
                  <h6>Rain</h6>
                  <p>$RAIN</p>
                </div>
                <div className="social-icons">
                  <a href="https://rain.cool/" target='_blank'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <g clip-path="url(#clip0_756_4270)">
                      <path d="M10.0766 9.08958C10.1741 8.39734 10.2224 7.69908 10.2214 7.00002C10.2224 6.30096 10.1741 5.6027 10.0766 4.91046H12.8134C13.2971 6.26152 13.2971 7.73852 12.8134 9.08958H10.0766ZM6.9997 13.1756C6.39662 13.1756 5.78099 12.5566 5.3089 11.4775C5.09324 10.9847 4.91762 10.4229 4.78615 9.81304H9.21325C9.08178 10.4226 8.90616 10.9845 8.6905 11.4775C8.21841 12.5566 7.60278 13.1756 6.9997 13.1756ZM4.65395 9.08958C4.45067 7.70396 4.45067 6.29609 4.65395 4.91046H9.34545C9.44825 5.60221 9.49921 6.30068 9.49791 7.00002C9.49921 7.69937 9.44825 8.39783 9.34545 9.08958H4.65395ZM1.18599 9.08958C0.702326 7.73852 0.702326 6.26152 1.18599 4.91046H3.92278C3.72947 6.2968 3.72947 7.70325 3.92278 9.08958H1.18599ZM6.9997 0.824463C7.60278 0.824463 8.21841 1.44322 8.6905 2.5225C8.90616 3.01534 9.08178 3.57717 9.21325 4.18701H4.78615C4.91762 3.57741 5.09324 3.01558 5.3089 2.5225C5.78099 1.44322 6.39662 0.824463 6.9997 0.824463ZM12.4998 4.18677H9.95191C9.80717 3.4703 9.60598 2.80909 9.35365 2.23278C9.14933 1.76527 8.91774 1.36941 8.66421 1.0505C9.48632 1.27817 10.252 1.67463 10.9124 2.21461C11.5728 2.7546 12.1134 3.42625 12.4998 4.18677ZM5.33519 1.05026C5.08238 1.36941 4.85104 1.76503 4.64647 2.2323C4.39415 2.80909 4.1932 3.4703 4.04822 4.18628H1.49959C1.8861 3.42586 2.42677 2.75429 3.08715 2.21435C3.74753 1.67442 4.51314 1.27797 5.33519 1.05026ZM1.49959 9.81328H4.04749C4.19223 10.5297 4.39342 11.191 4.64575 11.7673C4.85032 12.2345 5.08166 12.6302 5.33447 12.9493C4.51251 12.7216 3.74701 12.3251 3.08675 11.7852C2.42649 11.2452 1.88596 10.5737 1.49959 9.81328ZM8.66421 12.9493C8.91702 12.6302 9.1486 12.2345 9.35293 11.7673C9.60526 11.1905 9.8062 10.5293 9.95118 9.81328H12.4998C12.1134 10.5738 11.5728 11.2455 10.9124 11.7855C10.252 12.3256 9.48633 12.7221 8.66421 12.9498V12.9493Z" fill="#657B82" />
                    </g>
                    <defs>
                      <clipPath id="clip0_756_4270">
                        <rect width="14" height="14" fill="white" />
                      </clipPath>
                    </defs>
                  </svg></a>
                  <a href="https://x.com/Rain__Protocol" target='_blank'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path d="M10.6423 1.3125H12.5719L8.35619 6.13083L13.3157 12.6875H9.43245L6.39095 8.71092L2.91078 12.6875H0.979945L5.48911 7.53375L0.731445 1.3125H4.71328L7.46253 4.94725L10.6423 1.3125Z" fill="#657B82" />
                  </svg></a>
                  <a href="https://t.me/Rain_announcements" target='_blank'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <g clip-path="url(#clip0_756_4279)">
                      <path d="M5.49335 8.85566L5.26177 12.113C5.5931 12.113 5.7366 11.9707 5.90869 11.7997L7.4621 10.3152L10.6809 12.6724C11.2713 13.0014 11.6872 12.8282 11.8464 12.1293L13.9593 2.22899L13.9599 2.22841C14.1471 1.35574 13.6443 1.01449 13.0691 1.22857L0.649937 5.98332C-0.197646 6.31232 -0.184813 6.78482 0.505854 6.99891L3.68094 7.98649L11.056 3.37174C11.4031 3.14191 11.7187 3.26907 11.4591 3.49891L5.49335 8.85566Z" fill="#657B82" />
                    </g>
                    <defs>
                      <clipPath id="clip0_756_4279">
                        <rect width="14" height="14" fill="white" />
                      </clipPath>
                    </defs>
                  </svg></a>
                </div>
              </div>
              <div className="presale-box">
                <div className="timer-parent">
                  <h6 className="main-head">
                    {!timeshow1 ? "Round Starts In" : "Round Ending In"}
                  </h6>
                  {!timeshow1 ? (
                    <div className="timer">
                      <div className="text">
                        <h6>{day1}</h6>
                        <p>Days</p>
                      </div>
                      <span>:</span>
                      <div className="text">
                        <h6>{hour1}</h6>
                        <p>Hours</p>
                      </div>
                      <span>:</span>
                      <div className="text">
                        <h6>{min1}</h6>
                        <p>MINUTES</p>
                      </div>
                      <span>:</span>
                      <div className="text">
                        <h6>{sec1}</h6>
                        <p>Seconds</p>
                      </div>
                    </div>
                  ) : (
                    <div className="timer">
                      <div className="text">
                        <h6>{day}</h6>
                        <p>Days</p>
                      </div>
                      <span>:</span>
                      <div className="text">
                        <h6>{hour}</h6>
                        <p>Hours</p>
                      </div>
                      <span>:</span>
                      <div className="text">
                        <h6>{min}</h6>
                        <p>MINUTES</p>
                      </div>
                      <span>:</span>
                      <div className="text">
                        <h6>{sec}</h6>
                        <p>Seconds</p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="presale-detail">
                  <h6 className="main-head">Presale details</h6>
                  <div className="bottom-detail">
                    <div className="twice-text">
                      <p>Status</p>
                      {!timeshow1 ? (
                        <h6 style={{ color: "#F18030" }}>UPCOMING</h6>
                      ) : (
                        <h6 style={{ color: "var(--btn-green-bg)" }}>Live</h6>
                      )}
                    </div>
                    <div className="twice-text">
                      <p>Current Round Price</p>
                      <h6>$0.00038750</h6>
                    </div>
                    <div className="twice-text">
                      <p>Round</p>
                      <h6>1</h6>
                    </div>
                  </div>
                  {/* <div className="hardcap-ifexist">
                    <div className="upper-detail">
                      <p>Hard Cap</p>
                      <h6><span>125,234,134</span>/285,714,285 OAI</h6>
                    </div>
                    <ProgressBar now={60} />
                  </div> */}
                  <a
                    href="https://buyrain.gems.vip/"
                    target="_blank"
                    className={
                      !timeshow1
                        ? "btn-green btn-buy disabled"
                        : "btn-green btn-buy"
                    }
                  >
                    Buy Now
                  </a>
                </div>
              </div>
            </div>
            <div className="right-side">
              <ul class="nav nav-tabs banner-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">{t('Gems-detailt2')}</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="document-tab" data-bs-toggle="tab" data-bs-target="#document-tab-pane" type="button" role="tab" aria-controls="document-tab-pane" aria-selected="false">{t('Gems-detailt4')}</button>
                </li>
                {/* <li class="nav-item" role="presentation">
                                <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">{t('Gems-detailt3')}</button>
                            </li> */}
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0"><Information /></div>
                {/* <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0"><Media /></div> */}
                <div class="tab-pane fade" id="document-tab-pane" role="tabpanel" aria-labelledby="document-tab" tabindex="0"><Document /></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Banner
