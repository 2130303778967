import React, { useState, useEffect, useRef } from "react";
import DopClaim from "../../hooks/isClaimed2";
import { DopClaim as DopClaim1 } from "../../hooks/isClaimed";
import "./claimschedule.scss";
import { useWeb3React } from "@web3-react/core";
import Countdown from "react-countdown";
import { useHistory } from "react-router-dom";
import Web3 from "web3";
import { dopContract } from "../../utils/contractHelpers";

const List = ({
  item,
  index,
  five,
  eight,
  last,
  setRemainingClaim,
  setClaimedLeia,
  totalLeia,
  lastClaimedMonth,
}) => {
  const [tokenCl, setTokenCl] = useState(false);
  const { IsClaimed2 } = DopClaim();
  const { IsClaimed } = DopClaim1();
  const { account } = useWeb3React();
  const [avai, setAvai] = useState(false);
  const history = useHistory();
  const claimedUpdated = useRef(false);
  const remainingClaimUpdated = useRef(false);

  useEffect(() => {
    if (account && item?.claimContract && item?.id < 4) {
      isDopClaimed();
    } else {
      setTokenCl(undefined);
    }
  }, [account, item?.claimContract]);

  useEffect(() => {
    if (totalLeia && item?.percentage !== undefined) {
      if (tokenCl == true) {
        if (!claimedUpdated.current) {
          setClaimedLeia((prev) => {
            const prevValue = Number(prev) || 0;
            const percentage = Number(item?.percentage) || 0;
            const total = Number(totalLeia) || 0;

            return prevValue + (percentage / 100) * total;
          });
          claimedUpdated.current = true; // Prevent further updates
        }
      }

      if (tokenCl === false && avai === true) {
        if (!remainingClaimUpdated.current) {
          setRemainingClaim((prev) => {
            const prevValue = Number(prev) || 0;
            const percentage = Number(item?.percentage) || 0;
            const total = Number(totalLeia) || 0;

            return prevValue + (percentage / 100) * total;
          });
          remainingClaimUpdated.current = true; // Prevent further updates
        }
      }
    }
  }, [totalLeia, item?.percentage, tokenCl, avai]);

  const isDopClaimed = async () => {
    const web3 = new Web3(
      "https://cold-responsive-friday.quiknode.pro/15bbbcf85ec27b075486f39524c5741f49222932/"
      // "https://eth-sepolia.g.alchemy.com/v2/n_4iaIl35yi2Vc0AV3-eg9rsdbG95Tfg"
    );

    const contract = dopContract(item?.claimContract, web3);

    try {
      let res = await contract.methods.isClaimed(account).call();
      if (res) {
        setTokenCl(res);
      } else {
        setTokenCl(false);
      }
    } catch (err) {
      setTokenCl(true);
      console.log("errxxxxx", err);
    }
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setAvai(true);

      return (
        <h6 className="avail-text" style={{ color: "#329879" }}>
          Available
        </h6>
      );
    }

    // Utility function to format time values
    const formatTime = (value) => String(value).padStart(2, "0");

    return (
      <>
        {[
          { label: "Days", value: days },
          { label: "Hours", value: hours },
          { label: "Minutes", value: minutes },
        ].map(({ label, value }) => (
          <div className="single" key={label}>
            <h6>{formatTime(value)}</h6>
            <p>{label}</p>
          </div>
        ))}
      </>
    );
  };

  const parseDate = (dateString) => {
    // Convert "5:14 27/12/2024" to a valid Date object
    const [time, date] = dateString.split(" ");
    const [hours, minutes] = time.split(":").map(Number);
    const [day, month, year] = date.split("/").map(Number);

    // Create a Date object with the parsed values
    return new Date(year, month - 1, day, hours, minutes);
  };

  const MoveNext = () => {
    localStorage?.setItem("currentObj", JSON.stringify(item));
    history.push("/claimall");
  };

  const checkTime = () => {
    if (!item?.date) return false; // Handle undefined date

    const eventTime = new Date(item.date).getTime(); // Convert to timestamp
    const roundTime = eventTime + 5 * 60 * 1000; // Add 5 minutes
    const currentTime = Date.now(); // Get current timestamp

    return roundTime <= currentTime; // Returns true if 5 minutes have passed
  };

  const trunCate = (num) => {
    const truncatedNum = Math.floor(num * 100) / 100;
    return truncatedNum;
  };

  const convertToLocalTime = (utcDate) => {
    const localDate = new Date(utcDate); // Convert to Date object (local time by default)

    // Format the time as per the user's locale
    return localDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // AM/PM format
    });
  };

  return (
    <tr key={index}>
      <td>#{item?.id}</td>
      <td>
        <div
          className="twice-text"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h6>
            {/* {item?.date?.slice(item?.date?.length - 10, item?.date?.length)} */}
            {item?.date?.slice(0, 10)}
          </h6>
          <p>{convertToLocalTime(item.date)}</p>
        </div>
      </td>
      <td style={{ textAlign: "center" }}>{item?.percentage}%</td>

      <td style={{ textAlign: "center" }}>
        {item?.id < 4 ? (
          <div className="timer" style={{ justifyContent: "center" }}>
            {tokenCl ? (
              <h6>Claimed</h6>
            ) : (
              <>
                <Countdown own date={new Date(item.date)} renderer={renderer} />
              </>
            )}
          </div>
        ) : (
          <div className="timer" style={{ justifyContent: "center" }}>
            {item?.id <= lastClaimedMonth ? (
              <h6>Claimed</h6>
            ) : (
              <>
                <Countdown own date={new Date(item.date)} renderer={renderer} />
              </>
            )}
          </div>
        )}
      </td>
      <td style={{ textAlign: "center" }}>
        {item?.id === 1 ? (
          trunCate(five)
        ) : (
          <>{item?.id === 12 ? trunCate(last) : trunCate(eight)}</>
        )}{" "}
        LEIA
      </td>
      <td>
        {item?.id < 4 ? (
          tokenCl === false && avai ? (
            <a
              onClick={() => MoveNext()}
              className="btn-claim"
              style={{ display: "flex", margin: "0 auto" }}
            >
              Claim
            </a>
          ) : index > 0 ? (
            <>
              {tokenCl ? (
                <a
                  style={{ opacity: 0.5, display: "flex", margin: "0 auto" }}
                  className="btn-claim claimed"
                >
                  Claimed
                </a>
              ) : (
                <a
                  style={{ opacity: 0.5, display: "flex", margin: "0 auto" }}
                  className="btn-claim"
                >
                  Claim
                </a>
              )}
            </>
          ) : (
            <a
              style={{ opacity: 0.5, display: "flex", margin: "0 auto" }}
              className="btn-claim claimed"
            >
              Claimed
            </a>
          )
        ) : item?.id <= lastClaimedMonth ? (
          <a
            style={{ opacity: 0.5, display: "flex", margin: "0 auto" }}
            className="btn-claim claimed"
          >
            Claimed
          </a>
        ) : avai ? (
          <a
            onClick={() => MoveNext()}
            className="btn-claim"
            style={{ display: "flex", margin: "0 auto" }}
          >
            Claim
          </a>
        ) : (
          <a style={{ opacity: 0.5 }} className="btn-claim">
            Claim
          </a>
        )}

        {/* 
        {tokenCl === false && avai ? (
          <a
            onClick={() => MoveNext()}
            className="btn-claim"
            style={{ display: "flex", margin: "0 auto" }}
          >
            Claim
          </a>
        ) : (
          <>
            {index > 0 ? (
              <>
                {tokenCl ? (
                  <a
                    style={{ opacity: 0.5, display: "flex", margin: "0 auto" }}
                    className="btn-claim claimed"
                  >
                    Claimed
                  </a>
                ) : (
                  <a
                    style={{ opacity: 0.5, display: "flex", margin: "0 auto" }}
                    className="btn-claim"
                  >
                    Claim
                  </a>
                )}
              </>
            ) : (
              <a
                style={{ opacity: 0.5, display: "flex", margin: "0 auto" }}
                className="btn-claim claimed"
              >
                Claimed
              </a>
            )}
          </>
        )} */}
      </td>
    </tr>
  );
};

export default List;
