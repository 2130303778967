import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import CuurentRound from "../../hooks/DataFetchers/round.js";
import Timer from "../../hooks/DataFetchers/time.js";
import CuurentRoundl from "../../hooks/DataFetchers/roundl.js";
import Timerl from "../../hooks/DataFetchers/timel.js";
import axios from "axios";
import RoundCardsss from "./RoundCardsss.js";
import Environment from "../../utils/Environment.js";

const PresalecardsAll = ({ role, setMainLoader1 }) => {
  var currencyFormatter = require("currency-formatter");
  const { t } = useTranslation();
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [Allprojectssss, setAllprojectssss] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());

  // Sorting function to prioritize live projects
  // const sortProjects = (projects) => {
  //   return [...projects].sort((a, b) => {
  //     const hasLiveRoundA = a.allRounds?.some(
  //       (round) =>
  //         getRoundStatus(round.startDate, round.endDate) === 'starting' ||
  //         getRoundStatus(round.startDate, round.endDate) === "ending"
  //     );

  //     const hasLiveRoundB = b.allRounds?.some(
  //       (round) =>
  //         getRoundStatus(round.startDate, round.endDate) === 'starting' ||
  //         getRoundStatus(round.startDate, round.endDate) === "ending"
  //     );

  //     if (hasLiveRoundA && !hasLiveRoundB) return -1;
  //     if (!hasLiveRoundA && hasLiveRoundB) return 1;
  //     return 0;
  //   });
  // };
  const sortProjects = (projects) => {
    return [...projects].sort((a, b) => {
      const getStatusPriority = (project) => {
        if (project.allRounds?.some((round) => getRoundStatus(round.startDate, round.endDate) === "ending")) {
          return 0; // Highest priority (ending)
        }
        if (project.allRounds?.some((round) => getRoundStatus(round.startDate, round.endDate) === "starting")) {
          return 1; // Second priority (starting)
        }
        return 2; // Lowest priority (other)
      };
  
      return getStatusPriority(a) - getStatusPriority(b);
    });
  };

  const getTimeRemaining = (startDate, endDate, status) => {
    const currentTime = new Date();
    let timeDiff;

    if (status === "starting") {
      timeDiff = new Date(startDate) - currentTime;
    } else if (status === "ending") {
      timeDiff = new Date(endDate) - currentTime;
    }

    if (timeDiff <= 0) return null;

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const getRoundStatus = (startDate, endDate) => {
    const currentTime = new Date();
    if (currentTime < new Date(startDate)) return "starting";
    if (currentTime <= new Date(endDate)) return "ending";
    return "ended";
  };

  const getAllProjectsData = async (page = 1) => {
    setAllprojectssss([]);
    setMainLoader1(true);
    try {
      const config = {
        method: "get",
        url: `${Environment?.Api_Url}/projects/all?limit=12&offset=${page}`,
      };

      const response = await axios(config);
      const data = response.data?.data;

      setTotalPages(data?.pages || 0);
      setTotalCount(data?.count || 0);
      setAllprojectssss(data?.projects || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setMainLoader1(false);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (role === "all") {
      getAllProjectsData(currentPage);
    }
  }, [role, currentPage]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const sortedProjects = sortProjects(Allprojectssss);

  return (
    <>
      <section className="process-projects presale-cards">
        <div className="custom-container padd-right-sm">
          <div className={Allprojectssss?.length <= 0 ? "" : "bottom-cards"}>
            {sortedProjects?.map((project, index) => {
              const validRound = project?.allRounds?.find((round) => {
                const status = getRoundStatus(round?.startDate, round?.endDate);
                return status === "starting" || status === "ending";
              });

              const roundStatus = validRound
                ? getRoundStatus(validRound.startDate, validRound.endDate)
                : "ended";

              const remainingTime = validRound
                ? getTimeRemaining(
                    validRound.startDate,
                    validRound.endDate,
                    roundStatus
                  )
                : null;

              return (
                <div className="single-card" key={index}>
                  <Link to={`/Gems/${project?.projectName}`}>
                    {/* Project Image */}
                    <div className="main-token-img">
                      <img
                        width={70}
                        height={70}
                        src={project?.projectImg}
                        alt="img"
                        className="img-fluid"
                      />
                    </div>

                    {/* Project Info */}
                    <div className="token-info">
                      <h6>
                        {project?.projectName}
                        {project?.isPremiumProject && (
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="12"
                              viewBox="0 0 13 12"
                              fill="none"
                            >
                              <path
                                d="M7.45048 0.257202L8.48835 3.53194L12.1008 3.45449L12.7619 5.38046L9.79188 7.32689L10.9857 10.5534L9.2544 11.7429L6.38016 9.67079L3.50592 11.7429L1.77458 10.5534L2.96845 7.32689L0 5.379L0.661029 3.45303L4.27352 3.53048L5.3114 0.257202H7.45048Z"
                                fill="#30F1B6"
                              />
                            </svg>
                            Verified Gems
                          </span>
                        )}
                      </h6>
                      <p className="sub-title">{project?.tokenSymbol}</p>
                      {project?.projectSummary && (
                        <p className="para description">
                          {project?.projectSummary}
                        </p>
                      )}
                    </div>

                    {/* Price Info */}
                    <div className="tokenprice-info">
                      <div className="text">
                        <p>{t("Gems-detailt9")}</p>
                        <h6>
                          {validRound?.roundNumber || "--"} /
                          {project?.allRounds?.length || 0}
                        </h6>
                      </div>
                      <div className="text">
                        <p>{t("Process-t3")}</p>
                        <h6>
                          {validRound?.roundPrice
                            ? `$${validRound.roundPrice}`
                            : "--"}
                        </h6>
                      </div>
                      <div className="text">
                        <p>{t("Totalrasied")}</p>
                        <h6>
                          {currencyFormatter.format(
                            project?.totalAmountRaised?.[0]
                              ?.totalAmountRaised || 0,
                            { code: "USD" }
                          )}{" "}
                          /
                          {currencyFormatter.format(project?.hardCap || 0, {
                            code: "USD",
                          })}
                        </h6>
                      </div>
                    </div>
                  </Link>

                  {/* Round Status */}
                  <div className="round-info">
                    {roundStatus === "starting" && (
                      <>
                        <h6>{t("Process-t13")}</h6>
                        <h6>
                          {remainingTime?.days || 0}D :
                          {remainingTime?.hours || 0}H :
                          {remainingTime?.minutes || 0}M :
                          {remainingTime?.seconds || 0}S
                        </h6>
                      </>
                    )}

                    {roundStatus === "ending" && (
                      <>
                        <h6>{t("Process-t10")}</h6>
                        <h6>
                          {remainingTime?.days || 0}D :
                          {remainingTime?.hours || 0}H :
                          {remainingTime?.minutes || 0}M :
                          {remainingTime?.seconds || 0}S
                        </h6>
                      </>
                    )}

                    {roundStatus === "ended" && (
                      <>
                        <h6>{t("RoundIs")}</h6>
                        <h6 className="ended">{t("Finished")}</h6>
                      </>
                    )}
                  </div>

                  {/* Action Button */}
                  {roundStatus === "starting" ? (
                    <button
                      className="btn-buy"
                      style={{ opacity: "0.5", pointerEvents: "none" }}
                    >
                      {t("Btn-buynow")}
                    </button>
                  ) : roundStatus === "ending" ? (
                    <a
                      href={`https://buy.gems.vip/?projectName=${project.projectName}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn-green"
                    >
                      {t("Btn-buynow")}
                    </a>
                  ) : (
                    <Link
                      to={`/Gems/${project.projectName}`}
                      className="btn-buy"
                    >
                      {t("Process-t7")}
                    </Link>
                  )}
                </div>
              );
            })}

            {Allprojectssss?.length <= 0 && (
              <>
                <div className="whencardsnotshowing">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="38"
                    height="37"
                    viewBox="0 0 38 37"
                    fill="none"
                  >
                    <path
                      d="M0.5 36.5L0.500002 20.8333L4.83334 20.8333L4.83334 32.75L0.5 36.5Z"
                      fill="#77868B"
                    />
                    <path
                      d="M0.5 16.166L0.500002 0.49935L4.83334 0.49935L4.83334 12.416L0.5 16.166Z"
                      fill="#77868B"
                    />
                    <rect
                      x="2.5"
                      y="20.834"
                      width="14.5"
                      height="4.33333"
                      fill="#77868B"
                    />
                    <rect
                      x="2.5"
                      y="0.5"
                      width="14.5"
                      height="4.33333"
                      fill="#77868B"
                    />
                    <path
                      d="M21 36.5L21 20.8333L25.3333 20.8333L25.3333 32.75L21 36.5Z"
                      fill="#77868B"
                    />
                    <path
                      d="M21 16.166L21 0.49935L25.3333 0.49935L25.3333 12.416L21 16.166Z"
                      fill="#77868B"
                    />
                    <rect
                      x="23"
                      y="20.834"
                      width="14.5"
                      height="4.33333"
                      fill="#77868B"
                    />
                    <rect
                      x="33"
                      y="32.166"
                      width="4.5"
                      height="4.33333"
                      fill="#77868B"
                    />
                    <rect
                      x="33"
                      y="11.666"
                      width="4.5"
                      height="4.33333"
                      fill="#77868B"
                    />
                    <rect
                      x="12.5"
                      y="32.166"
                      width="4.5"
                      height="4.33333"
                      fill="#77868B"
                    />
                    <rect
                      x="12.5"
                      y="11.666"
                      width="4.5"
                      height="4.33333"
                      fill="#77868B"
                    />
                    <rect
                      x="23"
                      y="0.5"
                      width="14.5"
                      height="4.33333"
                      fill="#77868B"
                    />
                  </svg>
                  <p>GOOD THINGS ARE ON THEIR WAY</p>
                  <a
                    href="https://creators.gems.vip/signup"
                    className="btn-green"
                    target="_blank"
                    style={{ maxWidth: "150px", margin: "0 auto" }}
                  >
                    Create a Project
                  </a>
                </div>
              </>
            )}
          </div>

          {/* Pagination */}
          {Allprojectssss?.length > 12 && (
            <>
              <div className="custom-table">
                <div className="custom-pagination">
                  <p>
                    SHOWING {(currentPage - 1) * 12 + 1}-
                    {Math.min(currentPage * 12, totalCount)} OF {totalCount}
                  </p>
                  <ul className="pagination">
                    {/* Previous Button */}
                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                        href="#"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <g opacity="0.5">
                            <path
                              d="M7.88904 1.93555L5.38763 1.93555L1.99459 7.99682L4.496 7.99682L7.88904 1.93555Z"
                              fill="#939FA3"
                            />
                            <path
                              d="M7.88904 14.0703L5.38763 14.0703L1.99459 8.00904L3.57108 6.96117L4.496 8.00904L7.88904 14.0703Z"
                              fill="#939FA3"
                            />
                            <path
                              d="M8.01655 6.85742H14.9616L13.6869 9.14313H6.74182L8.01655 6.85742Z"
                              fill="#939FA3"
                            />
                          </g>
                        </svg>
                      </a>
                    </li>

                    {/* Page Numbers */}
                    {Array.from({ length: totalPages }, (_, index) => index + 1)
                      .filter((page) => {
                        return (
                          page <= 8 || // First 8 pages
                          page > totalPages - 3 || // Last 3 pages
                          (page >= currentPage - 1 && page <= currentPage + 1) // Nearby current page
                        );
                      })
                      .reduce((acc, page, i, filteredPages) => {
                        if (i > 0 && page - filteredPages[i - 1] > 1)
                          acc.push("...");
                        acc.push(page);
                        return acc;
                      }, [])
                      .map((page, index) =>
                        page === "..." ? (
                          <li
                            key={`ellipsis-${index}`}
                            className="page-item disabled"
                          >
                            <span className="page-link">...</span>
                          </li>
                        ) : (
                          <li
                            key={page}
                            className={`page-item ${
                              currentPage === page ? "active" : ""
                            }`}
                          >
                            <a
                              className="page-link"
                              onClick={() => handlePageChange(page)}
                              href="#"
                            >
                              {page}
                            </a>
                          </li>
                        )
                      )}

                    {/* Next Button */}
                    <li
                      className={`page-item ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                        href="#"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            d="M9.11108 1.93555L11.6125 1.93555L15.0055 7.99682L12.5041 7.99682L9.11108 1.93555Z"
                            fill="#30F1B6"
                          />
                          <path
                            d="M9.11108 14.0703L11.6125 14.0703L15.0055 8.00904L13.429 6.96117L12.5041 8.00904L9.11108 14.0703Z"
                            fill="#30F1B6"
                          />
                          <path
                            d="M3.31318 6.85742H10.2582L8.98351 9.14313H2.03845L3.31318 6.85742Z"
                            fill="#30F1B6"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default PresalecardsAll;
