import { useCallback } from "react";
import useWeb3 from "../hooks/useWeb3";
import environment from "../utils/Environment";
import { minerInsuranceContract } from "../utils/contractHelpers";

const PurchaseInsurance = () => {
  const web3 = useWeb3();
  const tokenAddress = environment.minerInsurance;
  const contract = minerInsuranceContract(tokenAddress, web3);
  const purchaseInsurance = useCallback(
    async (postApiRes, account, amount) => {
      // let nd = 1000000000000000000000;
      let nd = web3.utils.toWei(amount.toString(), "ether");
      try {
        // let amnt = amount * 10 ** 18;

        // const gas = await contract.methods
        //   .stake(nd)
        //   .estimateGas({ from: account });
        // const details = await contract.methods.stake(nd?.toString()).send({
        //   from: account,
        //   gas,
        // });

        const details = await contract.methods
          .purchaseInsurance(
            postApiRes?.token,
            postApiRes?.prices,
            postApiRes?.deadline,
            postApiRes?.quantities,
            postApiRes?.tokenPrice,
            postApiRes?.normalizationFactor,
            postApiRes?.trxHash,
            postApiRes?.v,
            postApiRes?.r,
            postApiRes?.s
          )
          .send({
            from: account,
            // gas,
            value: nd * 0.1,
          });

        const transactionHash = details.transactionHash;
        console.log("Transaction Hash:", transactionHash);

        const receipt = await web3.eth.getTransactionReceipt(transactionHash);
        console.log("Transaction receipt:", receipt);

        const blockNumber = receipt.blockNumber;
        console.log("Transaction block number:", blockNumber);

        const block = await web3.eth.getBlock(blockNumber);
        console.log("Transaction block :", block);

        const timestamp = block.timestamp;

        console.log("Transaction Timestamp:", timestamp);

        return { details, transactionHash, timestamp };
      } catch (error) {
        throw error;
      }
    },
    [contract]
  );
  return { purchaseInsurance: purchaseInsurance };
};
export default PurchaseInsurance;
