import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Navbar from "../landing/header/Navbar";

const BannerApply = ({ scrollToSecondSection }) => {
  const { t } = useTranslation();

  // const [showNav, setShowNav] = useState(true);

  // useEffect(() => {
  //   let timeoutId;

  //   const handleScroll = () => {
  //     setShowNav(true);
  //     clearTimeout(timeoutId);
  //     timeoutId = setTimeout(() => {
  //       setShowNav(false);
  //     }, 3000);
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //     clearTimeout(timeoutId);
  //   };
  // }, []);
  return (
    <>
      <Navbar />
      <section className="main-careerbanner">
        <img
          src="/assets/education/leftshade.png"
          alt="leftshade"
          className="leftshade"
        />
        <img
          src="/assets/education/rightshade.png"
          alt="leftshade"
          className="rightshade"
        />
        <img
          src="/assets/education/leftshadembl.png"
          alt="leftshade"
          className="leftshadembl d-none"
        />
        <img
          src="/assets/education/rightshadembl.png"
          alt="leftshade"
          className="rightshadembl d-none"
        />
        <div className="custom-container">
          <div className="main-content">
            <div className="contentheads">
              <p className="upper-text">{t("Applynew1")}</p>
              <h2 className="lower-text">
                {/* {t('Joinrevolution')} */}
                {t("Applynew2")}
                <br /> <span>{t("Applynew3")}</span>
              </h2>
              {/* <p className="faqspara">{t('Empowerinno')}</p> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BannerApply;
