export const Api_Url = "https://prodapi.gems.vip";
export const Api_Url_Leia_Claims = "https://leia-claims-api.gems.vip"; // main

// export const Api_Url_Leia_Claims = "https://stg-api-leiaclaims.quecko.org"; // staging

// export const API_URL_2 = "https://stg-api-leiaclaims.quecko.org"; // staging

export const API_URL_2 = "https://leia-claims-api.gems.vip"; // main

// export const Api_Url= "http://ec2-18-236-118-29.us-west-2.compute.amazonaws.com:8007",
// export const Api_Url = "http://192.168.20.197:4000";

export const API_URL_SURVEY = "https://apicommunityprod.gems.vip";

// export const Api_Url = "http://54.82.115.121:3000";      // staging without ssl

// export const Api_Url = "https://dev-api-gems.quecko.org"; // staging with ssl
