import React, { useEffect } from 'react';

const PdfViewer = () => {
    const pdfUrl = "/assets/alldetailpages/rain/whitepaperv1.0.pdf";

    useEffect(() => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            window.location.href = pdfUrl; // Auto-download on mobile
        }
    }, []);

    return (
        <section className="pdfviewer">
            <iframe
                src={pdfUrl}
                title="PDF Viewer"
            />
        </section>
    );
};

export default PdfViewer;

