import React, { useState } from "react";
import { Player } from "video-react";
import { useTranslation } from "react-i18next";
import { Accordion, Modal } from "react-bootstrap";

const Information = () => {
  const { t } = useTranslation();

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);

  return (
    <>
      <section className="information-section rain-information-section">
        <div className="first-para">
          <h2>
            {t("Dop-detail4")} <span></span>
          </h2>
          <p>
            RAIN is an automated, decentralized prediction market protocol that
            enables anyone to create, participate in, and monetize prediction
            markets across a wide range of real-world topics. Built on
            cutting-edge blockchain infrastructure, RAIN combines automation,
            transparency, and flexible verification to eliminate inefficiencies
            in traditional forecasting systems.
          </p>
          <p>
            At its core, RAIN is a permissionless system designed to unlock
            global predictive intelligence. With advanced tokenomics,
            dual-verification mechanisms, and decentralized governance, it
            offers a scalable and trustless platform for transforming insights
            into value—empowering individuals, organizations, and institutions
            worldwide.
          </p>
        </div>
        <div className="first-para">
          <h2 className="smallhead">
            WHAT MAKES RAIN UNIQUE <span></span>
          </h2>
          <p>
            Automated Prediction Markets: RAIN is not an outcome market; it is a
            fully automated protocol enabling the creation and resolution of
            prediction markets without central authorities.
          </p>
          <ol className="rain-list">
            {/* <li><span>Automated Prediction Markets:</span> RAIN is not an outcome market; it is a fully automated protocol enabling the creation and resolution of prediction markets without central authorities.</li> */}
            <li>
              <span>Dual Verification via Oracles:</span> RAIN uses an oracle
              system with an optimistic oracle approach for most markets. When
              outcomes are uncontested, they are resolved automatically. In the
              event of a dispute, a fallback to standard dispute-resolution
              oracles ensures accuracy and integrity.
            </li>
            <li>
              <span>Innovative Pool-Based Market Architecture:</span> RAIN's
              initial pool design allows users to continuously buy positions by
              issuing new shares in each outcome, while liquidity remains locked
              in the market. This approach eliminates the limitations of order
              books in low-liquidity environments and enables the creation of
              long-tail, private, and niche markets that would otherwise be
              impractical—expanding the reach and utility of prediction markets
              at scale.
            </li>
            <li>
              <span>Robust Tokenomics:</span> A deflationary model with
              integrated buyback-and-burn mechanics incentivizes accuracy,
              participation, and long-term value alignment for all ecosystem
              actors.
            </li>
            <li>
              <span>Decentralized Governance:</span> Token holders govern the
              protocol, proposing and voting on upgrades, market types, and
              system parameters—ensuring the platform evolves with its
              community.
            </li>
            <li>
              <span>Open and Borderless:</span> As a protocol, RAIN is designed
              to support multiple independent front-ends, enabling a truly open
              and borderless system where anyone can access or build upon the
              infrastructure from anywhere in the world.
            </li>
            <li>
              <span>Aligned Economic Incentives:</span> Transparent rewards for
              knowledge contributors, liquidity providers, and market creators
              promote high-quality engagement and sustainable growth.
            </li>
          </ol>
        </div>
        <div className="first-para">
          <h2>
            REVOLUTIONIZING PREDICTIVE INTELLIGENCE <span></span>
          </h2>
          <ol className="rain-list">
            <li>
              <span>Turning Knowledge into Capital:</span> RAIN incentivizes
              users to transform insights into income by creating, verifying,
              and participating in prediction markets.
            </li>
            <li>
              <span>Superior Accuracy:</span> Collective, decentralized
              forecasting through RAIN has the potential to outperform
              traditional methods by over 30% in predictive precision.
            </li>
            <li>
              <span>Broad Industry Applications:</span> Use cases span finance,
              sports, global events, healthcare, supply chains, and more—making
              RAIN a versatile infrastructure layer for future decision-making.
            </li>
            <li>
              <span>Community-Powered Growth:</span> By attracting diverse
              contributors, the platform cultivates a dynamic ecosystem of
              decentralized intelligence and economic opportunity.
            </li>
          </ol>
        </div>
        <div className="first-para">
          <h2>
            ROADMAP <span></span>
          </h2>
          <h6 className="rainhead">Phase 1 – Q1 2025:</h6>
          <ol className="rain-list marginlist">
            <li>
              Launch MVP with core automated prediction functionality,
              dual-verification oracle system, and foundational tokenomics.
            </li>
          </ol>
          <h6 className="rainhead">Phase 2 – Q4 2025:</h6>
          <ol className="rain-list marginlist">
            <li>
              Public platform launch with decentralized governance, open market
              creation, and initial verticals in sports, science, and
              geopolitics.
            </li>
            <li>
              Release Version 2, featuring a hybrid trading model that combines
              the pool-based system with an order book—enabling full trading
              functionality and the ability to sell out of positions.
            </li>
          </ol>
          <h6 className="rainhead">Phase 3 – 2026:</h6>
          <ol className="rain-list marginlist">
            <li>
              Feature expansion: advanced analytics, cross-chain compatibility,
              and support for new market structures (e.g., conditional markets,
              long-term forecasting).
            </li>
          </ol>
          <h6 className="rainhead">Phase 4 – 2027–2028:</h6>
          <ol className="rain-list listset">
            <li>
              Global scale-up with a focus on institutional tools, enterprise
              integrations, and real-world adoption at scale.
            </li>
          </ol>
        </div>
        <div className="first-para">
          <p>
            RAIN is more than a platform—it’s a protocol for the future of
            forecasting. By enabling open access to predictive power and
            aligning incentives across stakeholders, RAIN is building the
            infrastructure for a smarter, more informed world.
          </p>
        </div>
        <div className="first-para">
          <h2>
            {t("Dop-detail31")} <span></span>
          </h2>
          <p>
            RAIN’s tokenomics are designed to support sustainable growth, high
            integrity, and long-term alignment across all stakeholders. The
            token plays a central role in governance, protocol incentives, and
            economic design—but without forcing usage complexity at the user
            level.
          </p>
          <p>
            At launch, RAIN is a governance token. Users do not need to hold
            RAIN to use the prediction markets. Only those who wish to help
            shape the protocol’s future through proposals and votes will need
            the token.
          </p>
          {/* <div className="twice-tokenomics">
                        <div className="tokenomic-img" onClick={() => {setShow1(true)}} style={{cursor: "pointer"}}>
                            <img src="\assets\alldetailpages\rain\roadmap-light.png" alt="img" className='img-fluid d-none show-in-light-theme' />
                            <img src="\assets\alldetailpages\rain\raodmap-dark.png" alt="img" className='img-fluid hide-in-light-theme' />
                        </div>
                    </div> */}
        </div>
        <div className="first-para">
          <h3 className="lowerhead">
            Deflationary Model<span></span>
          </h3>
          <p>RAIN features a powerful deflationary mechanism:</p>
          <ol className="rain-list listset">
            <li className="m-0">
              2.5% of the trading volume in every prediction market is used to
              buy back and burn RAIN tokens.
            </li>
            <li className="m-0">
              This ties token scarcity directly to usage and ensures that
              increased adoption benefits all token holders.
            </li>
          </ol>
        </div>
        <div className="first-para">
          <h3 className="lowerhead">
            Inflation Mechanism<span></span>
          </h3>
          <p>
            To maintain liquidity, reward contributors, and support ongoing
            growth, RAIN introduces a controlled inflation model:
          </p>
          <ol className="rain-list listset">
            <li className="m-0">No inflation in the first 3 months</li>
            <li className="m-0">
              Starting month 4, $50,000 worth of RAIN tokens will be minted
              daily
            </li>
            <li className="m-0">
              These tokens are allocated to the team to support rewards,
              ecosystem expansion, contributors, campaigns, and partnerships
            </li>
          </ol>
          <p>
            This balance of deflation through usage and inflation through
            contribution creates a dynamic, sustainable economy for the
            protocol.
          </p>
          <div className="twice-tokenomics">
            <Accordion defaultActiveKey="0" className="rainacc d-none">
                <div className="tophead">
                    <h6 className="firsthead">Category</h6>
                    <h6 className="lasthead">Allocation</h6>
                </div>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                <div className="btnhead">
                    <h6 className="firsthead">Private Sale, Strategic Sale & Miners</h6>
                    <h6 className="lasthead">10%</h6>
                </div>
                </Accordion.Header>
                <Accordion.Body>
                  <h6 className="bodyhead">purpose</h6>
                  <p className="bodypara">Limited float to ensure high fully diluted valuation and bootstrap early protocol activity.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                <div className="btnhead">
                    <h6 className="firsthead">Contributors, Advisors & Strategic Partners</h6>
                    <h6 className="lasthead">10%</h6>
                </div>
                </Accordion.Header>
                <Accordion.Body>
                  <h6 className="bodyhead">purpose</h6>
                  <p className="bodypara">Vesting-based rewards for those supporting early growth and long-term development.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                <div className="btnhead">
                    <h6 className="firsthead">Team</h6>
                    <h6 className="lasthead">10%</h6>
                </div>
                </Accordion.Header>
                <Accordion.Body>
                  <h6 className="bodyhead">purpose</h6>
                  <p className="bodypara">No tokens at launch. Multi-year vesting to ensure alignment and long-term commitment.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                <div className="btnhead">
                    <h6 className="firsthead">Marketing & Development Fund</h6>
                    <h6 className="lasthead">20%</h6>
                </div>
                </Accordion.Header>
                <Accordion.Body>
                  <h6 className="bodyhead">purpose</h6>
                  <p className="bodypara">Used to fund operations, protocol development, marketing, campaigns, and user acquisition.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                <div className="btnhead">
                    <h6 className="firsthead">Launchpad, Exchanges & Liquidity Providers </h6>
                    <h6 className="lasthead">15%</h6>
                </div>
                </Accordion.Header>
                <Accordion.Body>
                  <h6 className="bodyhead">purpose</h6>
                  <p className="bodypara">Supports liquidity provisioning, listings, and key exchange partnerships.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                <div className="btnhead">
                    <h6 className="firsthead">Ecosystem Growth & Staking</h6>
                    <h6 className="lasthead">15%</h6>
                </div>
                </Accordion.Header>
                <Accordion.Body>
                  <h6 className="bodyhead">purpose</h6>
                  <p className="bodypara">Used to reward users, fund staking incentives, community grants, and ecosystem expansion.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header className="bordernone">
                <div className="btnhead">
                    <h6 className="firsthead">Reserve & Treasury</h6>
                    <h6 className="lasthead">20%</h6>
                </div>
                </Accordion.Header>
                <Accordion.Body className="bordertop">
                  <h6 className="bodyhead">purpose</h6>
                  <p className="bodypara">Protocol-owned tokens for future use, managed by multisig or DAO governance over time.</p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div
              className="tokenomic-img p-0 d-noneformbl"
              style={{ cursor: "pointer" }}
            >
              <img
                src="\assets\alldetailpages\rain\table-light.png"
                alt="img"
                className="img-fluid d-none show-in-light-theme"
              />
              <img
                src="\assets\alldetailpages\rain\table-dark.png"
                alt="img"
                className="img-fluid hide-in-light-theme"
              />
            </div>
            <div
              className="tokenomic-img d-noneformbl"
              onClick={() => {
                setShow1(true);
              }}
              style={{ cursor: "pointer" }}
            >
              <img
                src="\assets\alldetailpages\rain\roadmap-light.png"
                alt="img"
                className="img-fluid d-none show-in-light-theme"
              />
              <img
                src="\assets\alldetailpages\rain\raodmap-dark.png"
                alt="img"
                className="img-fluid hide-in-light-theme"
              />
            </div>

            <div
              className="tokenomic-imgg d-blockformobile d-none w-100"
              onClick={() => {
                setShow1(true);
              }}
              style={{ cursor: "pointer" }}
            >
              <img
                src="\assets\alldetailpages\rain\roadmap-lightmbl.png"
                alt="img"
                className="img-fluid d-none show-in-light-theme w-100"
              />
              <img
                src="\assets\alldetailpages\rain\raodmap-darkmbl.png"
                alt="img"
                className="img-fluid hide-in-light-theme w-100"
              />
            </div>
          </div>
        </div>
      </section>

      <Modal
        className="common-modal-style image-preview distribution-modl"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-0">
          <img
            src="\assets\alldetailpages\rain\roadmap-lightmbl.png"
            alt="img"
            className="img-fluid d-none show-in-light-theme w-100"
          />
          <img
            src="\assets\alldetailpages\rain\raodmap-darkmbl.png"
            alt="img"
            className="img-fluid hide-in-light-theme w-100"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Information;
