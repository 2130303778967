let Environment = {
  chinaTomi: "0xaDF3202480bD62F3dB150E27A0F811f999F253B9",
  leia: "0x3aEdC9075A8238A68BEc28495ea566f9abcA2840",
  GemsLocking: "0x6A1aa5ac35f38Bdb25BBD976E8eca942b23260b2",
  GemsStaking: "0x03B830791ebb7c70C47936A57cDf02D13ff41168",
  GemsToken: "0x3010ccb5419F1EF26D40a7cd3F0d707a0fa127Dc",
  StackContact: "0xF5c9F83C14db63ab8260a8d19d0B3eC8B1903E92",
  // Doptoken: "0x3010ccb5419F1EF26D40a7cd3F0d707a0fa127Dc",
  StackcontactGems: "0x03B830791ebb7c70C47936A57cDf02D13ff41168",
  StackContactDop: "0x953be9C1ADb9c651bBEC52E614c06EEe7FF9AA27",
  TomiContract: "0x4385328cc4D643Ca98DfEA734360C0F596C83449",
  StackContractTomi: "0xEB3c2E183660D52B76f386ac5BD91dB3A4B3ED63",
  Api_Url: "https://prodapi.gems.vip",
  nodeMinerSale: "0xbd074A0Cd91DBa50e892a863842501795BC59A99",
  AstroNovaGraph:
    "https://subgraph.satsuma-prod.com/323ce71d2cc0/usman--186639/astro-nova-live/api",
  NFAGraph:
    "https://api.studio.thegraph.com/query/63987/nfa-mainnet/version/latest",
  dop1: "0xF49e4197324059e819690a0588dec5AB25d3985C",
  dop: "0xec135D3aEe90a5119681Ce4317BA42281edA7b49",
  dopDynamic: "0xb9F0ACa7A19155ed63024Ce301336a191DB7062F",

  insurance: "0x21DA8afc2b24d8a9A126638b5f142Bf77a19CB59",
  minerInsurance: "0x86f9E6D7E3D159ec928b980d4146f65f6d77C3Dd",

  USDT: "0xdAC17F958D2ee523a2206206994597C13D831ec7", //mainnet
  TOMI: "0x4385328cc4D643Ca98DfEA734360C0F596C83449", //mainnet
  ETH: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE", //mainnet
  USDC: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48", //mainnet
  WBTC: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599", //mainnet
  GEMS: "0x3010ccb5419F1EF26D40a7cd3F0d707a0fa127Dc", //mainnet
  PEPE: "0x6982508145454Ce325dDbE47a25d4ec3d2311933",
  LINK: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
  UNI: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
  DOP: "0x97A9a15168C22B3C137E6381037E1499C8ad0978",
};
export default Environment;

// http://gems-ambassador-prod-lb-1707117272.us-east-1.elb.amazonaws.com/
// let Environment = {
//   // api_url: "http://192.168.20.70:8080",
//   // api_url: "https://wizardapi.quecko.com",
//   // api_url: "http://ec2-54-191-120-95.us-west-2.compute.amazonaws.com:8080",
//   // marketPlaceContract: "0x161975F0f31e935eD9f622A43cdcbF7b0FC216a3",
//   StackContact: "0x03B830791ebb7c70C47936A57cDf02D13ff41168",
//   // launchPadContract: "0xFc1F0Be40C59aBa1124532f4ceDE63Ff5CB84f1F",
//   // nftLaunchpad: '0x5D22b2400cF591a6A08D8EBa5D69f871D1DeD367'
//   ClamingContract: "0x3A6daD990fB68571dbEA87817fbd1127Ef681Ca3",
//   // nftLaunchpad: '0xd8F75Dd989Ab32420155bce4804e5af0cA41F895',
//   Doptoken: "0x3010ccb5419F1EF26D40a7cd3F0d707a0fa127Dc",
//   StackGraph:
//     "https://api.studio.thegraph.com/query/79148/gemsstaking/version/latest",
// };

// export default Environment;
