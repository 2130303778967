import React from 'react'
import { Player } from "video-react";
import { useTranslation } from 'react-i18next'

const Information = () => {
  const { t } = useTranslation()

    return (
        <>
            <section className="information-section">
                <div className="first-para" style={{margin: "0"}}>
                    <h2>{t('Gems-detailt11')} <span></span></h2>
                    <p>{t('Gems-detailt12')}</p>
                </div>
                <div className="img-wrapper">
                    <img src="\assets\alldetailpages\gemsdetailpage\info\projectinfo1.png" alt="img" className='img-fluid' />
                </div>
                <div className="first-para">
                    <h2>{t('Gems-detailt13')}<span></span></h2>
                    <p>{t('Gems-detailt14')}</p>
                </div>
                <div className="first-para">
                    <h2>{t('Gems-detailt15')} <span></span></h2>
                    <p>{t('Gems-detailt16')}</p>
                </div>
                <div className="first-para">
                    <h2>{t('Gems-detailt17')}<span></span></h2>
                    <p>{t('Gems-detailt18')}</p>
                </div>
                <div className="first-para">
                    <h2>{t('Gems-detailt19')} <span></span></h2>
                    <p>{t('Gems-detailt20')}</p>
                </div>
                <div className="first-para">
                    <h2>{t('Gems-detailt21')} <span></span></h2>
                    <p>{t('Gems-detailt22')}</p>
                    <p>{t('Gems-detailt23')}</p>
                </div>
                <div className="first-para">
                    <h2>{t('Gems-detailt24')} <span></span></h2>
                    <p>{t('Gems-detailt25')}</p>
                    <ul>
                        <li>{t('Gems-detailt26')}</li>
                        <li>{t('Gems-detailt27')}</li>
                    </ul>
                    <p>{t('Gems-detailt28')}</p>
                    <ul>
                        <li>{t('Gems-detailt29')}</li>
                        <li>{t('Gems-detailt30')}</li>
                    </ul>
                    <p>{t('Gems-detailt31')}</p>
                    <ul>
                        <li>{t('Gems-detailt32')}</li>
                        <li>{t('Gems-detailt33')}</li>
                    </ul>
                </div>
                <div className="first-para">
                    <h2>{t('Gems-detailt34')} <span></span></h2>
                    <div className="team-gems">
                    <div className="single-team">
                            <img src="\assets\alldetailpages\gemsdetailpage\info\team\IsaacJoshua.jpg" alt="img" className='img-fluid' />
                            <h6>Isaac Joshua – CEO, Gems Launchpad</h6>
                            <p>Isaac has over 8 years of experience in blockchain, Web3, and crypto. He leads the Gems Launchpad, drawing on his background in both private and publicly traded crypto firms.</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\alldetailpages\gemsdetailpage\info\team\GuyWinter.jpg" alt="img" className='img-fluid' />
                            <h6>Gai Winter – Business Development, Gems Launchpad</h6>
                            <p>Gai has extensive experience in Web3 and blockchain. He focuses on building partnerships with KOLs, VCs, and angel investors to grow communities.</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\alldetailpages\gemsdetailpage\info\team\GittiZach.jpg" alt="img" className='img-fluid' />
                            <h6>Gitai Zakh – Business Development & Operations, Gems Launchpad</h6>
                            <p>Gitai is a blockchain professional with over 10 years of experience in digital assets, tokenization, B2B SaaS, and Web3 business development.</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\alldetailpages\gemsdetailpage\info\team\EdenKedem.jpg" alt="img" className='img-fluid' />
                            <h6>Adan Kedem – Head of Marketing, Gems Launchpad</h6>
                            <p>Adan is a digital marketer with over a decade of experience in Web3 and blockchain business development. He specializes in community building and digital strategies.</p>
                        </div>
                    </div>

                </div>
                <div className="first-para">
                    <h2>{t('Gems-detailt43')} <span></span></h2>
                    <div className="twice-tokenomics">
                        <div className="tokenomic-img">
                            <img src="\assets\alldetailpages\gemsdetailpage\info\gems-tokenmoics-dark.png" alt="img" className='img-fluid hide-in-light-theme' />
                            <img src="\assets\alldetailpages\gemsdetailpage\info\gems-tokenmoics.png" alt="img" className='img-fluid d-none show-in-light-theme' />
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Information
