import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const Information = () => {
    const { t } = useTranslation()



    return (
        <>
            <section className="information-section">
                <div className="first-para" style={{ margin: "0" }}>
                    <h6>{t('Gn1')}</h6>
                    <h2>{t('Gn2')} <span></span></h2>
                    <p>{t('Gn3')}
                    </p>
                    <p>{t('Gn4')}</p>
                    <p>{t('Gn5')}</p>
                    <ul>
                        <li>{t('Gn6')}</li>
                        <li>{t('Gn7')}</li>
                        <li>{t('Gn8')}</li>
                    </ul>

                </div>
                <div className="first-para">
                    <h2>{t('Gn9')} <span></span></h2>
                    <ul>
                        <li>{t('Gn10')}
                        </li>
                        <li>{t('Gn11')}</li>
                        <li>{t('Gn12')}</li>
                    </ul>
                </div>
                <div className="first-para">
                    <h2>{t('Gn13')}  <span></span></h2>
                    <ul>
                        <li>{t('Gn14')}
                        </li>
                        <li>{t('Gn15')}
                        </li>
                        <li>{t('Gn16')}</li>
                        <li>{t('Gn17')}</li>
                        <li>{t('Gn18')}</li>
                        <li>{t('Gn19')}</li>
                    </ul>
                </div>
                <div className="first-para">
                    <h2>{t('Gn20')}  <span></span></h2>
                    <h6>{t('Gn21')}</h6>
                    <p>{t('Gn22')}</p>
                    <ul>
                        <li>{t('Gn23')}</li>
                        <li>{t('Gn24')}
                        </li>
                        <li><span>{t('Gn25')}</span></li>
                    </ul>
                    <h6>{t('Gn26')}</h6>
                    <ul>
                        <li>{t('Gn27')}</li>
                        <li>{t('Gn28')}</li>
                        <li>{t('Gn29')}</li>
                        <li>{t('Gn30')}</li>
                    </ul>
                </div>
                <div className="first-para">
                    <h2>{t('Gn31')}<span></span></h2>
                    <ul>
                        <li><span>{t('Gn32')}</span> </li>
                        <li><span>{t('Gn33')}</span> </li>
                        <li><span>{t('Gn34')}</span> </li>
                        <li><span>{t('Gn35')}</span> </li>
                        <li><span>{t('Gn36')}</span> </li>
                    </ul>
                </div>
                <div className="first-para">
                    <h2>{t('Gn37')}<span></span></h2>
                    <ul>
                        <li>{t('Gn39')}</li>
                        <li>{t('Gn40')}</li>
                    </ul>
                </div>

            </section>

            
        </>
    )
}

export default Information