import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import CuurentRound from "../../hooks/DataFetchers/round.js";
import Timer from "../../hooks/DataFetchers/time.js";
import CuurentRoundl from "../../hooks/DataFetchers/roundl.js";
import Timerl from "../../hooks/DataFetchers/timel.js";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import { Api_Url } from "../../utils/ApiUrl.js";
import { Api_Url_Leia_Claims, API_URL_2 } from "../../utils/ApiUrl.js";

import useSignatureLeiaClaims from "../../hooks/userSignLeiaClaims";
import Loader from "../../hooks/loader.js";
import { toast } from "react-toastify";

const AllProjectsPremium = ({ role }) => {
  const [Allprojectssss, setAllprojectssss] = useState([]);
  const { account, chainId } = useWeb3React();
  // const account = "0x9799C5820A3bB7f97a1099432C9cFdF164fd50F7";

  const { userSignLeiaClaims } = useSignatureLeiaClaims();
  const [loader, setLoader] = useState(false);
  const [error, seterror] = useState("");
  const [user, setUser] = useState(false);
  const [msg, setMsg] = useState("");

  const getAllProjectsData = async () => {
    if (role === "premium") {
      setAllprojectssss([]);
    }
    if (role === "premium") {
      var config = {
        method: "get",
        url: `${Api_Url}/users/premium/buyer-listing?by=${account.toLowerCase()}&limit=20&offset=1`,
        headers: {
          // authorization: `Bearer ${tok}`
        },
      };
    }

    axios(config)
      .then(function (response) {
        // console.log("responce", response)
        // console.log("valueheresss", response)
        // console.log("asddewdfewfwefwef", response)
        setAllprojectssss(response?.data?.data?.buyerParticipation);
      })
      .catch(function (error) {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    if (role && account) {
      if (role === "premium") {
        getAllProjectsData();
      }
    }
  }, [role, account]);

  const getUserSign = async () => {
    if (account) {
      if (chainId == 1) {
        // console.log("chain id")
        signInUser();

        // getIsClaimAvailable();
      } else {
        toast.error("Please Switch to Ethereum Mainnet from your wallet");
      }
    }
  };

  const signInUser = async () => {
    // console.log("innnnnnn")
    let devicee = localStorage.getItem("device");
    if (devicee === "Safe{Wallet}") {
      console.log(devicee, "deviceedeviceedevicee");
      // if (chainId === 1) {
      let tok = localStorage.getItem("dopToken");
      let addrSign = localStorage.getItem("addrSign");
      if (tok && addrSign === account) {
        window.location.assign("/claimschedule");
      } else {
        setLoader(true);
        setMsg("Please check your wallet for signature");
        try {
          let v = await userSignLeiaClaims();
          setMsg("");
          var config = {
            method: "post",
            url: `${API_URL_2}/auth/signin/safe`,
            data: {
              walletAddress: account.toLowerCase(),
              sign: v,
            },
          };
          await axios(config)
            .then(function (res) {
              const resData = res?.data?.data;
              console.log(resData, "resData sigin");
              localStorage?.setItem("dopToken", resData?.accessToken);
              localStorage?.setItem("refreshToken", resData?.refreshToken);
              localStorage?.setItem("addrSign", account);
              localStorage?.setItem("signValue", v);
              window.location.assign("/claimschedule");
              // window.location.reload()
            })
            .catch(function (err) {
              if (err?.response?.data?.statusCode === 403) {
                seterror(err.response.data.message);
              } else {
                seterror(
                  "This wallet is not eligible for any LEIA token claims."
                );
              }

              // console.log("error here",err.response.data.message)

              setUser(true);
              setLoader(false);
              throw err;
            });
        } catch (err) {
          setLoader(false);
        }
      }
      // }
    } else {
      console.log(devicee, " esle deviceedeviceedevicee");
      // if (chainId === 1) {
      let tok = localStorage.getItem("dopToken");
      console.log(tok, " esle dop token");

      let addrSign = localStorage.getItem("addrSign");
      console.log(addrSign, " esle address token");

      if (tok && addrSign.toLowerCase() === account.toLowerCase()) {
        console.log("if further");
        window.location.assign("/claimschedule");
      } else {
        console.log("else further");

        setLoader(true);
        setMsg("Please check your wallet for signature");
        try {
          let v = await userSignLeiaClaims();
          setMsg("");
          var config = {
            method: "post",
            url: `${API_URL_2}/auth/signin`,
            data: {
              walletAddress: account.toLowerCase(),
              sign: v,
            },
          };
          await axios(config)
            .then(function (res) {
              const resData = res?.data?.data;
              console.log(resData, "resData sigin");
              localStorage?.setItem("dopToken", resData?.accessToken);
              localStorage?.setItem("refreshToken", resData?.refreshToken);
              localStorage?.setItem("addrSign", account);
              localStorage?.setItem("signValue", v);
              window.location.assign("/claimschedule");
              // window.location.reload()
            })
            .catch(function (err) {
              if (err?.response?.data?.statusCode === 403) {
                seterror(err.response.data.message);
              } else {
                seterror(
                  "This wallet is not eligible for any LEIA token claims."
                );
              }
              setUser(true);
              setLoader(false);
              throw err;
            });
        } catch (err) {
          // const connectorId = window.localStorage.getItem("connectorId");
          // await logout(connectorId);
          // localStorage.removeItem("connectorId");
          // localStorage.removeItem("flag");
          console.log(err);

          setLoader(false);
        }
      }
      // }
    }
  };

  return (
    <>
      {loader && (
        <Loader text={"Please sign the transaction from your wallet"} />
      )}
      <div className="custom-table">
        <div class="table-responsive">
          <table className="table m-0">
            <thead>
              <tr>
                <th>Project Name</th>
                <th className="text-center">Total Purchases</th>
                <th className="text-center">Amount Spent</th>
                <th className="text-center">Amount Purchased</th>
                <th className="text-center">Claim Type</th>
                <th className="text-center">Claim Status</th>
                <th className="text-center">Action</th>
                <th className="text-center">Claim Action</th>
              </tr>
            </thead>
            <tbody>
              {Allprojectssss?.map((project, index) => (
                <tr key={project._id || index}>
                  <td>
                    <div className="twice-items">
                      {project?.projectImage ? (
                        <img
                          src={project.projectImage}
                          // src={`/assets/presaletokens/${project?.privateSale?.toLowerCase()}.png`}
                          alt={`${
                            project.projectName || project?.privateSale
                          } Logo`}
                          className="img-fluid"
                        />
                      ) : (
                        <img
                          // src={project.projectImage}
                          src={`/assets/presaletokens/${project?.privateSale?.toLowerCase()}.png`}
                          alt={`${
                            project.projectName || project?.privateSale
                          } Logo`}
                          className="img-fluid"
                        />
                      )}

                      <h6>{project.projectName || project?.privateSale}</h6>
                    </div>
                  </td>
                  <td className="text-center">{project.totalPurchases}</td>
                  <td className="text-center">
                    ${project?.totalSaleConverted?.toLocaleString()}
                  </td>
                  <td className="text-center">
                    {project.dopPurchased.toLocaleString()}{" "}
                    {project.privateSale}
                  </td>
                  {project?.privateSale == "LEIA" ? (
                    <td className="text-center">Vesting</td>
                  ) : (
                    <td className="text-center">Immediate</td>
                  )}
                  {project?.privateSale == "LEIA" ? (
                    <td className="text-center available">Available</td>
                  ) : (
                    <td className="text-center unavailable">Not Available</td>
                  )}
                  <td className="text-center">
                    <Link to={`/allpurchasespremium/${project?._id}`}>
                      All Purchases
                    </Link>
                  </td>
                  <td className="text-center">
                    {/* <Link to="/claimschedule"> */}

                    <button
                      disabled={project?.privateSale != "LEIA"}
                      className={
                        project?.privateSale == "LEIA"
                          ? "btn-claim"
                          : "btn-claim-disabled"
                      }
                      onClick={() => {
                        getUserSign();
                      }}
                    >
                      Claim
                    </button>
                    {/* </Link> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <div className="custom-pagination">
          <p>SHOWING 1-10 OF 145</p>
          <ul class="pagination">
            <li class="page-item">
              <a class="page-link" href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                >
                  <g opacity="0.5">
                    <path
                      d="M7.88904 1.93555L5.38763 1.93555L1.99459 7.99682L4.496 7.99682L7.88904 1.93555Z"
                      fill="#939FA3"
                    />
                    <path
                      d="M7.88904 14.0703L5.38763 14.0703L1.99459 8.00904L3.57108 6.96117L4.496 8.00904L7.88904 14.0703Z"
                      fill="#939FA3"
                    />
                    <path
                      d="M8.01655 6.85742H14.9616L13.6869 9.14313H6.74182L8.01655 6.85742Z"
                      fill="#939FA3"
                    />
                  </g>
                </svg>
              </a>
            </li>
            <li class="page-item">
              <a class="page-link active" href="#">
                1
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                2
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                3
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                4
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                5
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                >
                  <path
                    d="M9.11108 1.93555L11.6125 1.93555L15.0055 7.99682L12.5041 7.99682L9.11108 1.93555Z"
                    fill="#30F1B6"
                  />
                  <path
                    d="M9.11108 14.0703L11.6125 14.0703L15.0055 8.00904L13.429 6.96117L12.5041 8.00904L9.11108 14.0703Z"
                    fill="#30F1B6"
                  />
                  <path
                    d="M3.31318 6.85742H10.2582L8.98351 9.14313H2.03845L3.31318 6.85742Z"
                    fill="#30F1B6"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div> */}
      </div>
    </>
  );
};

export default AllProjectsPremium;
