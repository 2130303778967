import React, { useState } from 'react'
import { Player } from "video-react";
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap';

const Information = () => {
    const { t } = useTranslation()

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);

    return (
        <>
            <section className="information-section information-section-incentiv">
                <div className="first-para" style={{ margin: "0" }}>
                    <h2>{t('Gems-detailt11')} <span></span></h2>
                    <p>{t('Inc-para1')}</p>
                </div>
                <div className="first-para">
                    <h2>{t('Inc-detailt13')}<span></span></h2>
                    <ul>
                        <li>{t('Inc-para2')}</li>
                        <li>{t('Inc-para3')}</li>
                        <li>{t('Inc-para4')}</li>
                    </ul>
                </div>
                <div className="first-para">
                    <h2>{t('Inc-detailt24')} <span></span></h2>
                    <p>{t('Inc-para5')}</p>
                </div>
                <div className="first-para">
                    <p className='text-center'>{t('Formodetech')}, <a href="https://incentiv.net/wp-content/uploads/2024/09/White-Paper-V1.0.pdf" target='_blank'>{t('ReadIncwhitepaper')}</a></p>
                </div>
                <div className="first-para">
                    <h2>{t('Dop-detail31')} <span></span></h2>
                    <div className="twice-tokenomics">
                        <div className="tokenomic-img">
                            <img src="\assets\alldetailpages\incentivdetailpage\banner\inc-tokenmoics-dark.png" alt="img" className='img-fluid hide-in-light-theme' />
                            <img src="\assets\alldetailpages\incentivdetailpage\banner\inc-tokenmoics.png" alt="img" className='img-fluid d-none show-in-light-theme' />
                        </div>
                    </div>

                </div>
                <div className="first-para">
                    <p className='text-center'>{t('Lookingformore')} <a href="https://incentiv.net/wp-content/uploads/2024/09/Foundation-Paper-V1.2.pdf" target='_blank'>{t('ReadIncFoundationpaper')}</a></p>
                </div>
                <div className="first-para">
                    <h2>{t('Distandallocation')} <span></span></h2>
                    <div onClick={() => { setShow1(true) }} className="twice-tokenomics" style={{ cursor: "pointer" }}>
                        <div className="tokenomic-img">
                            <img src="\assets\alldetailpages\incentivdetailpage\banner\incentiv-allocation-dark.png" alt="img" className='img-fluid hide-in-light-theme' />
                            <img src="\assets\alldetailpages\incentivdetailpage\banner\incentiv-allocation.png" alt="img" className='img-fluid d-none show-in-light-theme' />
                        </div>
                    </div>

                </div>
                <div className="first-para">
                    <p className='text-center'>{t('Fordetailinfo')} <a href="https://incentiv.net/wp-content/uploads/2024/09/Foundation-Paper-V1.2.pdf" target='_blank'>{t('Foundpaper')}</a></p>
                </div>
                <div className="first-para">
                    <h2>{t('Inc-detailt34')} <span></span></h2>
                    <div className="team-gems">
                        <div className="single-team">
                            <img src="\assets\alldetailpages\incentivdetailpage\banner\team\t1.webp" alt="img" className='img-fluid' />
                            <h6>{t('Inc-team1h')}</h6>
                            <p>{t('Inc-team1p')}</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\alldetailpages\incentivdetailpage\banner\team\t2.jpg" alt="img" className='img-fluid' />
                            <h6>{t('Inc-team2h')}</h6>
                            <p>{t('Inc-team2p')}</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\alldetailpages\incentivdetailpage\banner\team\t3.jpg" alt="img" className='img-fluid' />
                            <h6>{t('Inc-team3h')}</h6>
                            <p>{t('Inc-team3p')}</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\alldetailpages\incentivdetailpage\banner\team\t4.jpg" alt="img" className='img-fluid' />
                            <h6>{t('Inc-team4h')}</h6>
                            <p>{t('Inc-team4p')}</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\alldetailpages\incentivdetailpage\banner\team\t5.jpg" alt="img" className='img-fluid' />
                            <h6>{t('Inc-team5h')}</h6>
                            <p>{t('Inc-team5p')}</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\alldetailpages\incentivdetailpage\banner\team\t6.jpg" alt="img" className='img-fluid' />
                            <h6>{t('Inc-team6h')}</h6>
                            <p>{t('Inc-team6p')}</p>
                        </div>
                    </div>

                </div>
            </section>



            <Modal className='common-modal-style image-preview' show={show1} onHide={handleClose1} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className='p-0'>
                <img src="\assets\alldetailpages\incentivdetailpage\banner\incentiv-allocation-dark.png" alt="img" className='img-fluid hide-in-light-theme' />
                <img src="\assets\alldetailpages\incentivdetailpage\banner\incentiv-allocation.png" alt="img" className='img-fluid d-none show-in-light-theme' />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Information
