import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getGemsTokenContract } from "../../utils/contractHelpers";

export const GetTokenBalance = () => {
  const web3 = useWeb3();
  const tokenAddress = environment.gems;
  const GetTokenBal = useCallback(
    async (account, tokenSelected) => {
      const contract = getGemsTokenContract(environment?.[tokenSelected], web3);
      const buy = await contract.methods.decimals().call();
      const approved = await contract.methods
        .balanceOf(account?.toString())
        .call();
      const bal = parseInt(approved.toString()) / 10 ** buy;
      return bal;
    },
    [web3]
  );

  return { GetTokenBal: GetTokenBal };
};

export default GetTokenBalance;
