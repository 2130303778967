import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import ReactPaginate from "react-paginate";

const PurchaseNftModal = ({
  arryy,
  page,
  pageCount,
  handlePageChange,
  count,
}) => {
  return (
    <section className="datatable">
      <div className="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>
                <div className="parent-tag">Purchased on</div>
              </th>
              <th>
                <div className="parent-tag">PURCHASE PRICE</div>
              </th>
              <th>
                <div className="parent-tag">Amount Spent</div>
              </th>
              <th>LEIA PURCHASED</th>
            </tr>
          </thead>
          <tbody>
            {arryy?.length > 0
              ? arryy?.map((item, index) => {
                  let date = new Date(item?.createdAt).toLocaleDateString();
                  return (
                    <tr>
                      <td>{date}</td>
                      <td>${item?.price?.toFixed(4)}/LEIA</td>
                      <td>
                        {item?.amountInvested?.toFixed(4)} {item?.tokenSelected}
                      </td>
                      <td>{item?.dopPurchased?.toFixed(4)} LEIA</td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>
        {/* <div className="footer-content">
          <div className="left-f">
          <h6>
                {" "}
                {count > 0 && count <= 5 ? (
                  <>
                    <p>
                      SHOWING 1-{count} OF {count}
                    </p>
                  </>
                ) : count > 5 ? (
                  <>
                    <p>
                      SHOWING {page > 0 ? page * 5 + 1 : page + 1} -
                      {5 * (page + 1) > count ? count : 5 * (page + 1)} OF{" "}
                      {count}
                    </p>
                  </>
                ) : (
                  <>
                    <p>SHOWING 0 OF 0</p>
                  </>
                )}
              </h6>
          </div>
          <div className="right-f">
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <nav aria-label="Page navigation example">
                  <ul class="text-center">
                    <ReactPaginate
                      previousLabel={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            d="M10 13L5 8L10 3"
                            stroke="#fff"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      }
                      nextLabel={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            d="M5 13L10 8L5 3"
                            stroke="#fff"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      }
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                      containerClassName="pagination"
                      activeClassName="active"
                      forcePage={page}
                    ></ReactPaginate>
                  </ul>
                </nav>
              </ul>
            </nav>
          </div>
        </div> */}
      </div>
      <div className="accmblview d-none">
        {arryy?.length > 0
          ? arryy?.map((item, index) => {
              let date = new Date(item?.createdAt).toLocaleDateString();
              return (
                <>
                  <div className="main-twice-text">
                    <h6 className="accmblviewhead">Purchased on</h6>
                  </div>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="accheadermain">
                          <p className="acctext">{date}</p>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="acctexts">
                          <h6 className="textleft">PURCHASE PRICE</h6>
                          <p className="textright">
                            ${item?.price?.toFixed(4)}/LEIA
                          </p>
                        </div>
                        <div className="acctexts">
                          <h6 className="textleft">Amount Spent</h6>
                          <p className="textright">
                            {item?.amountInvested?.toFixed(4)}{" "}
                            {item?.tokenSelected}
                          </p>
                        </div>
                        <div className="acctexts">
                          <h6 className="textleft">LEIA PURCHASED</h6>
                          <p className="textright">
                            {item?.dopPurchased?.toFixed(4)} LEIA
                          </p>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </>
              );
            })
          : "No Data"}

        {/* <div className="footer-content">
          <div className="left-f">  <h6>     {" "}
                {count > 0 && count <= 5 ? (
                  <>
                    <p>
                      SHOWING 1-{count} OF {count}
                    </p>
                  </>
                ) : count > 5 ? (
                  <>
                    <p>
                      SHOWING {page > 0 ? page * 5 + 1 : page + 1} -
                      {5 * (page + 1) > count ? count : 5 * (page + 1)} OF{" "}
                      {count}
                    </p>
                  </>
                ) : (
                  <>
                    <p>SHOWING 0 OF 0</p>
                  </>
                )}</h6></div>
          <div className="right-f">
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <nav aria-label="Page navigation example">
                  <ul class="text-center">
                    <ReactPaginate
                      previousLabel={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            d="M10 13L5 8L10 3"
                            stroke="#fff"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      }
                      nextLabel={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            d="M5 13L10 8L5 3"
                            stroke="#fff"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      }
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                      containerClassName="pagination"
                      activeClassName="active"
                      forcePage={page}
                    ></ReactPaginate>
                  </ul>
                </nav>
              </ul>
            </nav>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default PurchaseNftModal;
