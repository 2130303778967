import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import "./claim.scss";
import Navbar from "../landing/header/Navbar";

import ClaimDops from "../../hooks/ClaimDop";
import { Accordion, Modal } from "react-bootstrap";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
import DopClaim from "../../hooks/isClaimed";
import DopClaim2 from "../../hooks/isClaimed2";
import Loader from "../../hooks/loader";
import ClaimDopsNFT from "../../hooks/ClaimDopNFT";
import DopNFTClaim from "../../hooks/isNftClaimed";
import PurchaseModal from "./DataTablesLeiaClaim/purchaseDetail";
import PurchaseNftModal from "./DataTablesLeiaClaim/purchaseNftDetail";
import { Api_Url_Leia_Claims, API_URL_2 } from "../../utils/ApiUrl";
import { setupNetwork } from "../../utils/wallet";

import ClaimDopsDynamic from "../../hooks/ClaimDopDynamic";
import { dopContract } from "../../utils/contractHelpers";
import Web3 from "web3";

import useAuth from "../../hooks/useAuth";
import moment from "moment";

const Claim = () => {
  const { logout } = useAuth();

  const currentTime = moment().unix();
  const history = useHistory();
  const { Claim } = ClaimDops();

  const { ClaimDynamic } = ClaimDopsDynamic();
  const { account, chainId } = useWeb3React();
  const { IsClaimed } = DopClaim();
  const { IsClaimed2 } = DopClaim2();
  const { IsNFTClaimed } = DopNFTClaim();
  const { ClaimNFT } = ClaimDopsNFT();
  const [claimToken, setClaimToken] = useState(true);
  const [error, setError] = useState("");
  const [claimNFT, setClaimNFT] = useState(false);
  const [onlyTok, setOnlyTok] = useState(false);
  const [terms, setTerms] = useState(false);
  const [round, setRound] = useState(null);
  const [profile, setProfile] = useState(null);
  const [addObj, setAddObj] = useState(null);
  const [addObjNFT, setAddObjNFT] = useState(null);
  const [total, setTotal] = useState(0);
  const [tokenCl, setTokenCl] = useState(false);
  const [isKycVer, setIsKycVer] = useState(true);
  const [nftCl, setNftCl] = useState(false);
  const [nftLeaves, setNftLeaves] = useState(null);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const [pages1, setPages1] = useState([]);
  const [pageCount1, setPageCount1] = useState(0);
  const [page1, setPage1] = useState(0);
  const [count1, setCount1] = useState(0);
  const [arry, setArry] = useState([]);
  const [arry1, setArry1] = useState([]);

  // nft purchase lists
  const [isNfts, setIsNfts] = useState(false);
  const [nft200, setNft200] = useState([]);
  const [nft500, setNft500] = useState([]);
  const [nft1000, setNft1000] = useState([]);
  const [nft5000, setNft5000] = useState([]);
  const [nft10000, setNft10000] = useState([]);
  const [nft30000, setNft30000] = useState([]);
  const [nft75000, setNft75000] = useState([]);
  const [nftDopss, setNftDops] = useState(0);
  const [nftLengths, setNftLengths] = useState(0);
  const [detailTransactions, setDetailTransactios] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    history.push("/claimschedule");
  };
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [airdropAmount, setAirdropAmount] = useState(null);
  const [earnOn, setEarnOn] = useState(null);

  const [key, setKey] = useState("uninsured");

  const [pageInsurance, setPageInsurance] = useState(0);
  const [allowKyc, setAllowKyc] = useState(false);

  useEffect(() => {
    if (profile) {
      setLoader(false);
    }
  }, [profile]);

  useEffect(() => {
    let resss = localStorage.getItem("currentObj");
    setRound(JSON.parse(resss));
    document.body.style.overflow = "auto";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    let timeoutId;
    if (!account) {
      timeoutId = setTimeout(() => {
        updateWallet();
      }, 2000);
    }

    // Cleanup the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, [account]);

  const updateWallet = async () => {
    if (!account) {
      const locaWallet = localStorage.getItem("connectorId");
      console.log("here");
      await logout(locaWallet);
      localStorage.clear();
      history.push("/");
    }
  };

  const isDopClaimed = async () => {
    console.log("dop calculate: ");

    const web3 = new Web3(
      "https://cold-responsive-friday.quiknode.pro/15bbbcf85ec27b075486f39524c5741f49222932/"
      // "https://sepolia.infura.io/v3/9076d24c7c3f4b37922d194bec690461"
    );

    const contract = dopContract(round?.claimContract, web3);

    try {
      let res = await contract.methods.isClaimed(account).call();

      if (res) {
        setTokenCl(res);
      } else {
        setTokenCl(false);
      }
    } catch (err) {
      // setTokenCl(true);
      console.log("errxxxxx", err);
    }

    // try {
    //   let res = null;
    //   if (round?.id === 1) {
    //     res = await IsClaimed(account);
    //     console.log("resresresres", res);
    //     if (res) {
    //       setTokenCl(res);
    //     } else {
    //       setTokenCl(false);
    //     }
    //   } else {
    //     res = await IsClaimed2(account, round?.claimContract);
    //     console.log("resresresres", res);
    //     if (res) {
    //       setTokenCl(res);
    //     } else {
    //       setTokenCl(false);
    //     }
    //   }
    // } catch (err) {
    //   console.log("errxxxxx", err);
    //   // history.push("/");
    //   // window.location.reload();
    // }
  };

  useEffect(() => {
    if (chainId !== 1) {
      // chainSwitch();
    }
  }, [chainId]);

  const ClaimYourDops = async () => {
    try {
      if (round?.id < 4) {
        // console.log("onlyTok", onlyTok, isNfts, claimNFT);
        // if (onlyTok === false && claimNFT === false) {
        //   setOnlyTok(true);
        // } else {
        let token = localStorage.getItem("dopToken");
        setLoader(true);
        let apiResponse = await axios.get(
          `${Api_Url_Leia_Claims}claim/proof${
            round?.id === 1 ? "" : "/vested"
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // handle success

        // console.log("!!!!!!", proofs);
        const proofs = apiResponse?.data?.data?.merkleProof;
        let v = apiResponse?.data?.data?.sign?.v;
        let r = apiResponse?.data?.data?.sign?.r;
        let s = apiResponse?.data?.data?.sign?.s;
        console.log(
          "apiResponse?.data?.data?.proofData?.ids",
          apiResponse?.data?.data?.proofData?.ids
        );
        if (s) {
          try {
            // claimNFT is false forever after first round
            const res = await Claim(
              apiResponse?.data?.data?.proofData?.amount?.toString(),
              account,
              proofs,
              apiResponse?.data?.data?.proofData?.ids,
              apiResponse?.data?.data?.proofData?.nftQuantity,
              apiResponse?.data?.data?.proofData?.isKycRequired,
              false,
              v,
              r,
              s,
              round?.claimContract
              // () => {
              //   setTimeout(() => {
              //     setLoader(false);
              //     setTokenCl(true);
              //     handleShow();
              //   }, [5000]);
              // }
            );
            if (res) {
              setLoader(false);
              setTokenCl(true);
              handleShow();
            }

            console.log("res", res);
            // if (res) {
            //   setLoader(false);
            //   setTokenCl(true);
            //   handleShow();
            // }
            setLoader(false);
          } catch (err) {
            setLoader(false);
            // handle error
            console.log(err, "err on clai");
          }
        }
      } else {
        let token = localStorage.getItem("dopToken");
        setLoader(true);
        let apiResponse = await axios.get(
          `${API_URL_2}/claim/proof/vested/dynamic`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const proofs = apiResponse?.data?.data?.merkleProof;
        let v = apiResponse?.data?.data?.sign?.v;
        let r = apiResponse?.data?.data?.sign?.r;
        let s = apiResponse?.data?.data?.sign?.s;
        let deadline = apiResponse?.data?.data?.sign?.deadline;
        let proofData = apiResponse?.data?.data?.proofData;
        // console.log("apiResponse vested dynamic", apiResponse);

        if (s) {
          try {
            const res = await ClaimDynamic(
              proofData?.amountToClaim,
              proofData?.totalClaims,
              proofData?.claimedAmount,
              proofData?.claimMonths,
              proofs,
              deadline,
              v,
              r,
              s,
              round?.claimContract
              // () => {
              //   setTimeout(() => {
              //     setLoader(false);
              //     setTokenCl(true);
              //     handleShow();
              //   }, [5000]);
              // }
            );
            if (res) {
              console.log("res should be true: ", res);

              // if (res.includes("Error")) {
              //   setLoader(false);
              //   setTokenCl(false);
              //   toast.error("Transaction Failed");
              // } else {
              //   console.log("response on claim: ", res);

              setLoader(false);
              setTokenCl(true);
              handleShow();
              // setTimeout(() => {
              // }, [3000]);
              // }

              // setLoader(false);
              // setTokenCl(true);
              // handleShow();
            }

            // console.log("ClaimDynamic reponse", res);
            setLoader(false);
          } catch (e) {
            setLoader(false);

            console.log(e, "error in claiming vested dynamic");
          }
        }
      }
      // }
    } catch (err) {
      setLoader(false);
      console.log(err, "claim");
    }
  };

  const setupTokens = () => {
    const addr = [
      {
        address: "0x22dA5C97F742eC3828a8183896dA56B44eFd0471",
        quantity: 50000000000000000000n,
        ids: [0, 1],
        nftQuantity: [1, 2],
        isKycRequired: true,
      },
      {
        address: "0x96d2cc9E3c1Ea954aa67Db266a0a79F06E007012",
        quantity: 150000000000000000000n,
        ids: [0, 1],
        nftQuantity: [1, 2],
        isKycRequired: false,
      },
      {
        address: "0x53780eDC8E2a5236aD7aeBA183C60672C602438A",
        quantity: 150000000000000000000n,
        ids: [0, 1],
        nftQuantity: [1, 2],
        isKycRequired: false,
      },
      {
        address: "0x2d2234950EA9809faDFc2E234435CD56c96b29aE",
        quantity: 150000000000000000000n,
        ids: [0, 1],
        nftQuantity: [1, 2],
        isKycRequired: true,
      },
    ];
    let leaves = addr.find((x) => x.address === account);
    if (leaves) {
      setAddObj(leaves);
    }
    let tota = 0;
    let filts = addr.filter((r) => {
      return r.address === account;
    });
    for (let i of filts) {
      tota = i.quantity;
    }
    setTotal(tota);
  };

  useEffect(() => {
    if (account) {
      setupTokens();
    }
  }, [account]);

  //   Handling pagination below
  const handlePageChange = (e) => {
    console.log("called");
    const selectedPage = e.selected;
    setPage(selectedPage);
  };
  const handlePageChange1 = (e) => {
    console.log("called");
    const selectedPage = e.selected;
    setPage1(selectedPage);
  };

  useEffect(() => {
    if (account) {
      getProfile();
      isDopClaimed();
    }
  }, [account, round]);

  useEffect(() => {
    if (account) {
      // dopTransaction();
    }
  }, [page, account]);
  useEffect(() => {
    if (account) {
      NftTransaction();
      NftTransactionDetails();
    }
  }, [page1, account]);

  const getProfile = async () => {
    let token = localStorage.getItem("dopToken");
    setLoader(true);
    var config = {
      method: "get",
      url: `${Api_Url_Leia_Claims}/users/profile`,

      headers: {
        Authorization: "Bearer " + token,
      },
      data: {},
    };
    await axios(config)
      .then(function (res) {
        const resss = res?.data.data;
        let date = new Date(resss?.rewardCreatedAt).toLocaleDateString();
        // setAirdropAmount(resss?.airdropAmount);
        setProfile(resss);
        if (resss?.kycRequired === false) {
          setIsKycVer("approved");
        } else {
          setIsKycVer(resss?.kycStatus);
        }
        setEarnOn(date);
      })
      .catch(function (err) {
        // if (err?.response?.data?.statusCode === 401) {
        //   refreshTokeen();
        // } else {
        //   setLoader(false);
        // }
      });
  };

  // const claimInsuranceTokens70Percent = async (index) => {
  //   if (!account) {
  //     toast.error("Connect Your Wallet");
  //     return;
  //   }

  //   try {
  //     setLoader(true);

  //     let indexes = selected.map((item) => item.index);
  //     let paramIndex = null;
  //     if (index && index !== null) {
  //       paramIndex = [index];
  //     } else if (index == null) {
  //       paramIndex = indexes;
  //     }

  //     let a = await claimInsurance(paramIndex, true);

  //     if (a) {
  //     }
  //     setLoader(false);
  //   } catch (error) {
  //     toast.error("Selected Claims Failed");

  //     setLoader(false);
  //   }
  // };

  // const claimInsuranceFunds = async (index) => {
  //   if (!account) {
  //     toast.error("Connect Your Wallet");
  //     return;
  //   }

  //   try {
  //     setLoader(true);
  //     let indexes = selected.map((item) => item.index);
  //     let paramIndex = null;
  //     if (index && index !== null) {
  //       paramIndex = [index];
  //     } else if (index == null) {
  //       paramIndex = indexes;
  //     }
  //     let a = await claimInsurance(paramIndex, false);

  //     if (a) {
  //     }
  //     setLoader(false);
  //   } catch (error) {
  //     toast.error("Selected Claims Failed");

  //     setLoader(false);
  //   }
  // };

  useEffect(() => {
    if (account && key == "insured") {
      // insuranceTransactions();
    }
  }, [account, key, pageInsurance]);
  const handlePageChangeInsurance = (e) => {
    const selectedPage = e.selected;

    setPageInsurance(selectedPage);
  };

  // const insuranceTransactions = async () => {
  //   // setLoader(true);
  //   var config = {
  //     method: "get",
  //     url: `${Api_Url_Leia_Claims}users/insured-user-transaction?privateSale=LEIA&limit=10&offset=${
  //       pageInsurance + 1
  //     }&walletAddress=${account}`,

  //     // headers: {
  //     //   Authorization: "Bearer " + token,
  //     // },
  //     data: {},
  //   };
  //   await axios(config)
  //     .then(function (res) {
  //       const resss = res?.data.data;
  //       setThirtyPercentInsuredTokens(resss?.thrirthPercentInsuredTokens);
  //       setTotalInsuredTokens(resss?.totalInsuredTokens);
  //       setInsuranceArray(resss?.userTransaction);
  //       setCountInsurance(resss.count);
  //       let arr = Array.from(Array(parseInt(resss.pages)).keys());
  //       setPageCountInsurance(resss.pages);
  //       setPagesInsurance(arr);
  //       setLoader(false);
  //     })
  //     .catch(function (err) {
  //       setLoader(false);
  //       // if (err?.response?.data?.statusCode === 401) {
  //       //   refreshTokeen();
  //       // } else {
  //       //   setLoader(false);
  //       // }
  //     });
  // };

  // const dopTransaction = async () => {
  //   let token = localStorage.getItem("dopToken");
  //   // setLoader(true);
  //   var config = {
  //     method: "get",
  //     url: `${Api_Url_Leia_Claims}transactions/dop-transactions?limit=${5}&offset=${
  //       page + 1
  //     }`,

  //     headers: {
  //       Authorization: "Bearer " + token,
  //     },
  //     data: {},
  //   };
  //   await axios(config)
  //     .then(function (res) {
  //       const resss = res?.data.data;
  //       setArry(resss?.transactions);
  //       console.log(resss, "doptrasection");
  //       setCount(resss.count);
  //       let arr = Array.from(Array(parseInt(resss.pages)).keys());
  //       setPageCount(resss.pages);
  //       setPages(arr);
  //       setLoader(false);
  //     })
  //     .catch(function (err) {
  //       setLoader(false);
  //       // if (err?.response?.data?.statusCode === 401) {
  //       //   refreshTokeen();
  //       // } else {
  //       //   setLoader(false);
  //       // }
  //     });
  // };

  const NftTransaction = async () => {
    let token = localStorage.getItem("dopToken");
    // setLoader(true);
    var config = {
      method: "get",
      url: `${Api_Url_Leia_Claims}/transactions/nft-transactions?limit=${5}&offset=${
        page1 + 1
      }`,

      headers: {
        Authorization: "Bearer " + token,
      },
      data: {},
    };
    await axios(config)
      .then(function (res) {
        const resss = res?.data.data;
        console.log(resss, "dopnfttrasection");
        setCount1(resss.count);
        setArry1(resss?.transactions);
        let arr = Array.from(Array(parseInt(resss.pages)).keys());
        setPageCount1(resss.pages);
        setPages1(arr);
        // setLoader(false);
      })
      .catch(function (err) {
        // setLoader(false);
        // if (err?.response?.data?.statusCode === 401) {
        //   refreshTokeen();
        // } else {
        //   setLoader(false);
        // }
      });
  };

  const NftTransactionDetails = async () => {
    let token = localStorage.getItem("dopToken");
    var config = {
      method: "get",
      url: `${Api_Url_Leia_Claims}/transactions/nft-transactions?limit=${500}&offset=${
        page1 + 1
      }`,

      headers: {
        Authorization: "Bearer " + token,
      },
      data: {},
    };
    await axios(config)
      .then(function (res) {
        const transactions = res?.data?.data?.transactions;
        let dum200 = [];
        let dum500 = [];
        let dum1000 = [];
        let dum5000 = [];
        let dum10000 = [];
        let dum30000 = [];
        let dum75000 = [];
        let nftLength = {
          l200: 0,
          l500: 0,
          l1000: 0,
          l5000: 0,
          l10000: 0,
          l30000: 0,
          l75000: 0,
        };
        if (transactions?.length > 0) {
          setIsNfts(true);
          for (let i of transactions) {
            if (i?.nftAmounts[0] !== "0") {
              nftLength.l200 = nftLength.l200 + parseInt(i?.nftAmounts[0]);
              dum200?.push(i);
            }
            if (i?.nftAmounts[1] !== "0") {
              nftLength.l500 = nftLength.l500 + parseInt(i?.nftAmounts[1]);
              dum500?.push(i);
            }
            if (i?.nftAmounts[2] !== "0") {
              nftLength.l1000 = nftLength.l1000 + parseInt(i?.nftAmounts[2]);
              dum1000?.push(i);
            }
            if (i?.nftAmounts[3] !== "0") {
              nftLength.l5000 = nftLength.l5000 + parseInt(i?.nftAmounts[3]);
              dum5000?.push(i);
            }
            if (i?.nftAmounts[4] !== "0") {
              nftLength.l10000 = nftLength.l10000 + parseInt(i?.nftAmounts[4]);
              dum10000?.push(i);
            }
            if (i?.nftAmounts[5] !== "0") {
              nftLength.l30000 = nftLength.l30000 + parseInt(i?.nftAmounts[5]);
              dum30000?.push(i);
            }
            if (i?.nftAmounts[6] !== "0") {
              nftLength.l75000 = nftLength.l75000 + parseInt(i?.nftAmounts[6]);
              dum75000?.push(i);
            }
          }
          setNft200(dum200);
          setNft500(dum500);
          setNft1000(dum1000);
          setNft5000(dum5000);
          setNft10000(dum10000);
          setNft30000(dum30000);
          setNft75000(dum75000);
          setNftLengths(nftLength);
        } else {
          setIsNfts(false);
        }
        let nftDops = {
          total200: 0,
          total500: 0,
          total1000: 0,
          total5000: 0,
          total10000: 0,
          total30000: 0,
          total75000: 0,
        };
        if (dum200?.length > 0) {
          let tota = 0;
          for (let i of dum200) {
            tota = tota + 200 / i?.price;
          }
          nftDops.total200 = tota;
        }
        if (dum500?.length > 0) {
          let tota = 0;
          for (let i of dum500) {
            tota = tota + 500 / i?.price;
          }
          nftDops.total500 = tota;
        }
        if (dum1000?.length > 0) {
          let tota = 0;
          for (let i of dum1000) {
            tota = tota + 1000 / i?.price;
          }
          nftDops.total1000 = tota;
        }
        if (dum5000?.length > 0) {
          let tota = 0;
          for (let i of dum5000) {
            tota = tota + 5000 / i?.price;
          }
          nftDops.total5000 = tota;
        }
        if (dum10000?.length > 0) {
          let tota = 0;
          for (let i of dum10000) {
            tota = tota + 10000 / i?.price;
          }
          nftDops.total10000 = tota;
        }
        if (dum30000?.length > 0) {
          let tota = 0;
          for (let i of dum30000) {
            tota = tota + 30000 / i?.price;
          }
          nftDops.total30000 = tota;
        }
        if (dum75000?.length > 0) {
          let tota = 0;
          for (let i of dum75000) {
            tota = tota + 75000 / i?.price;
          }
          nftDops.total75000 = tota;
        }
        setNftDops(nftDops);
        console.log(transactions, "transactions");
      })
      .catch(function (err) {
        // setLoader(false);
        // if (err?.response?.data?.statusCode === 401) {
        //   refreshTokeen();
        // } else {
        //   setLoader(false);
        // }
      });
  };

  const [show5, setShow5] = useState(false);

  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  return (
    <>
      <Navbar />

      {loader && <Loader />}
      <section className="claimallmain">
        <div className="custom-container">
          <div className="main-head">
            <Link to="/claimschedule" className="btn-back">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M7.38867 14.0645L4.88727 14.0645L1.49422 8.00318L3.99563 8.00318L7.38867 14.0645Z"
                  fill="#329879"
                />
                <path
                  d="M7.38867 1.92969L4.88727 1.92969L1.49422 7.99096L3.07072 9.03883L3.99563 7.99096L7.38867 1.92969Z"
                  fill="#329879"
                />
                <path
                  d="M13.1862 9.14258L6.24116 9.14258L7.51588 6.85687L14.4609 6.85687L13.1862 9.14258Z"
                  fill="#329879"
                />
              </svg>
              Back
            </Link>
            <h6>
              Claim #{round?.id} - {round?.id === 1 ? 5 : 8.64}%
            </h6>
          </div>
          <div className="claimallinner">
            <div className="claimallleft">
              <div className="claimallleftinner">
                <h6 className="claimalllefthead">Tokens</h6>
                <div
                  onClick={() => {
                    if (arry?.length > 0) {
                      handleShow2();
                    }
                  }}
                  className="claimtoken"
                >
                  <div className="claimtokenleft">
                    <div className="claimtokenimg">
                      <img
                        src="/assets/presaletokens/leia.png"
                        alt="claiminnerimg"
                        className="claiminnerimg"
                      />
                    </div>
                    <h6 className="tokenlefthead">LEIA Tokens</h6>
                    {arry?.length > 0 ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                          fill="#797979"
                        />
                      </svg>
                    ) : (
                      ""
                    )}
                  </div>
                  {profile?.dopPurchased ? (
                    <h6 className="claimtokenrighthead">
                      {(
                        profile?.dopPurchased - profile?.dopPurchasedByNft
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      LEIA
                    </h6>
                  ) : (
                    <h6 className="claimtokenrighthead">{0.0} LEIA</h6>
                  )}
                </div>
                {/* <div
                  onClick={() => {
                    if (airdropAmount > 0) {
                      handleShow3();
                    }
                  }}
                  className="claimtoken"
                >
                  <div className="claimtokenleft">
                    <div className="claimtokenimg">
                      <img
                        src="\assets\cardimages\rewards.svg"
                        alt="claiminnerimg"
                        className="claiminnerimg"
                      />
                    </div>
                    <h6 className="tokenlefthead">Testnet Rewards</h6>
                    {airdropAmount > 0 ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                          fill="#797979"
                        />
                      </svg>
                    ) : (
                      ""
                    )}
                  </div>
                  {profile?.airdropAmount ? (
                    <h6 className="claimtokenrighthead">
                      {profile?.airdropAmount?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      LEIA
                    </h6>
                  ) : (
                    <h6 className="claimtokenrighthead">{0.0} LEIA</h6>
                  )}
                </div> */}
              </div>
              {isNfts && round?.id === 1 ? (
                <div className="claimallleftinner">
                  <h6 className="claimalllefthead">NFTs</h6>
                  {/* <div className="claimtoken" onClick={handleShow4}>
                      <div className="claimtokenleft">
                        <div className="claimtokenimg">
                          <img
                            src="\assets\cardimages\nftone.svg"
                            alt="claiminnerimg"
                            className="claiminnerimg"
                          />
                        </div>
                        <h6 className="tokenlefthead">$200 LEIA NFT</h6>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                            fill="#797979"
                          />
                        </svg>
                      </div>
                      <h6 className="claimtokenrighthead">
                        50,000.00 DOP <br />
                        <span className="xspan">x2 NFTS</span>
                      </h6>
                    </div> */}
                  {nft200?.length > 0 && (
                    <div
                      className="claimtoken"
                      onClick={() => {
                        setDetailTransactios(nft200);
                        handleShow4();
                      }}
                    >
                      <div className="claimtokenleft">
                        <div className="claimtokenimg">
                          <img
                            src="\assets\cardimages\nftone.svg"
                            alt="claiminnerimg"
                            className="claiminnerimg"
                          />
                        </div>
                        <h6 className="tokenlefthead">$200 LEIA NFT</h6>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                            fill="#797979"
                          />
                        </svg>
                      </div>
                      <h6 className="claimtokenrighthead">
                        {nftDopss?.total200?.toFixed(4)} LEIA <br />
                        <span className="xspan">x{nftLengths?.l200} NFTS</span>
                      </h6>
                    </div>
                  )}
                  {nft500?.length > 0 && (
                    <div
                      className="claimtoken"
                      onClick={() => {
                        setDetailTransactios(nft500);
                        handleShow4();
                      }}
                    >
                      <div className="claimtokenleft">
                        <div className="claimtokenimg">
                          <img
                            src="\assets\cardimages\nfttwo.svg"
                            alt="claiminnerimg"
                            className="claiminnerimg"
                          />
                        </div>
                        <h6 className="tokenlefthead">$500 LEIA NFT</h6>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                            fill="#797979"
                          />
                        </svg>
                      </div>
                      <h6 className="claimtokenrighthead">
                        {nftDopss?.total500?.toFixed(4)} LEIA <br />
                        <span className="xspan">x{nftLengths?.l500} NFTS</span>
                      </h6>
                    </div>
                  )}
                  {nft1000?.length > 0 && (
                    <div
                      className="claimtoken"
                      onClick={() => {
                        setDetailTransactios(nft1000);
                        handleShow4();
                      }}
                    >
                      <div className="claimtokenleft">
                        <div className="claimtokenimg">
                          <img
                            src="\assets\cardimages\nftthree.svg"
                            alt="claiminnerimg"
                            className="claiminnerimg"
                          />
                        </div>
                        <h6 className="tokenlefthead">$1,000 LEIA NFT</h6>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                            fill="#797979"
                          />
                        </svg>
                      </div>
                      <h6 className="claimtokenrighthead">
                        {nftDopss?.total1000?.toFixed(4)} LEIA <br />
                        <span className="xspan">x{nftLengths?.l1000} NFTS</span>
                      </h6>
                    </div>
                  )}
                  {nft5000?.length > 0 && (
                    <div
                      className="claimtoken"
                      onClick={() => {
                        setDetailTransactios(nft5000);
                        handleShow4();
                      }}
                    >
                      <div className="claimtokenleft">
                        <div className="claimtokenimg">
                          <img
                            src="\assets\cardimages\nftfour.svg"
                            alt="claiminnerimg"
                            className="claiminnerimg"
                          />
                        </div>
                        <h6 className="tokenlefthead">$5,000 LEIA NFT</h6>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                            fill="#797979"
                          />
                        </svg>
                      </div>
                      <h6 className="claimtokenrighthead">
                        {nftDopss?.total5000?.toFixed(4)} LEIA <br />
                        <span className="xspan">x{nftLengths?.l5000} NFTS</span>
                      </h6>
                    </div>
                  )}
                  {nft10000?.length > 0 && (
                    <div
                      className="claimtoken"
                      onClick={() => {
                        setDetailTransactios(nft10000);
                        handleShow4();
                      }}
                    >
                      <div className="claimtokenleft">
                        <div className="claimtokenimg">
                          <img
                            src="\assets\cardimages\nftfive.svg"
                            alt="claiminnerimg"
                            className="claiminnerimg"
                          />
                        </div>
                        <h6 className="tokenlefthead">$10,000 LEIA NFT</h6>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                            fill="#797979"
                          />
                        </svg>
                      </div>
                      <h6 className="claimtokenrighthead">
                        {nftDopss?.total10000?.toFixed(4)} LEIA <br />
                        <span className="xspan">
                          x{nftLengths?.l10000} NFTS
                        </span>
                      </h6>
                    </div>
                  )}
                  {nft30000?.length > 0 && (
                    <div
                      className="claimtoken"
                      onClick={() => {
                        setDetailTransactios(nft30000);
                        handleShow4();
                      }}
                    >
                      <div className="claimtokenleft">
                        <div className="claimtokenimg">
                          <img
                            src="\assets\cardimages\nftsix.svg"
                            alt="claiminnerimg"
                            className="claiminnerimg"
                          />
                        </div>
                        <h6 className="tokenlefthead">$30,000 LEIA NFT</h6>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                            fill="#797979"
                          />
                        </svg>
                      </div>
                      <h6 className="claimtokenrighthead">
                        {nftDopss?.total30000?.toFixed(4)} LEIA <br />
                        <span className="xspan">
                          x{nftLengths?.l30000} NFTS
                        </span>
                      </h6>
                    </div>
                  )}
                  {nft75000?.length > 0 && (
                    <div
                      className="claimtoken"
                      onClick={() => {
                        setDetailTransactios(nft75000);
                        handleShow4();
                      }}
                    >
                      <div className="claimtokenleft">
                        <div className="claimtokenimg">
                          <img
                            src="\assets\cardimages\nftseven.svg"
                            alt="claiminnerimg"
                            className="claiminnerimg"
                          />
                        </div>
                        <h6 className="tokenlefthead">$75,000 LEIA NFT</h6>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                            fill="#797979"
                          />
                        </svg>
                      </div>
                      <h6 className="claimtokenrighthead">
                        {nftDopss?.total75000?.toFixed(4)} LEIA <br />
                        <span className="xspan">
                          x{nftLengths?.l75000} NFTS
                        </span>
                      </h6>
                    </div>
                  )}
                  <div className="checkboxmain">
                    <div class="content">
                      <label className="checkBox">
                        <input
                          type="checkbox"
                          id="ch"
                          checked={claimToken}
                          onChange={() => {
                            setClaimToken(!claimToken);
                            setClaimNFT(false);
                          }}
                        />
                        <div class="transition"></div>
                      </label>
                    </div>
                    <div className="checkboxtexts">
                      <h6 className="checkboxhead">
                        claim ONLY LEIA tokens{" "}
                        <span className="greenspan">Lower Gas Fee</span>
                      </h6>
                      <p className="checkboxpara">
                        CLAIM your LEIA tokens associated with NFTS
                      </p>
                    </div>
                  </div>
                  <div className="checkboxmain">
                    <div class="content">
                      <label className="checkBox">
                        <input
                          type="checkbox"
                          id="ch"
                          checked={claimNFT}
                          onChange={() => {
                            setClaimNFT(!claimNFT);
                            setClaimToken(false);
                          }}
                        />
                        <div class="transition"></div>
                      </label>
                    </div>
                    <div className="checkboxtexts">
                      <h6 className="checkboxhead">
                        mint nft & claim LEIA tokens{" "}
                        <span className="redspan"> Higher Gas Fee</span>
                      </h6>
                      <p className="checkboxpara">
                        mint NFT and also receive your LEIA tokens associated
                        with it
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="bottom-twice-elem">
              <div className="claimallright">
                <h6 className="claimallrighthead">Summary</h6>
                <div className="summarymain">
                  <p className="summarypara">LEIA Tokens</p>
                  {profile?.dopPurchased ? (
                    <h6 className="summaryhead">
                      {(profile?.dopPurchased).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      LEIA
                    </h6>
                  ) : (
                    <h6 className="summaryhead">{0.0} LEIA</h6>
                  )}
                </div>
                {/* <div className="summarymain">
                <p className="summarypara">Testnet Rewards</p>
                <h6 className="summaryhead">
                  {profile?.airdropAmount ? (
                    <h6 className="summaryhead">
                      {profile?.airdropAmount.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      LEIA
                    </h6>
                  ) : (
                    <h6 className="summaryhead">{0.0} LEIA</h6>
                  )}{" "}
                </h6>
              </div> */}
                {
                  claimNFT && ""
                  // <div class="summarymain">
                  //   <p class="summarypara">LEIA NFTs</p>
                  //   <h6 class="summaryhead">14 LEIA NFTs</h6>
                  // </div>
                }
                <div className="summarymain">
                  <p className="summarypara">
                    LEIA tokens associated with NFTs
                  </p>
                  <h6 className="summaryhead">
                    {profile?.dopPurchasedByNft ? (
                      <h6 className="summaryhead">
                        {profile?.dopPurchasedByNft.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        LEIA
                      </h6>
                    ) : (
                      <h6 className="summaryhead">{0.0} LEIA</h6>
                    )}{" "}
                  </h6>
                </div>
                <div className="summarymain">
                  <p className="summarypara">Claimable</p>
                  <h6 className="summaryhead">
                    {round?.id === 1 ? (
                      <h6 className="summaryhead">
                        {(
                          (profile?.dopPurchased + profile?.dopPurchasedByNft) *
                          0.05
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        LEIA
                      </h6>
                    ) : (
                      <h6 className="summaryhead">
                        {(
                          (profile?.dopPurchased + profile?.dopPurchasedByNft) *
                          0.0864
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        LEIA
                      </h6>
                    )}
                  </h6>
                </div>
                <div className="totalmain">
                  <p className="totalpara">TOTAL</p>
                  {/* {profile?.airdropAmount ? ( */}
                  <h6 className="totalhead">
                    {(
                      profile?.dopPurchased + profile?.dopPurchasedByNft
                    )?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    LEIA
                  </h6>
                  {/* ) : (
                  <h6 className="totalhead">0.00</h6>
                )} */}
                </div>
                <div className="claimable-thistime">
                  <div className="left-text">
                    <h6>CLAIMABLE THIS TIME</h6>
                    <p>({round?.id === 1 ? 5 : 8.64} % OF YOUR PURCASE)</p>
                  </div>
                  <div className="right-text">
                    <h5>
                      {(
                        (profile?.dopPurchased + profile?.dopPurchasedByNft) *
                        (round?.id === 1 ? 0.05 : 0.0864)
                      )?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                      LEIA
                    </h5>
                  </div>
                </div>
                <>
                  {claimNFT && (
                    <div class="checkboxmain">
                      <div class="content">
                        <label class="checkBox">
                          <input
                            onChange={() => {
                              setTerms(!terms);
                              setError("");
                            }}
                            checked={terms}
                            type="checkbox"
                            id="ch"
                          />
                          <div class="transition"></div>
                        </label>
                      </div>
                      <p class="maincheckboxpara">
                        By checking this box, I affirm and warrant that I am not
                        identified as a sanctioned individual (or any equivalent
                        designation) on any sanctions, blacklist, or analogous
                        register in any global jurisdiction.
                      </p>
                    </div>
                  )}
                </>
                <p
                  style={{
                    color: "red",
                    textAlign: "center",
                    marginBottom: 20,
                    marginTop: -10,
                    fontSize: 14,
                  }}
                >
                  {error}
                </p>

                {/* nftCl */}
                <>
                  {isKycVer === "approved" ? (
                    <>
                      {claimNFT ? (
                        <button
                          disabled={tokenCl || terms === false}
                          style={{
                            opacity: tokenCl || terms === false ? 0.5 : 1,
                          }}
                          onClick={() => ClaimYourDops()}
                          className={
                            "claimbtn " +
                            (tokenCl || terms === false ? "disable" : "")
                          }
                        >
                          {tokenCl ? (
                            "claimed"
                          ) : (
                            <>
                              {" "}
                              {round?.id === 1
                                ? "claim 5%"
                                : "claim 8.64%"}{" "}
                            </>
                          )}
                        </button>
                      ) : (
                        <>
                          <button
                            disabled={tokenCl}
                            style={{ opacity: tokenCl ? 0.5 : 1 }}
                            onClick={() => ClaimYourDops()}
                            className={"claimbtn " + (tokenCl ? "disable" : "")}
                          >
                            {tokenCl ? (
                              "claimed"
                            ) : (
                              <>
                                {" "}
                                {round?.id === 1
                                  ? "claim 5%"
                                  : "claim 8.64%"}{" "}
                              </>
                            )}
                          </button>
                        </>
                      )}
                    </>
                  ) : (
                    // <>
                    //   <button
                    //     onClick={() => setAllowKyc(true)}
                    //     className={"claimbtn "}
                    //   >
                    //     Start Kyc
                    //   </button>
                    // </>
                    <>
                      {profile && (
                        <a
                          href="https://in.sumsub.com/websdk/p/uni_hl492MgNi0cEX3vM"
                          className={"claimbtn "}
                        >
                          Start Kyc
                        </a>
                      )}
                    </>
                  )}
                </>
              </div>
              {claimToken === true && isNfts && (
                <div className="important-div">
                  <h6>Important Notice</h6>
                  <p>
                    Please be aware that once you finalize this claim process,
                    you will no longer have the opportunity to mint your NFTs at
                    a later time.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* <Modal
        className="connectwallet-modal buy-modal"
        show={allowKyc}
        onHide={() => setAllowKyc(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Kyc Unavailable</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="buy-modal">
            <img src="\assets\error.svg" alt="img" className="img-fluid" />
            <p>Kyc is not available right now.</p>
            <button className="btn-common" onClick={() => setAllowKyc(false)}>
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal> */}

      <Modal
        className="connectwallet-modal buy-modal asfasdf"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Claimed Successfully</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="buy-modal">
            {/* <img src="\assets\check.svg" alt="img" className="img-fluid" /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="68"
              height="67"
              viewBox="0 0 68 67"
              fill="none"
            >
              <path
                d="M67.083 26.9845L67.083 0.416992L25.6296 40.7939L0.916992 23.7697L0.916994 48.3998L25.6296 66.583L67.083 26.9845Z"
                fill="#02171D"
              />
            </svg>
            <p>Your claim has been processed successfully.</p>
            <button className="btn-common" onClick={handleClose}>
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="connectwallet-modal buy-modal"
        show={onlyTok === true && isNfts === true}
        onHide={() => setOnlyTok(null)}
        // onHide={() => {
        //   setOnlyTok(null);
        //   ClaimYourDops();
        // }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="buy-modal">
            <img src="\assets\error.svg" alt="img" className="img-fluid" />
            <p>
              You will not be able to claim nfts if you have nfts and you don't
              want to claim with tokens
            </p>
            <button
              className="btn-common"
              onClick={() => {
                setOnlyTok(null);
                ClaimYourDops();
              }}
            >
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="connectwallet-modal buy-modal"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="buy-modal">
            <img src="\assets\error.svg" alt="img" className="img-fluid" />
            <p>
              {total > 0
                ? "Oops, something went wrong."
                : `This wallet is not eligible for any LEIA token claims.`}
            </p>
            <button className="btn-common" onClick={handleClose1}>
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="connectwallet-modal buy-modal tablemodal"
        show={show2}
        onHide={handleClose2}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Token Purchase Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PurchaseModal
            pages={pages}
            pageCount={pageCount}
            page={page}
            handlePageChange={handlePageChange}
            arryy={arry}
            count={count}
          />
        </Modal.Body>
      </Modal>

      {/* <Modal
        className="connectwallet-modal buy-modal tablemodal"
        show={show3}
        onHide={handleClose3}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Testnet Rewards Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="mainrewardsdiv">
            <div class="rewardmain">
              <p class="rewardinnerpara">earned on</p>
              <p class="rewardinnerpara">LEIA amount earned</p>
            </div>
            <div class="rewardmain">
              <p class="rewardinnerpara">{earnOn}</p>
              <p class="rewardinnerpara">{airdropAmount?.toFixed(2)} LEIA</p>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

      <Modal
        className="connectwallet-modal buy-modal tablemodal"
        show={show4}
        onHide={handleClose4}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>LEIA NFTs Purchase Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PurchaseNftModal
            pages={pages1}
            pageCount={pageCount1}
            page={page1}
            handlePageChange={handlePageChange1}
            arryy={detailTransactions}
            count={count1}
          />
        </Modal.Body>
      </Modal>

      <Modal
        className="connectwallet-modal buy-modal tablemodal newmodal"
        show={show5}
        onHide={handleClose5}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Insurance Claim</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="insurancetop">
            <p className="insurancetophead">Insurance Claim</p>
            <p className="insuranceamount">70 USDT</p>
          </div>
          <div className="insurancedetail">
            <div className="innerdetail">
              <p className="detailpara">Purchased on</p>
              <h6 className="detailhead">02:46 25/04/24</h6>
            </div>
            <div className="innerdetail">
              <p className="detailpara">Purchased Price</p>
              <h6 className="detailhead">$0.02/LEIA</h6>
            </div>
            <div className="innerdetail">
              <p className="detailpara">Amount Spent</p>
              <h6 className="detailhead">2.36 ETH</h6>
            </div>
            <div className="innerdetail">
              <p className="detailpara">LEIA Purchased</p>
              <h6 className="detailhead">2,454,667 LEIA</h6>
            </div>
            <div className="innerdetail">
              <p className="detailpara">Type</p>
              <h6 className="detailhead">Token</h6>
            </div>
            <div className="innerdetail">
              <p className="detailpara">30% Tokens</p>
              <h6 className="detailhead">4,667 LEIA</h6>
            </div>
            <div className="innerdetail">
              <p className="detailpara">Remaining Insurance Claim</p>
              <h6 className="detailhead">70 USDT</h6>
            </div>
          </div>
          <div className="mainbtns">
            <button className="whitebtn">Close</button>
            <button className="greybtn">claim insurance</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Claim;
