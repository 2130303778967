import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";
import CuurentRound from "../../hooks/DataFetchers/round.js";
import Timer from "../../hooks/DataFetchers/time.js";
import CuurentRoundl from "../../hooks/DataFetchers/roundl.js";
import Timerl from "../../hooks/DataFetchers/timel.js";
import axios from "axios";
import RoundCardsss from "./RoundCardsss.js";
import Environment from "../../utils/Environment.js";

const PresalecardsUpcoming = ({ role, setMainLoader1 }) => {
    var currencyFormatter = require("currency-formatter");
    const { t } = useTranslation();
    const [Allprojectssss, setAllprojectssss] = useState([])

    const getAllProjectsData = async () => {
        setAllprojectssss([])
        setMainLoader1(true)
        if (role === "upcoming") {
            var config = {
                method: "get",
                url: `${Environment?.Api_Url}/projects/all?limit=1000&status=not started&offset=1`,
                headers: {
                    // authorization: `Bearer ${tok}`
                },
            };
        }
        axios(config)
            .then(function (response) {
                if (response) {
                    // console.log("valueheresss", response)
                    // console.log("asddewdfewfwefwef", response)
                    setAllprojectssss(response?.data?.data?.projects)
                    setMainLoader1(false)
                }
            })
            .catch(function (error) {
                setMainLoader1(false)
                console.error("Error fetching data:", error);
            });
    };
    useEffect(() => {
        if (role) {
            getAllProjectsData();
        }
    }, [role])

    // console.log("hitttt")

    return (
        <>
            <section className="process-projects presale-cards">
                <div className="custom-container padd-right-sm">
                    <div className={Allprojectssss?.length <= 0 ? "" : "bottom-cards"}>
                        {Allprojectssss?.map((round, index) => {
                            // Replace the base URL in the project image
                            const updatedImgUrl = round?.projectImg?.replace(
                                "https://media-gems.gems.vip",
                                "https://dh3bzuu7a45gi.cloudfront.net"
                            );

                            return (
                                <div className="single-card">
                                    <Link to={`/Gems/${round?.projectName}`}>
                                        <div className="main-token-img">
                                            <img width={70} height={70} src={updatedImgUrl} alt="img" className="img-fluid" />
                                        </div>
                                        <div className="token-info">
                                            <h6>{round?.projectName} {round?.isPremiumProject ? <span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                                <path d="M7.45048 0.257202L8.48835 3.53194L12.1008 3.45449L12.7619 5.38046L9.79188 7.32689L10.9857 10.5534L9.2544 11.7429L6.38016 9.67079L3.50592 11.7429L1.77458 10.5534L2.96845 7.32689L0 5.379L0.661029 3.45303L4.27352 3.53048L5.3114 0.257202H7.45048Z" fill="#30F1B6" />
                                            </svg> Verified Gems</span> : ""}</h6>
                                            <p className="sub-title">{round?.tokenSymbol}</p>
                                            {round?.projectSummary &&
                                                (
                                                    <p className="para">{round?.projectSummary}</p>
                                                )
                                            }
                                        </div>
                                        <div className="tokenprice-info">
                                            <div className="text">
                                                <p>{t('Process-t4')}</p>
                                                <h6>{round?.presaleRounds[0]?.roundNumber ? round?.presaleRounds[0]?.roundNumber : "--"}</h6>
                                            </div>
                                            <div className="text">
                                                <p>{t('Process-t3')}</p>
                                                <h6>{round?.presaleRounds[0]?.roundPrice ? `$${round?.presaleRounds[0]?.roundPrice}` : "--"}</h6>
                                            </div>
                                            <div className="text">
                                                <p>{t('Totalrasied')}</p>
                                                <h6>{round?.totalAmountRaised[0]?.totalAmountRaised ? currencyFormatter.format(round?.totalAmountRaised[0]?.totalAmountRaised, { code: "USD" }).split(".")[0].slice(1, 100) : "--"}</h6>
                                            </div>
                                        </div>
                                    </Link>
                                    {/* <div className="round-info">
                  <h6>Round Ending In</h6>
                  <h6 className="ended">Finished</h6>
                </div> */}
                                    <RoundCardsss key={index} round={round} />
                                </div>
                            );
                        })}


                        {
                            Allprojectssss?.length <= 0 && <>
                                <div className="whencardsnotshowing">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="37" viewBox="0 0 38 37" fill="none">
                                        <path d="M0.5 36.5L0.500002 20.8333L4.83334 20.8333L4.83334 32.75L0.5 36.5Z" fill="#77868B" />
                                        <path d="M0.5 16.166L0.500002 0.49935L4.83334 0.49935L4.83334 12.416L0.5 16.166Z" fill="#77868B" />
                                        <rect x="2.5" y="20.834" width="14.5" height="4.33333" fill="#77868B" />
                                        <rect x="2.5" y="0.5" width="14.5" height="4.33333" fill="#77868B" />
                                        <path d="M21 36.5L21 20.8333L25.3333 20.8333L25.3333 32.75L21 36.5Z" fill="#77868B" />
                                        <path d="M21 16.166L21 0.49935L25.3333 0.49935L25.3333 12.416L21 16.166Z" fill="#77868B" />
                                        <rect x="23" y="20.834" width="14.5" height="4.33333" fill="#77868B" />
                                        <rect x="33" y="32.166" width="4.5" height="4.33333" fill="#77868B" />
                                        <rect x="33" y="11.666" width="4.5" height="4.33333" fill="#77868B" />
                                        <rect x="12.5" y="32.166" width="4.5" height="4.33333" fill="#77868B" />
                                        <rect x="12.5" y="11.666" width="4.5" height="4.33333" fill="#77868B" />
                                        <rect x="23" y="0.5" width="14.5" height="4.33333" fill="#77868B" />
                                    </svg>
                                    <p>GOOD THINGS ARE ON THEIR WAY</p>
                                    <a href="https://creators.gems.vip/signup" className="btn-green" target="_blank" style={{ maxWidth: "150px", margin: "0 auto" }}>Create a Project</a>
                                </div>
                            </>
                        }

                    </div>
                </div>
            </section>
        </>
    );
};

export default PresalecardsUpcoming;
