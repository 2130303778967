import React, { useEffect, useState } from "react";
import "./gemspurchases.scss";
import { Link } from "react-router-dom";
import Navbar from "../landing/header/Navbar";
import { useParams } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import Loader from "../../hooks/loader";
import moment from "moment";
import "./gemspurchases.scss";
import { Api_Url } from "../../utils/ApiUrl";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import useWeb3 from "../../hooks/useWeb3";
import GetTokenBalance from "../../hooks/DataFetchers/getTokenBalance";
import AllowanceTokens from "../../hooks/DataFetchers/allowanceToken";
import ApproveTokens from "../../hooks/approveTokens";
import PurchaseInsurance from "../../hooks/purchaseInsurance";
// import PsMarketCap from "../../hooks/chinaFunction/psMarketCap";

const AllPurchasesNodeMiner = () => {
  const { id } = useParams();
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const { GetTokenBal } = GetTokenBalance();
  const { allowanceTokens } = AllowanceTokens();
  const { approveTokens } = ApproveTokens();
  const { purchaseInsurance } = PurchaseInsurance();

  // const account = "0x5cb3772c036f0874dc57aa2689dcebb0a58a3c25";

  // const { psMarketCap } = PsMarketCap()
  // const [claimCheckStatus, setClaimCheckStatus] = useState(false);
  // console.log("value of status", claimCheckStatus)

  const [mainLoader1, setMainLoader1] = useState(false);
  // const [claimedvalue, setClaimedvalue] = useState();
  const [projectdetail, setProjectDetail] = useState(null);
  const [insuranceData, setInsuranceData] = useState(null);
  const [price, setPrice] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [allowance, setAllowance] = useState(0);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [contractaddress, setContractAddress] = useState(null);
  const [finalizaesale, setFinalizaeSale] = useState([]);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [country, setCountry] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2insurance1, setShow2Insurance1] = useState(false);
  const handleShow2Insurance1 = () => {
    setShow2Insurance1(true);
  };
  const handleClose2Insurance1 = () => {
    setShow2Insurance1(false);
    setIsChecked1(false);
    setIsChecked2(false);
  };

  const handleCheckboxChange1 = () => {
    setIsChecked1(!isChecked1);
  };

  const handleCheckboxChange2 = () => {
    setIsChecked2(!isChecked2);
  };
  // console.log("address here is", contractaddress)
  const getPrice = async (token) => {
    axios
      .get(
        `https://min-api.cryptocompare.com/data/price?fsym=${token}&tsyms=USD`
      )
      .then((res) => {
        setPrice(res?.data?.USD);
        // setEthUsd(res.data);
      });
  };

  const getAllProjectsData = async (page = 1) => {
    setMainLoader1(true);
    const offset = page;
    var config = {
      method: "get",
      url: `${Api_Url}/nodeminer/purchases/specific?by=${account.toLowerCase()}&limit=10&offset=${offset}&privateSale=${id}`,
      headers: {
        // authorization: `Bearer ${tok}`
      },
    };

    axios(config)
      .then(function (response) {
        const data = response.data;
        setProjectDetail(response?.data?.data || []);
        setTotalPages(data?.data?.pages || 0);
        setTotalCount(data?.data?.count || 0);
        setContractAddress(data?.data?.projectInfo[0]?.privateSaleContract);
        setMainLoader1(false);
        // setProjectDetail(response?.data?.data?.projects[0])
        // setProjectDetail(response?.data?.data)

        // console.log("+++++++++++++++++++", response?.data?.data?.buyerAllTransationsOfSpecificProject)
      })
      .catch(function (error) {
        setMainLoader1(false);
        console.error("Error fetching data:", error);
      });
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const checkFinalizedSale = async () => {
    setMainLoader1(true);
    var data = JSON.stringify({
      query: `query MyQuery {
                saleFinalizeds(where: {contract: "${contractaddress}"}) {
                contract
                transactionHash
                id
                blockTimestamp
                blockNumber
            }
            }`,
      variables: {},
    });
    var config = {
      method: "post",
      url: "https://api.studio.thegraph.com/query/63987/referal-sale-base-v2/version/latest",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        setFinalizaeSale(response?.data?.data?.saleFinalizeds);
        setMainLoader1(false);

        // console.log("responce here is",
        // )
      })
      .catch(function (error) {
        setMainLoader1(false);
        console.log(error, "error");
      });
  };

  useEffect(() => {
    get_ApiKeys();
  }, []);

  useEffect(() => {
    if (id && account) {
      getAllProjectsData(currentPage);
    }
    // else if(!account){
    //     // console.log("outhere")
    //     window.location.assign("/");
    //     // fetchProjects(currentPage);
    // }
  }, [id, account, currentPage]);

  const get_ApiKeys = async () => {
    // let ipp=localStorage.getItem('ip')
    try {
      const response = await axios({
        method: "get",
        url: "https://ip.nf/me.json",
      });
      //  console.log(ipp,'response', response?.data.ip.ip);
      // if (!ipp||ipp!=response?.data.ip.ip){
      // localStorage.setItem('ip', response?.data.ip.ip)
      get_ApiKeys1(response?.data.ip.ip);
      // }
    } catch (error) {
      // setShowService(false)
      console.log(error, "error");
      // localStorage.setItem('ip',response?.data.ip)
      // localStorage.setItem('country',response?.data.country)
      // throw error;
    }
  };

  const get_ApiKeys1 = async (e) => {
    try {
      const response = await axios({
        method: "get",
        url: `https://api.iplocation.net/?ip=${e}`,
      });
      //  console.log(response?.data.country_name,'sdsdsdsds',);
      // localStorage.setItem('ip', response?.data.ip.ip)
      setCountry(response?.data.country_name?.toLowerCase());
      // setCountryyName(response?.data.ip.country)
      if (
        response?.data.country_name?.toLowerCase()?.includes("southsudan") ||
        response?.data.country_name?.toLowerCase()?.includes("south sudan") ||
        response?.data.country_name?.toLowerCase()?.includes("afghanistan") ||
        response?.data.country_name?.toLowerCase()?.includes("yemen") ||
        response?.data.country_name?.toLowerCase()?.includes("myanmar") ||
        response?.data.country_name?.toLowerCase()?.includes("unitedstates") ||
        response?.data.country_name?.toLowerCase()?.includes("united states") ||
        response?.data.country_name?.toLowerCase()?.includes("iran") ||
        response?.data.country_name?.toLowerCase()?.includes("north korea") ||
        response?.data.country_name?.toLowerCase()?.includes("syria") ||
        response?.data.country_name?.toLowerCase()?.includes("lebanon") ||
        response?.data.country_name?.toLowerCase()?.includes("northkorea") ||
        response?.data.country_name?.toLowerCase()?.includes("america") ||
        response?.data.country_name?.toLowerCase()?.includes("usa")
      ) {
        // setShowService(true)
      }
      //  }
      else {
        // setShowService(false);
      }
    } catch (error) {
      // setShowService(false)
      setCountry("india");

      console.log(error, "error");
      // localStorage.setItem('ip',response?.data.ip)
      // localStorage.setItem('country',response?.data.country)
      // throw error;
    }
  };

  async function getEthBalance(address) {
    try {
      const balance = await web3.eth.getBalance(address);
      setTokenBalance(balance);

      console.log(`ETH Balance: ${web3.utils.fromWei(balance, "ether")} ETH`);
    } catch (error) {
      console.error("Error fetching balance:", error);
    }
  }

  const getTokenBalance = async (token) => {
    const bal = await GetTokenBal(account, token);
    setTokenBalance(bal);
    console.log("balance: ", bal);
  };

  const getAllowance = async (token) => {
    const all = await allowanceTokens(account, token);
    if (all !== null || all !== undefined) {
      setAllowance(all);
    }
  };

  const GetInsuranceCall = async () => {
    if (account) {
      if (tokenBalance > insuranceData?.amountInvested * 0.052) {
        setMainLoader1(true);
        let config = {
          method: "post",
          url: `${Api_Url}/nodeminer/miners/purchase-insurance`,
          data: {
            walletAddress: account?.toLocaleLowerCase(),
            trxHash: insuranceData?.transactionHash,
            country: country,
            tokenSelected: insuranceData?.tokenSelected,
          },
        };
        console.log("insurance func api config");
        let postApiRes = await axios(config)
          .then(function (response) {
            return response?.data?.data;
          })
          .catch(function (error) {
            setMainLoader1(false);
            console.error("API Error:", error);
            toast.error(error?.response?.data?.message || "API request failed");
            return null;
          });
        console.log("api response: ", postApiRes);

        if (postApiRes) {
          if (insuranceData?.tokenSelected == "ETH") {
            try {
              setMainLoader1(true);
              const gas = await purchaseInsurance(
                postApiRes,
                account,
                insuranceData?.amountInvested
              );
              console.log("gas: ", gas);

              if (gas) {
                purchaseAPI(
                  insuranceData?.transactionHash,
                  insuranceData?.tokenSelected
                );
                handleClose();
                handleShow1();
                setMainLoader1(false);
                getAllProjectsData();

                // setStakingGasFees(gas);
                // handleShow();
              }
            } catch (e) {
              setMainLoader1(false);
              console.error(e);
              toast.error(e?.message);
            }
          } else {
            if (allowance >= insuranceData?.amountInvested * 0.052) {
              try {
                setMainLoader1(true);
                const gas = await purchaseInsurance(postApiRes, account, 0);
                console.log("gas: ", gas);

                if (gas) {
                  purchaseAPI(
                    insuranceData?.transactionHash,
                    insuranceData?.tokenSelected
                  );
                  handleClose();
                  handleShow1();
                  setMainLoader1(false);
                  getAllProjectsData();

                  // setStakingGasFees(gas);
                  // handleShow();
                }
              } catch (e) {
                setMainLoader1(false);
                console.error(e);
                toast.error(e?.message);
              }
            } else {
              try {
                setMainLoader1(true);
                let approve;
                try {
                  approve = await approveTokens(
                    account,
                    insuranceData?.tokenSelected
                  );
                } catch (e) {
                  setMainLoader1(false);
                  console.error(e);
                  toast.error(e?.message);
                }
                if (approve) {
                  getAllowance(insuranceData?.tokenSelected);
                  getTokenBalance(insuranceData?.tokenSelected);
                  if (tokenBalance > insuranceData?.amountInvested * 0.052) {
                    try {
                      const gas = await purchaseInsurance(
                        postApiRes,
                        account,
                        0
                      );
                      console.log("gas: ", gas);
                      if (gas) {
                        purchaseAPI(
                          insuranceData?.transactionHash,
                          insuranceData?.tokenSelected
                        );
                        handleClose();
                        handleShow1();
                        setMainLoader1(false);
                        getAllProjectsData();

                        // setStakingGasFees(gas);
                        // handleShow();
                      }
                    } catch (e) {
                      setMainLoader1(false);
                      console.error(e);
                      toast.error(e?.message);
                    }
                  } else {
                    // handleShow2();
                    setMainLoader1(false);

                    toast.error("Not Enough Balance");
                  }
                }
              } catch (e) {
                setMainLoader1(false);
                console.error(e);
                toast.error(e?.message);
              }
            }
          }
        }
      } else {
        toast.error("Not Enough Balance!");
      }
    } else {
      // setWalletError("Connect Wallet!");
      handleClose();
    }
  };

  const purchaseAPI = async (txHash, token) => {
    let tok = localStorage.getItem("accessToken");
    var config = {
      method: "patch",
      // url: `${Api_Url}/users/update-leader-plan`,
      url: `${Api_Url}/nodeminer/miners/purchase-insurance`,
      data: {
        trxHash: txHash,
        tokenSelected: token,
        walletAddress: account?.toLowerCase(),
      },
      headers: {
        authorization: `Bearer ` + tok,
      },
    };
    await axios(config)
      .then(function (response) {})
      .catch(function (error) {
        console.error(error?.message);
      });
  };

  // Format the transaction time
  const formatTransactionTime = (time) => {
    const date = new Date(time);

    // Format for the time: HH:mm dd/MM/yy
    return date.toLocaleString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };

  // useEffect(() => {
  //     if (contractaddress) {
  //         console.log("in here")
  //         firstTimeData();
  //     }
  // }, [contractaddress]);

  // const firstTimeData = async () => {
  //     try {
  //         let a = await psMarketCap(contractaddress)
  //         setClaimedvalue(a)
  //         console.log("claimed value", a)
  //         // setClaimCheckStatus(a?.claimsEnabled);
  //     } catch (err) {
  //         console.log(err.message);
  //     }
  // };

  // console.log("finalize sale is ", finalizaesale?.length > 0 ? "available" : finalizaesale?.length <= 0 ? "not available" : "")
  return (
    <>
      {mainLoader1 && (
        <Loader text={"Please confirm the transaction from your wallet."} />
      )}
      <Navbar />
      <section className="all-purchases">
        <div className="custom-container">
          <div className="main-head">
            <Link to="/mypurchases" className="btn-back">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M7.38867 14.0645L4.88727 14.0645L1.49422 8.00318L3.99563 8.00318L7.38867 14.0645Z"
                  fill="#329879"
                />
                <path
                  d="M7.38867 1.92969L4.88727 1.92969L1.49422 7.99096L3.07072 9.03883L3.99563 7.99096L7.38867 1.92969Z"
                  fill="#329879"
                />
                <path
                  d="M13.1862 9.14258L6.24116 9.14258L7.51588 6.85687L14.4609 6.85687L13.1862 9.14258Z"
                  fill="#329879"
                />
              </svg>
              Back
            </Link>
          </div>
          <div className="main-heading">
            <p>MY PURCHASES</p>
            <div className="twice-elem">
              {projectdetail?.projectInfo[0]?.projectImage ? (
                <img
                  width={24}
                  src={projectdetail?.projectInfo[0]?.projectImage}
                  // src={`/assets/presaletokens/${project?.privateSale?.toLowerCase()}.png`}
                  alt={`${
                    projectdetail?.projectInfo[0]?.projectName ||
                    projectdetail?.projectInfo[0]?.privateSale
                  } Logo`}
                  className="img-fluid"
                />
              ) : (
                <img
                  // src={project.projectImage}
                  src={`/assets/presaletokens/${projectdetail?.projectInfo[0]?.projectName?.toLowerCase()}.svg`}
                  alt={`${
                    projectdetail?.projectInfo[0]?.projectName ||
                    projectdetail?.projectInfo[0]?.privateSale
                  } Logo`}
                  className="img-fluid"
                />
              )}
              {/* <img
                width={24}
                src={projectdetail?.projectInfo[0]?.projectImage}
                alt="img"
                className="img-fluid"
              /> */}
              <h5>{projectdetail?.projectInfo[0]?.projectName}</h5>
            </div>
          </div>
          <div className="claim-box">
            <div className="left-side">
              <div className="inside-left">
                <svg
                  className="hide-in-light-theme"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M13.1293 18.9613C10.2194 20.6414 6.49849 19.6444 4.81844 16.7344C3.13839 13.8245 4.1354 10.1036 7.04534 8.42354C9.95527 6.74349 13.6762 7.74051 15.3562 10.6504C17.0363 13.5604 16.0393 17.2813 13.1293 18.9613Z"
                    stroke="#D1DADD"
                    stroke-width="1.62529"
                  />
                  <path
                    d="M10.3085 5.35899C12.9006 3.22095 16.7351 3.58905 18.8732 6.18117C21.0112 8.77328 20.6431 12.6078 18.051 14.7459"
                    stroke="#D1DADD"
                    stroke-width="1.62529"
                  />
                  <rect
                    x="10.0502"
                    y="11.0635"
                    width="3.816"
                    height="3.816"
                    transform="rotate(45 10.0502 11.0635)"
                    fill="#D1DADD"
                  />
                </svg>
                <svg
                  className="d-none show-in-light-theme"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M13.1295 18.9613C10.2195 20.6414 6.49861 19.6444 4.81856 16.7344C3.13851 13.8245 4.13553 10.1036 7.04546 8.42354C9.95539 6.74349 13.6763 7.74051 15.3564 10.6504C17.0364 13.5604 16.0394 17.2813 13.1295 18.9613Z"
                    stroke="#02171D"
                    stroke-width="1.62529"
                  />
                  <path
                    d="M10.3086 5.35899C12.9007 3.22095 16.7353 3.58905 18.8733 6.18117C21.0113 8.77328 20.6432 12.6078 18.0511 14.7459"
                    stroke="#02171D"
                    stroke-width="1.62529"
                  />
                  <rect
                    x="10.0503"
                    y="11.0635"
                    width="3.816"
                    height="3.816"
                    transform="rotate(45 10.0503 11.0635)"
                    fill="#02171D"
                  />
                </svg>
                <div className="right-text">
                  <p>{projectdetail?.projectInfo[0]?.projectName} Purchased</p>
                  <h5>
                    {projectdetail?.projectInfo[0]?.totalNftSum
                      ? projectdetail?.projectInfo[0]?.totalNftSum
                      : "0"}{" "}
                    {projectdetail?.projectInfo[0]?.privateSale}{" "}
                  </h5>
                  {/* <span>+0.8%</span> */}
                </div>
              </div>
              {projectdetail?.projectInfo[0]?.projectName == "MINER" ? (
                <div className="bottom-inline">
                  <p>
                    <span></span>{" "}
                    {projectdetail?.projectInfo[0]?.summedArray[0]} MINI
                  </p>
                  <p>
                    <span></span>{" "}
                    {projectdetail?.projectInfo[0]?.summedArray[1]} STANDARD
                  </p>
                  <p>
                    <span></span>{" "}
                    {projectdetail?.projectInfo[0]?.summedArray[2]} MEGA
                  </p>
                </div>
              ) : null}
            </div>
          </div>
          <div className="custom-table">
            <div class="table-responsive">
              <table class="table m-0">
                <thead>
                  <tr>
                    <th>Purchased on</th>
                    <th>HASH</th>

                    <th>PURCHASE PRICE</th>
                    <th>Amount Spent</th>

                    <th>
                      {projectdetail?.projectInfo[0]?.projectName} PURCHASED
                    </th>
                    {projectdetail?.projectInfo[0]?.projectName == "MINER" ? (
                      <>
                        <th>Gems Protect</th>
                        <th>Action</th>
                      </>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {projectdetail?.transaction?.map((project, index) => (
                    <tr key={index}>
                      <td>
                        {moment(project?.transactionTime)?.format(
                          "DD/MM/YYYY, hh:mm"
                        )}
                      </td>
                      <td>
                        {project?.transactionHash?.slice(0, 6)}...
                        {project?.transactionHash?.slice(
                          project?.transactionHash?.length - 3,
                          project?.transactionHash?.length
                        )}
                        <a
                          href={
                            "https://etherscan.io/tx/" +
                            project?.transactionHash
                          }
                          target="_blank"
                        >
                          <img
                            src="\assets\link-icon.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </a>
                      </td>
                      <td>
                        {projectdetail?.projectInfo[0]?.projectName ==
                        "MINER" ? (
                          <>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              $
                              {parseFloat(
                                (parseFloat(project?.nftAmounts[0]) > 0
                                  ? parseFloat(project?.minerPrices[0])
                                  : 0) +
                                  (parseFloat(project?.nftAmounts[1]) > 0
                                    ? parseFloat(project?.minerPrices[1])
                                    : 0) +
                                  (parseFloat(project?.nftAmounts[2]) > 0
                                    ? parseFloat(project?.minerPrices[2])
                                    : 0)
                              )?.toFixed(2)}
                              <div class="dropdown dropdownnodesminers">
                                <button
                                  class="dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="19"
                                    viewBox="0 0 18 19"
                                    fill="none"
                                  >
                                    <path
                                      d="M9 17.249C13.1421 17.249 16.5 13.8912 16.5 9.74902C16.5 5.60689 13.1421 2.24902 9 2.24902C4.85786 2.24902 1.5 5.60689 1.5 9.74902C1.5 13.8912 4.85786 17.249 9 17.249Z"
                                      stroke="#77868B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M9 12.749V9.74902"
                                      stroke="#77868B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M9 6.74902H9.00833"
                                      stroke="#77868B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </button>
                                <ul
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  {project?.nftAmounts[0] != 0 ? (
                                    <div className="twice">
                                      <p>Mini</p>
                                      <h6>
                                        $
                                        {parseFloat(
                                          project?.minerPrices[0]
                                        )?.toFixed(2)}
                                      </h6>
                                    </div>
                                  ) : null}
                                  {project?.nftAmounts[1] != 0 ? (
                                    <div className="twice">
                                      <p>Standard</p>
                                      <h6>
                                        $
                                        {parseFloat(
                                          project?.minerPrices[1]
                                        )?.toFixed(2)}
                                      </h6>
                                    </div>
                                  ) : null}
                                  {project?.nftAmounts[2] != 0 ? (
                                    <div className="twice">
                                      <p>Mega</p>
                                      <h6>
                                        $
                                        {parseFloat(
                                          project?.minerPrices[2]
                                        )?.toFixed(2)}
                                      </h6>
                                    </div>
                                  ) : null}
                                </ul>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>$ 99.99</>
                        )}
                      </td>
                      {project?.tokenSelected === "BTC" ||
                      project?.tokenSelected === "ETH" ||
                      project?.tokenSelected === "WBTC" ? (
                        <td>
                          {project?.amountInvested?.toLocaleString("en-US", {
                            minimumFractionDigits: 5,
                            maximumFractionDigits: 5,
                          })}{" "}
                          {project?.tokenSelected}
                        </td>
                      ) : (
                        <td>
                          {project?.amountInvested?.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{" "}
                          {project?.tokenSelected}
                        </td>
                      )}
                      {/* <td>{project?.amountInvested?.toLocaleString("en-US", {
                                                minimumFractionDigits: 5,
                                                maximumFractionDigits: 5,
                                            })} {project?.tokenSelected}</td> */}
                      <td>
                        {projectdetail?.projectInfo[0]?.projectName ==
                        "MINER" ? (
                          <>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {parseFloat(project?.nftAmounts[0]) +
                                parseFloat(project?.nftAmounts[1]) +
                                parseFloat(project?.nftAmounts[2])}
                              <div class="dropdown dropdownnodesminers">
                                <button
                                  class="dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="19"
                                    viewBox="0 0 18 19"
                                    fill="none"
                                  >
                                    <path
                                      d="M9 17.249C13.1421 17.249 16.5 13.8912 16.5 9.74902C16.5 5.60689 13.1421 2.24902 9 2.24902C4.85786 2.24902 1.5 5.60689 1.5 9.74902C1.5 13.8912 4.85786 17.249 9 17.249Z"
                                      stroke="#77868B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M9 12.749V9.74902"
                                      stroke="#77868B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M9 6.74902H9.00833"
                                      stroke="#77868B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </button>
                                <ul
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <div className="twice">
                                    <p>Mini</p>
                                    <h6>{project?.nftAmounts[0]}</h6>
                                  </div>
                                  <div className="twice">
                                    <p>Standard</p>
                                    <h6>{project?.nftAmounts[1]}</h6>
                                  </div>
                                  <div className="twice">
                                    <p>Mega</p>
                                    <h6>{project?.nftAmounts[2]}</h6>
                                  </div>
                                </ul>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>{project?.nftAmounts[0]}</>
                        )}
                      </td>
                      {projectdetail?.projectInfo[0]?.projectName == "MINER" ? (
                        <>
                          <td>
                            <div>
                              {project?.isInsured ? (
                                <span style={{ color: "var(--btn-green-bg)" }}>
                                  Yes
                                </span>
                              ) : (
                                <span style={{ color: "#F7931A" }}>No</span>
                              )}
                            </div>
                          </td>
                          <td>
                            {project?.isInsured ? (
                              "--"
                            ) : (
                              <button
                                className="btn-green p-3"
                                style={{ border: "none" }}
                                type="button"
                                onClick={() => {
                                  handleShow2Insurance1();
                                  setInsuranceData(project);

                                  if (project?.tokenSelected == "ETH") {
                                    getEthBalance(account);
                                  } else {
                                    getTokenBalance(project?.tokenSelected);
                                    getAllowance(project?.tokenSelected);
                                  }

                                  if (
                                    project?.tokenSelected != "USDT" &&
                                    project?.tokenSelected != "USDC"
                                  ) {
                                    getPrice(project?.tokenSelected);
                                  } else {
                                    setPrice(1);
                                  }
                                }}
                              >
                                Get Gems Protect
                              </button>
                            )}
                          </td>
                        </>
                      ) : null}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="custom-pagination">
              <p>
                SHOWING {(currentPage - 1) * 10 + 1}-
                {Math.min(currentPage * 10, totalCount)} OF {totalCount}
              </p>
              <ul className="pagination">
                {/* Previous Button */}
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <a
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                    href="#"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <g opacity="0.5">
                        <path
                          d="M7.88904 1.93555L5.38763 1.93555L1.99459 7.99682L4.496 7.99682L7.88904 1.93555Z"
                          fill="#939FA3"
                        />
                        <path
                          d="M7.88904 14.0703L5.38763 14.0703L1.99459 8.00904L3.57108 6.96117L4.496 8.00904L7.88904 14.0703Z"
                          fill="#939FA3"
                        />
                        <path
                          d="M8.01655 6.85742H14.9616L13.6869 9.14313H6.74182L8.01655 6.85742Z"
                          fill="#939FA3"
                        />
                      </g>
                    </svg>
                  </a>
                </li>

                {/* Page Numbers */}
                {Array.from({ length: totalPages }, (_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                      href="#"
                    >
                      {index + 1}
                    </a>
                  </li>
                ))}

                {/* Next Button */}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                    href="#"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <path
                        d="M9.11108 1.93555L11.6125 1.93555L15.0055 7.99682L12.5041 7.99682L9.11108 1.93555Z"
                        fill="#30F1B6"
                      />
                      <path
                        d="M9.11108 14.0703L11.6125 14.0703L15.0055 8.00904L13.429 6.96117L12.5041 8.00904L9.11108 14.0703Z"
                        fill="#30F1B6"
                      />
                      <path
                        d="M3.31318 6.85742H10.2582L8.98351 9.14313H2.03845L3.31318 6.85742Z"
                        fill="#30F1B6"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Modal
        className="common-modal-style getinsurance-modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Get Gems Protect</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="getinusrance">
            <div className="main-img">
              <img
                src="\assets\claim\getinsurance.png"
                alt="img"
                className="img-fluid"
              />
            </div>
            <div className="bottom-content">
              <div className="inline-elem">
                <h6>Purchase Amount</h6>
                <h4>
                  {insuranceData?.tokenSelected === "BTC" ||
                  insuranceData?.tokenSelected === "ETH" ||
                  insuranceData?.tokenSelected === "WBTC" ? (
                    <td>
                      {insuranceData?.amountInvested?.toLocaleString("en-US", {
                        minimumFractionDigits: 5,
                        maximumFractionDigits: 5,
                      })}{" "}
                      {insuranceData?.tokenSelected}
                    </td>
                  ) : (
                    <td>
                      {insuranceData?.amountInvested?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      {insuranceData?.tokenSelected}
                    </td>
                  )}
                  <span>
                    ($
                    {(insuranceData?.amountInvested * price)?.toLocaleString(
                      "en-US",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                    )
                  </span>
                </h4>
              </div>
              <div className="inline-elem">
                <h6>Gems Protect (5%)</h6>
                <h4>
                  {insuranceData?.tokenSelected === "BTC" ||
                  insuranceData?.tokenSelected === "ETH" ||
                  insuranceData?.tokenSelected === "WBTC" ? (
                    <td>
                      {(0.05 * insuranceData?.amountInvested)?.toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 5,
                          maximumFractionDigits: 5,
                        }
                      )}{" "}
                      {insuranceData?.tokenSelected}
                    </td>
                  ) : (
                    <td>
                      {(0.05 * insuranceData?.amountInvested)?.toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}{" "}
                      {insuranceData?.tokenSelected}
                    </td>
                  )}{" "}
                  <span>
                    {" "}
                    ($
                    {(
                      0.05 *
                      insuranceData?.amountInvested *
                      price
                    )?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    )
                  </span>
                </h4>
              </div>
              <div className="inline-elem">
                <h6>Total</h6>
                <h4>
                  {insuranceData?.tokenSelected === "BTC" ||
                  insuranceData?.tokenSelected === "ETH" ||
                  insuranceData?.tokenSelected === "WBTC" ? (
                    <td>
                      {(0.05 * insuranceData?.amountInvested)?.toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 5,
                          maximumFractionDigits: 5,
                        }
                      )}{" "}
                      {insuranceData?.tokenSelected}
                    </td>
                  ) : (
                    <td>
                      {(0.05 * insuranceData?.amountInvested)?.toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}{" "}
                      {insuranceData?.tokenSelected}
                    </td>
                  )}{" "}
                  <span>
                    ($
                    {(
                      0.05 *
                      insuranceData?.amountInvested *
                      price
                    )?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    )
                  </span>
                </h4>
              </div>
            </div>
          </div>
          <div className="common-twice-btns">
            <button
              className="cancle-btn"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </button>
            <button
              className="confirm-btn"
              onClick={() => {
                GetInsuranceCall();
              }}
            >
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="common-modal-style success-modal"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Success!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="88"
            height="88"
            viewBox="0 0 88 88"
            fill="none"
          >
            <path
              d="M76.583 37.9845L76.583 11.417L35.1296 51.7939L10.417 34.7697L10.417 59.3998L35.1296 77.583L76.583 37.9845Z"
              fill="#D1DADD"
            />
          </svg>
          <h6>Gems Protect Purchased</h6>
          <p className="mb-5">Gems protect has been successfully purchased</p>
          <button
            className="confirm-btn"
            onClick={() => {
              handleClose1();
            }}
          >
            Okay
          </button>
        </Modal.Body>
      </Modal>

      <Modal
        className="common-modal-style connectwallet-modal nooreee learnmore-modal"
        show={show2insurance1}
        onHide={handleClose2Insurance1}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="disclaimerdiv">
            <div className="darktext">
              <div className="scrolltext">
                <p className="darkparaupper text-center">
                  GEMS NODES AND GEMS MINERS <br />
                  TERMS & CONDITIONS
                </p>
                <p className="darkpara text-center">
                  Last updated: 04 . 02 . 25
                </p>
                <p className="darkpara">
                  Please carefully read these terms and conditions before
                  receiving any tokens from us and or our affiliates and or any
                  other person and accepting them as they affect your
                  obligations and legal rights, including, but not limited to,
                  waivers of rights and limitations of liability. If you do not
                  agree with these terms and conditions or if you are a
                  prohibited person, then you are not permitted to receive the
                  tokens.
                </p>
                <p className="darkpara">
                  By receiving tokens or any other form of reword from us, you
                  acknowledge that you have fully read, understand and
                  irrevocably accept and agree to be bound by these terms and
                  conditions. You must also monitor the website for any
                  announcements from us as they may add to, or change, these
                  terms and conditions from time to time.
                </p>
                <p className="darkpara">
                  You acknowledge and understand that the tokens do not
                  represent or confer any ownership right or stake, share, or
                  equivalent rights, or any right to receive intellectual
                  property rights in or relating to us. The tokens are not
                  intended to be or to represent a stock, a loan contract, a
                  commodity, a currency, a share, an instrument creating or
                  acknowledging indebtedness, an instrument giving entitlements
                  to securities, a certificate representing certain securities,
                  an option, a future or a contract for difference or in any
                  permitted jurisdictions.
                </p>
                <p className="darkpara">
                  1.
                  <span className="headingtext formarginleft">Definitions</span>
                </p>
                <p className="darkpara">
                  <span className="bold">“Affiliates”</span> means with respect
                  to any specified person, any director, officer, partner,
                  member, agent, advisor or employee of such person and any
                  other person that, directly or indirectly, through one or more
                  intermediaries, controls, is controlled by, or is under common
                  control with, such specified person, and for purposes of this
                  definition “control” (including, with correlative meanings,
                  the terms, “controlled by” and “under common control with”),
                  as used with respect to any person, means the possession,
                  directly or indirectly, of the power to direct or cause the
                  direction of the management or policies of such person,
                  whether through the ownership of voting securities, by
                  contract or otherwise.
                </p>

                <p className="darkpara">
                  <span className="bold">“Airdrop”</span> means the distribution
                  of the token to eligible users of the Platform at no
                  additional cost.
                </p>

                <p className="darkpara">
                  <span className="bold">“Applicable law”</span> means the
                  applicable laws, acts, statutes, ordinances, rules,
                  regulations, judgments, injunctions, orders, treaties,
                  sanctions, administrative acts and decrees of any relevant
                  jurisdiction.
                </p>

                <p className="darkpara">
                  <span className="bold">“Blockchain”</span> means a type of
                  distributed ledger, comprised of immutable, digitally
                  recorded, data in packages called blocks.
                </p>

                <p className="darkpara">
                  <span className="bold">“Eligible User”</span> means members of
                  the “Gems” community who are determined to be eligible for an
                  allocation of the tokens based on criteria determined by us in
                  its sole discretion, but at all times shall exclude any
                  prohibited person.
                </p>

                <p className="darkpara">
                  <span className="bold">“Gems Nodes”</span> means your computer
                  which is connected to other computers which follows rules and
                  shares information which is based on Gems protocol encrypted
                  on the Blockchain.
                </p>

                <p className="darkpara">
                  <span className="bold">“Gems Miners”</span> means your
                  computer which is engaged in critical process in Gems networks
                  and or community and which is involved verifying transactions
                  and adding them to the Blockchain.
                </p>

                <p className="darkpara">
                  <span className="bold">“Governmental Authority”</span> means
                  any nation or government, any state or other political
                  subdivision thereof, any entity exercising legislative,
                  judicial or administrative functions of or pertaining to
                  government, including, without limitation, any government
                  authority, agency, department, board, commission or
                  instrumentality, and any court, tribunal or arbitrator(s) of
                  competent jurisdiction, and any self- regulatory organisation.
                </p>

                <p className="darkpara">
                  <span className="bold">“Information Materials”</span> means
                  documents or other materials issued by us in connection with
                  the tokens from time to time.
                </p>

                <p className="darkpara">
                  <span className="bold">“Us” or “We”</span> means Gems Labs
                  limited, a British Virgin Islands business company with
                  limited liability, which is the entity owning Rocks Platform
                  initiating the Services subject to these T&Cs.
                </p>

                <p className="darkpara">
                  <span className="bold">“Parties”</span> means us and you.
                </p>

                <p className="darkpara">
                  <span className="bold">“Person”</span> means an individual or
                  legal entity or person, including, without limitation, a
                  governmental authority or an agency or instrumentality
                  thereof.
                </p>

                <p className="darkpara">
                  <span className="bold">“Proceedings”</span> means any
                  disputes, claims, suits, actions, causes of action, demands,
                  procedures or proceedings.
                </p>

                <p className="darkpara">
                  <span className="bold">“Rocks Platform”</span> or the
                  “Platform” means a streamlined platform in the GEMS ecosystem
                  which was created in order to facilitate Token launches,
                  operating on the BASE network.
                </p>
                <p className="darkpara">
                  <span className="bold">“T&Cs”</span> means these terms and
                  conditions, including without limitation, all information
                  materials, and any other rules, policies or procedures that
                  may be issued by any member of us and published from time to
                  time on the website, each as amended from time to time and at
                  any time in accordance with the provisions herein.
                </p>
                <p className="darkpara">
                  <span className="bold">“Token”</span> means cryptographic
                  tokens, as described further in the information materials, and
                  which do not belong to us and or created by us.
                </p>
                <p className="darkpara">
                  <span className="bold">“Token Generation Event (TGE)”</span>{" "}
                  means the event marking the start of token generation and
                  distribution for a certain token.
                </p>
                <p className="darkpara">
                  <span className="bold">“You”, “Your” or “Yourself”</span>{" "}
                  means any person who from time to time and at any time (i)
                  proposes to acquire tokens from us or any third party; or (ii)
                  holds tokens.
                </p>
                <p className="darkpara">
                  <span className="bold">“Website”</span> means any website URL
                  used to allow eligible users to receive the tokens allocated
                  to them under the airdrop (as may be updated from time to
                  time).
                </p>
                <p className="darkpara">
                  as used herein, any reference to the “use” of the tokens
                  includes, without limitation, any holding, transferring,
                  exchanging and/or selling of the tokens or the grant of any
                  security over the tokens and a reference to “using” the tokens
                  shall be construed accordingly.
                </p>

                <p className="darkpara">
                  2.
                  <span className="headingtext formarginleft">
                    Terms and Conditions, Status and Acceptance
                  </span>
                </p>

                <p className="darkpara">
                  These T&Cs constitute a legally binding obligation on you
                  effective upon the earlier to occur of the date and time: (i)
                  you click the check box on the website to indicate that you
                  have read, understand and agree to these T&Cs; or (ii) you
                  receiving any tokens allocated to you as part of the airdrop.
                  These T&Cs define the rights and obligations of the parties in
                  relation to the tokens and your receipt of the tokens. You
                  must carefully read and agree to comply with these T&Cs before
                  receiving and/or using the tokens. By receiving tokens, you
                  are confirming that you have fully read, understand and
                  irrevocably accept these T&Cs. If you do not agree with these
                  T&Cs in general or any part of them or have not acknowledged
                  your review and acceptance of these T&Cs, you are not
                  permitted to receive tokens.
                </p>

                <p className="darkpara">
                  3.
                  <span className="headingtext formarginleft">
                    The Services
                  </span>
                </p>
                <p className="darkpara">
                  We provide you with innovative community building tools
                  through Gems Nodes and Gems Miners subject to fees as
                  stipulated on our website. We serve as essential components
                  for community engagement and token distribution via the
                  Platform, while offering benefits such as token airdrops,
                  additional allocations, and rewards.
                </p>

                <p className="darkpara">
                  4.
                  <span className="headingtext formarginleft">Limitations</span>
                </p>
                <p className="darkpara">
                  Tokens are only intended for those persons who are
                  knowledgeable and experienced in cryptocurrencies, blockchain
                  and related technologies and protocols. By receiving the
                  tokens, you acknowledge that transactions using
                  cryptocurrencies (including cryptographic tokens) are
                  inherently unstable and you agree to accept that risk, and
                  agree that we is not liable for any loss that you may suffer
                  or incur, and further acknowledge, accept and assume all risks
                  associated with the tokens including, without limitation,
                  those identified in clause 6 of these T&Cs and in the
                  information materials. Tokens are intended to be marketed,
                  allocated and received by only to persons that are not
                  prohibited persons. The airdrop is expressly being made in the
                  permitted jurisdictions on the basis that the tokens do not
                  require that a prospectus be prepared or that other disclosure
                  or registration requirements be met or where other investor
                  safeguards or regulatory documents or licensing is required in
                  connection with the making available of the tokens by the user
                  in the permitted jurisdictions.
                </p>

                <p className="darkpara">
                  The tokens are not intended to be or to represent a stock, a
                  loan contract, a commodity, a currency, a share, an instrument
                  creating or acknowledging indebtedness, an instrument giving
                  entitlements to securities, a certificate representing certain
                  securities, an option, a future or a contract for difference
                  or in any permitted jurisdictions. Tokens are not intended to
                  be securities and shall not in any case be considered as such
                  and the offer of tokens has not been registered with any
                  governmental authority. You acknowledge and agree that tokens
                  do not represent any stock, loan contract, commodity,
                  currency, share, instrument creating or acknowledging
                  indebtedness, instrument giving entitlements to securities,
                  certificate representing certain securities, option, future or
                  contract for difference or right to receive intellectual
                  property rights, or any voting or governance rights or any
                  other right to influence the our development or our operation
                  and do not represent any ownership right in us. However,
                  without limitation to the above, we us reserves all rights
                  with respect to pursuing any form of decentralised governance
                  and shall do so in its sole and absolute discretion.
                </p>
                <p className="darkpara">
                  We reserve the right to cancel any tokens allocated to you in
                  connection with any airdrop, TGE or Rocks Platform
                  distribution at any time prior to your receipt of the tokens
                  as such is in our sole and absolute discretion and without
                  prior notice and without any liability or further obligation
                  of any kind whatsoever to you or any other party, in the event
                  us finds such measures reasonable and/or necessary in a
                  particular situation, including, but not limited to, change of
                  regulatory requirements, or upon suspicion or detection that
                  you do not primarily reside or are not domiciled in a
                  permitted jurisdiction or are engaged in fraud or other
                  illegal activity.
                </p>
                <p className="darkpara">
                  Certain jurisdictions expressly prohibit or restrict the
                  distribution of cryptocurrencies and/or cryptographic tokens,
                  while other jurisdictions may require us and/or the tokens to
                  be licensed, registered, authorised or otherwise regulated.
                  The tokens may be deemed to be securities for purposes of
                  securities laws in various jurisdictions such that the offer
                  or sale of tokens by us in such jurisdictions may require
                  registration or other steps to be taken with the relevant
                  regulatory authorities in those jurisdictions or for an
                  exemption from such registration or other steps being a
                  requirement. No such steps have been taken by us nor has any
                  such relevant exemption been confirmed. Some other
                  jurisdictions have or may have been excluded from the token
                  sale for other reasons, as determined by us in its sole and
                  absolute discretion. Persons (natural or legal) who are a
                  resident or tax resident, have a domicile in or otherwise have
                  a relevant connection with any prohibited jurisdiction are
                  excluded from participating in the token sale and possessing
                  and using a token. Tokens may not be distributed or made
                  available directly or indirectly to any prohibited person and
                  neither these T&Cs nor any information materials may be
                  supplied to any prohibited person, or used in connection with
                  the distribution or making available of the tokens by us to
                  any prohibited person. For the avoidance of doubt, the list of
                  prohibited jurisdictions may be changed from time to time,
                  irrespective of our awareness us and relevant amendments may
                  be made to these T&Cs. You are only permitted to use the
                  website and receive tokens if you are not a prohibited person.
                  To the extent a prohibited person attempts to enter into these
                  T&Cs, receive tokens under the airdrop, such purported
                  activity is void and of no force or effect.
                </p>

                <p className="darkpara">
                  5.
                  <span className="headingtext formarginleft">General</span>
                </p>

                <p className="darkpara">
                  You shall not acquire or seek to receive any tokens if you are
                  a prohibited person or are otherwise not an eligible user. Us
                  may change, modify, amend, alter or supplement these T&Cs
                  (each an “amendment”) at any time in order to reflect (i)
                  changes to applicable law that may be, or which may otherwise
                  become, applicable to the tokens, (ii) any developments that
                  may otherwise reasonably be capable of materially adversely
                  impacting the tokens or its award by us; or (iii) as we may in
                  good faith deem advisable to protect our reputation. These
                  T&Cs do not constitute a sale of newly created virtual assets
                  to the public under applicable laws. One of the information or
                  analyses presented herein, therein or thereon are intended to
                  form the basis for any investment decision. You enter into
                  these T&Cs based on your own independent judgement and on
                  advice from independent advisers (as applicable). Subject to
                  applicable laws, each project which is a member of Rocks
                  Platform reserves the right, in their respective sole and
                  absolute discretion, to refuse to allow you to receive any
                  tokens where there is a change in applicable laws or where any
                  member of the Rocks Platform suspects that you are engaging in
                  illegal activities. In order to seek compliance with (or to
                  seek to mitigate the impact of) any applicable law or any
                  other laws, statutes, ordinances, rules, regulations,
                  judgments, injunctions, orders, treaties, administrative acts
                  or decrees of any nation or governmental authority, any state
                  or other political subdivision thereof, any entity exercising
                  legislative, judicial or administrative functions of or
                  pertaining to government, including, without limitation, any
                  governmental authority, agency, department, board, commission
                  or instrumentality, and any court, tribunal or arbitrator(s)
                  of competent jurisdiction, believes that you applied to or
                  affect us or the tokens. In addition, us we may take such
                  steps as they consider necessary or convenient where they
                  believe or suspect the tokens may be used, trafficked or
                  applied in the attempted furtherance of money laundering,
                  terrorist financing, tax evasion or other unlawful activity.
                </p>

                <p className="darkpara">
                  6.
                  <span className="headingtext formarginleft">
                    Risks of Tokens and Limitations of Liabilities
                  </span>
                </p>

                <p className="darkpara">
                  You understand and acknowledge that tokens, blockchain-based
                  technologies and other associated and related technologies are
                  not exclusively (or, as appropriate, at all) controlled by us
                  and adverse changes in market forces or the technology,
                  broadly construed, may prevent or compromise the Platform’s
                  performance under these T&Cs. As such, the purchase of tokens
                  carries with it a number of risks. Prior to receiving tokens,
                  you should carefully consider the risks listed herein and in
                  the information materials and, to the extent necessary,
                  consult an appropriate lawyer, accountant, or tax
                  professional. If any of the risks associated with receiving
                  and holding tokens is unacceptable to you, you should not
                  receive tokens. you acknowledge, agree and assume all risks
                  associated with these T&Cs and the tokens including, without
                  limitation, those risks disclosed in the information
                  materials. There may be additional risks that cannot be
                  anticipated or foreseen due to the incipience of cryptographic
                  token technology, blockchain-based technology and related
                  technologies.
                </p>

                <p className="darkpara">
                  7.
                  <span className="headingtext formarginleft">
                    Disclaimer of Warranties
                  </span>
                </p>

                <p className="darkpara">
                  The tokens are provided to the fullest extent legally
                  permissible to you “as is” and on an “as available” basis with
                  no representation or warranty of any kind either, statutory,
                  expressed or implied, including, but not limited to, the
                  implied representations or warranties of title,
                  non-infringement, merchantability, and fitness for a
                  particular purpose. You assume all responsibility and risk
                  with respect to your acquisition of the Services. Us or any of
                  their respective affiliates or advisers make any warranty or
                  representation with respect to the completeness, security,
                  reliability, quality, accuracy, or availability of smart
                  contracts associated with the tokens, including that your
                  access to such smart contracts will be reliable, error-free,
                  or uninterrupted, that defects will be corrected. You
                  understand and expressly agree that none, of us or any of our
                  respective affiliates or advisers represents, warrants or
                  guarantees in any way that tokens might be sold or
                  transferred, or be saleable or transferable, or there is an
                  ability or will be a platform to exchange tokens for fiat
                  currencies, cryptocurrencies or cryptographic tokens, during
                  or after the TGE. We Us further does not make any
                  representations or warranties with respect to the regulatory
                  oversight or the use or security of any such exchange.
                </p>

                <p className="darkpara">
                  8.
                  <span className="headingtext formarginleft">Indemnity</span>
                </p>

                <p className="darkpara">
                  You do hereby to the fullest extent permitted by applicable
                  law indemnify, defend and hold us and each other relevant
                  party harmless from and against any and all loss, penalty,
                  claim, damage, liability or expense whatsoever (including
                  reasonable attorneys’ fees and disbursements) due to or
                  arising out of or based upon (i) any inaccurate representation
                  or warranty made by you, or breach or failure by you to comply
                  with any covenant or agreement made by you in these T&Cs or in
                  any other document furnished by you to any of the foregoing in
                  connection with this transaction, or (ii) any action
                  instituted by or on behalf of you against us or any other
                  relevant party that is finally resolved by judgment against
                  you or in favour of us or any other relevant party.
                </p>

                <p className="darkpara">
                  9.
                  <span className="headingtext formarginleft">
                    Miscellaneous
                  </span>
                </p>

                <p className="darkpara">
                  <span className="bold">Third Party Rights.</span> You hereby
                  acknowledge and agree that each relevant party is an intended
                  third-party beneficiary under these T&Cs (and we us shall hold
                  the benefit of such provisions on trust for each such relevant
                  party). However, the parties to these T&Cs may rescind or vary
                  these T&Cs (including, without limitation, any variation so as
                  to extinguish or alter a third party's entitlement to enforce
                  any provisions of these T&Cs) without the consent of any such
                  third party.
                </p>

                <p className="darkpara">
                  <span className="bold">Security.</span> You must at all times
                  remain the only person who has control over your private key,
                  digital wallet and any other device associated with the
                  purchase of tokens and any username, passwords or other login
                  or identifying credentials used by you with respect to the
                  tokens. you must implement reasonable and appropriate measures
                  designed to secure access to any private key, digital wallet
                  or any other device associated with the receipt of tokens. If
                  you transfer any such private key, digital wallet or any other
                  device associated with the purchase of tokens to any third
                  party, you do so at your own risk and we shall not be held
                  responsible for any loss you may suffer as a result of third
                  parties accessing your private key, digital wallet or any
                  other device associated with the receipt of tokens.
                </p>

                <p className="darkpara">
                  <span className="bold">Suspension.</span> Notwithstanding
                  anything contained herein, we us reserves the right, without
                  notice and in its sole and absolute discretion, to suspend
                  your ability to receive the tokens, and without liability to
                  you, at its sole and absolute discretion, including but not
                  limited to, in case of your breach of these T&Cs or if we
                  believes you have committed fraud or other misconduct or are a
                  prohibited person.
                </p>

                <p className="darkpara">
                  <span className="bold">Entire Agreement.</span> Unless
                  otherwise provided, these T&Cs are intended to fully reflect
                  the terms of the agreement between the parties, and shall
                  supersede any previously or contemporaneously agreed upon
                  terms or understanding. no provision of these T&Cs shall be
                  considered waived unless such waiver is in writing and signed
                  by the party that benefits from the enforcement of such
                  provision. No waiver of any provision in these T&Cs, however,
                  will be deemed a waiver of a subsequent breach of such
                  provision or a waiver of a similar provision. in addition, a
                  waiver of any breach or a failure to enforce any term or
                  condition of these T&Cs will not in any way affect, limit, or
                  waive a party’s rights hereunder at any time to enforce strict
                  compliance thereafter with every term and condition hereof.
                </p>

                <p className="darkpara">
                  <span className="bold">Assignment.</span> We may, at its sole
                  and absolute discretion, assign any of its rights and/or
                  delegate its duties under these T&Cs. you may not assign your
                  rights or delegate right to receive any tokens, and any
                  assignment or delegation in breach of the foregoing shall be
                  null and void.
                </p>

                <p className="darkpara">
                  <span className="bold">severability.</span> in the event any
                  one or more of the provisions of these T&Cs are for any reason
                  held to be invalid, illegal or unenforceable in any
                  jurisdiction, in whole or in part or in any respect, or in the
                  event that any one or more of the provisions of these T&Cs
                  operate or would prospectively operate to invalidate these
                  T&Cs in any jurisdiction, then and in any such event, such
                  provision(s) shall be deemed modified to the minimum extent
                  necessary so that such provision, as so modified, shall no
                  longer be held to be invalid, illegal or unenforceable. any
                  such modification, invalidity or unenforceability shall be
                  strictly limited both to such provision and to such
                  jurisdiction, and in each case to no other. furthermore, in
                  the event of any such modification, invalidity or
                  unenforceability, these T&Cs shall be interpreted so as to
                  achieve the intent expressed herein to the greatest extent
                  possible in the jurisdiction in question and otherwise as set
                  forth herein.
                </p>

                <p className="darkpara">
                  <span className="bold">
                    Electronic Notices and use of Information.
                  </span>{" "}
                  you agree and consent to receive electronically all
                  communications, agreements, documents, receipts, notices and
                  disclosures (hereinafter - the{" "}
                  <span className="bold">“Communications”)</span>. Us WE and OUR
                  respective service providers may further disclose your
                  information to any of their respective service providers,
                  agents, relevant custodians or similar third parties for any
                  reason and such persons may keep your information for any
                  period of time permitted by applicable law.
                </p>

                <p className="darkpara">
                  <span className="bold">Tax Issues.</span> us We makes no
                  representations concerning the tax implications of the receipt
                  of tokens or the possession or use of them. you bear the sole
                  and absolute responsibility to determine if the potential
                  appreciation or depreciation in the value of tokens over time
                  has tax implications for you in your home jurisdiction or any
                  other jurisdiction. By receiving tokens, and to the extent
                  permitted by applicable law, you agree to be solely
                  responsible for any applicable taxes imposed on, and agree not
                  to hold us liable for any tax liability associated with or
                  arising from your acquisition, possession, or transfer of
                  tokens.
                </p>

                <p className="darkpara">
                  <span className="bold">limited Recourse:</span>{" "}
                  Notwithstanding any other provision of these T&Cs, any our
                  obligations hereunder are limited recourse of our obligations
                  of us. all obligations of and any claims against us hereunder
                  or in connection herewith after such realisation shall be
                  extinguished and shall not thereafter revive. No recourse
                  shall be had against any of ours officers, directors,
                  partners, employees, shareholders or incorporators.
                </p>
              </div>
            </div>
            <div className="checkboxmainnnnnn">
              <label className="checkbox m-0" htmlFor="ch1">
                <input
                  type="checkbox"
                  id="ch1"
                  checked={isChecked1}
                  onChange={handleCheckboxChange1}
                />
                <span className="checkmark"></span>
              </label>
              <p className="checkboxpara">
                I confirm that I have read and understand the Terms & Conditions
              </p>
            </div>

            <div className="checkboxmainnnnnn">
              <label className="checkbox m-0" htmlFor="ch2">
                <input
                  type="checkbox"
                  id="ch2"
                  checked={isChecked2}
                  onChange={handleCheckboxChange2}
                />
                <span className="checkmark"></span>
              </label>
              <p className="checkboxpara">I agree to the Terms & Conditions</p>
            </div>

            <button
              className={
                !(isChecked1 && isChecked2)
                  ? "continuebutton-disabled"
                  : "continuebutton"
              }
              disabled={!(isChecked1 && isChecked2)}
              onClick={() => {
                handleClose2Insurance1();
                handleShow();
              }}
            >
              Add Gems Protect
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AllPurchasesNodeMiner;
