import React, { useEffect, useState } from "react";
import "./gemscommunity.scss";
import Navbar from "../landing/header/Navbar";
import { Link } from "react-router-dom";
import Timer from "./Timer";
import axios from "axios";
import { API_URL_SURVEY } from "../../utils/ApiUrl";
import Loader from "../../hooks/loader";
import ClaimButton from "./Timer";
import useAuth from "../../hooks/useAuth";
import { useWeb3React } from "@web3-react/core";
import { Modal } from "react-bootstrap";
import Signature from "../../hooks/userSign";
import { browserName, isBrowser } from "react-device-detect";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const GemsCommunityProject = () => {
  let { account, chainId } = useWeb3React();
  const { login, logout } = useAuth();
  const [checked, setChecked] = useState(false);
  const [showwallet, setShowWallet] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [error, setError] = useState(false);
  const [canShowSign, setCanShowSign] = useState(false);

  const history = useHistory();

  const { userSign } = Signature();

  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [showSign, setShowSign] = useState(false);
  const hideSignModal = () => {
    setShowSign(false);
    handleShow();
  };
  const showSignModal = () => setShowSign(true);

  const handleShowWallet = () => setShowWallet(true);
  const handleCloseWallet = () => {
    setShowWallet(false);
  };

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  if (id) {
    localStorage.setItem("referalId", id);
  }
  console.log(id);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };
  const [checked1, setChecked1] = useState(false);
  // const [participants, setParticipants] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleCheckboxChange1 = () => {
    setChecked1(!checked1);
  };

  // const getStats = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await axios.get(`${API_URL_SURVEY}/users/stats`);
  //     console.log(response);
  //     setParticipants(response?.data?.data?.participants);
  //   } catch (error) {
  //     if (error?.response) {
  //       console.error("Error fetching stats:", error?.response?.data?.message);
  //     } else {
  //       console.error("Error fetching stats:", error?.message);
  //     }
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   getStats();
  // }, []);

  const getBearerToken = async () => {
    if (account) {
      try {
        setLoading(true);

        const sign = await userSign();
        if (sign) {
          const payload = {
            walletAddress: account?.toLowerCase(),
            sign: sign?.toLowerCase(),
          };

          console.log(payload);

          const response = await axios.post(
            `${API_URL_SURVEY}/auth/users/signin`,
            payload,
            { headers: { "Content-Type": "application/json" } }
          );
          console.log(response);
          if (response) {
            const data = response?.data?.data;
            const user = response?.data?.data?.user;
            localStorage.setItem("accessToken", data?.accessToken);
            localStorage.setItem("signerAccount", account);
            localStorage.setItem("refreshToken", data?.refreshToken);
            setUserData(data?.user);
            if (
              user.followGemsCommunityOnTwitter &&
              user.followGemsOnTwitter &&
              user.followUsOnTelegram &&
              user.isEmailVerified &&
              user.isReferrelCompleted &&
              user.isSocialTaskCompleted &&
              user.isSurveyCompleted &&
              user.isTwitterVerified &&
              user.postOnTwitter
            ) {
              setIsCompleted(true);
              history.push({
                pathname: "/survey",
                state: { isCompleted: true },
              });
            } else {
              setIsCompleted(false);
              setError(true);
              // toast.error(
              //   "Seems like you did not complete all your steps in time"
              // );
              hideSignModal();
              handleShow();
            }
          }
        }
      } catch (err) {
        setIsCompleted(false);
        setError(true);

        // console.error("Error in getBearerToken():", err.message);
        if (err?.response) {
          console.log("Error in getBearerToken():", err?.response);
          // toast.error(err.response.data.message);
          if (err.response.data.message === "User not found") {
            // toast.error("Seems like you are late to the party");
            hideSignModal();
            handleShow();
          }
        } else {
          toast.error(err.message);
        }
      } finally {
        setLoading(false);
      }
    } else {
      console.log("No wallet connected");
      toast.error("Wallet not found! Connect again for sign");
      handleCloseWallet();
      hideSignModal();
    }
  };

  const connectWallet = async (e) => {
    try {
      if (account) {
        const connectorId = window.localStorage.getItem("connectorId");
        await logout(connectorId);
        localStorage.removeItem("connectorId");
        localStorage.removeItem("flag");
      } else {
        await login("injected", e);
        localStorage.setItem("connectorId", "injected");
        localStorage.setItem("flag", "true");
      }
    } catch (error) {
      console.error("Error during WalletConnect operation:", error);
      handleCloseWallet();
      handleShow();
    }
    // setLoader(false);
  };

  const trustWallet = async () => {
    try {
      if (account) {
        await logout("walletconnect");
        localStorage.removeItem("connectorId");
        localStorage.removeItem("flag");
        localStorage.removeItem("accessToken");
      } else {
        // await setupNetwork();
        await login("walletconnect");
        localStorage.setItem("connectorId", "walletconnect");
        localStorage.setItem("flag", "true");
      }
    } catch (error) {
      console.error("Error during WalletConnect operation:", error);
      handleCloseWallet();
      handleShow();
    } finally {
    }
  };

  const coinbase = async () => {
    try {
      if (account) {
        await logout("coinbaseWallet");
        localStorage.removeItem("connectorId");
        localStorage.removeItem("flag");
        localStorage.removeItem("accessToken");
      } else {
        // await setupNetwork();
        await login("coinbaseWallet");
        localStorage.setItem("connectorId", "coinbaseWallet");
        localStorage.setItem("flag", "true");
      }
    } catch (error) {
      console.error("Error during coinbaseWallet operation:", error);
      handleCloseWallet();
      handleShow();
    } finally {
    }
  };

  const [coinbaseLink, setCoinbaseLink] = useState("");

  useEffect(() => {
    const referalCode = localStorage.getItem("referalId");
    if (referalCode) {
      setCoinbaseLink(
        `https://gems.vip/gemscommunityproject/?id=${referalCode}`
      );
    } else {
      setCoinbaseLink("https://gems.vip/");
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isDisabled = !(checked && checked1);

  const [pageLoaded, setPageLoaded] = useState(false);

  console.log("page loaded", pageLoaded);

  useEffect(() => {
    setPageLoaded(true);

    return () => {
      setPageLoaded(false);
    };
  }, []);

  const logouthandler = async () => {
    const connectorId = window.localStorage.getItem("connectorId");
    await logout(connectorId);
    localStorage.removeItem("connectorId");
    localStorage.removeItem("flag");
  };
  useEffect(() => {
    if (account) {
      logouthandler();
    }
  }, []);

  useEffect(() => {
    if (account && canShowSign) {
      handleClose();
      handleCloseWallet();
      handleClose1();
      showSignModal();
    }
  }, [account, canShowSign]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Navbar pageLoaded={pageLoaded} />
          <section className="gems-community">
            <img
              src="\assets\gemscommunityproject\layer-bg.png"
              alt="img"
              className="img-fluid bg-shadow hide-in-light-theme"
            />
            <img
              src="\assets\gemscommunityproject\layer-bg-light.png"
              alt="img"
              className="img-fluid bg-shadow d-none show-in-light-theme"
            />
            <video
              className="main-banner-video"
              muted="muted"
              playsinline="playsinline"
              autoPlay
              loop
              width="100%"
              id="myVideo"
            >
              <source
                src="\assets\gemscommunityproject\video-bg.mp4"
                type="video/mp4"
              />
            </video>
            <div className="custom-container">
              <div className="parent">
                <div className="main-heading">
                  <h5>
                    <span>Gems Community Project</span>
                  </h5>
                  <h4>Welcome to this thing we’re doing together!</h4>
                </div>
                <div className="mid-para">
                  <h6>We are building a community project together!</h6>
                  <p>
                    Every step you take in this quest, will impact the outcome
                    for the entire community and its token &#129309;
                  </p>
                </div>
                {/* <div className="bottom-agreement">
                  <label className="custom-checkbox-container">
                    <input
                      type="checkbox"
                      checked={checked}
                      onChange={handleCheckboxChange}
                    />
                    <span className="checkmark"></span>
                    <span className="checkbox-label">
                      I have read and agree with{" "}
                      <span style={{ fontFamily: "hsemibold" }}>Airdrop </span>
                      <Link to="/termscommunityproject" target="_blank">
                        Terms and Conditions
                      </Link>{" "}
                      and{" "}
                      <Link to="/privacy" target="_blank">
                        Privacy Policy.
                      </Link>{" "}
                    </span>
                  </label>
                  <label className="custom-checkbox-container">
                    <input
                      type="checkbox"
                      checked={checked1}
                      onChange={handleCheckboxChange1}
                    />
                    <span className="checkmark"></span>
                    <span className="checkbox-label">
                      I agree to receive communication and marketing emails from
                      Gems.
                    </span>
                  </label>
                </div>
                <Link
                  to={{ pathname: "/survey", state: { id: id } }}
                  className={`btn-green ${isDisabled ? "disabled" : ""}`}
                  aria-disabled={isDisabled} // Optional: Adds semantic accessibility for disabled state
                >
                  Start Quest
                </Link> */}
                <div className="bottom-timer"></div>
              </div>
            </div>
          </section>

          <Modal
            className="community-modal"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Body>
              <div className="mainbox">
                <h3 className="upperhead">Yoohoo!</h3>
                <p className="paramain">
                  I’m that lil’ popup that tells you our quest has ended and
                  wishes to thank you for participating… thanks!
                </p>
                <p className="paramainone">
                  If you’re here to view your dashboard after completing the
                  quest, <span> Click here</span>:
                </p>
                <button
                  className="connectbtn"
                  onClick={() => {
                    setCanShowSign(true);
                    if (account) {
                      showSignModal();
                      handleClose();
                    } else {
                      handleShowWallet();
                      handleClose();
                    }
                  }}
                >
                  Connect Wallet
                </button>
                {error && (
                  <p className="user-seeing">
                    If you're seeing this message, You haven't completed the
                    quest in time. See you next time!
                  </p>
                )}
              </div>
              <div className="mainbox">
                <h3 className="upperheadgreen">
                  And now for the age old question:
                </h3>
                <p className="paramainsec">When do we get our airdrop?</p>
                <p className="paramainsec formargin">
                  {" "}
                  We’ll post updates regarding the airdrop and the project
                  itself on our website & socials, so keep
                </p>
                <p className="paramainsec formargin">
                  {" "}
                  following and you’ll know:
                </p>
                <div className="mainsocial">
                  <a href="https://x.com/WeMakeIt2Gether" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="41"
                      height="41"
                      viewBox="0 0 41 41"
                      fill="none"
                    >
                      <rect
                        x="1"
                        y="1.20605"
                        width="39"
                        height="39"
                        rx="19.5"
                        fill="#021C24"
                      />
                      <rect
                        x="1"
                        y="1.20605"
                        width="39"
                        height="39"
                        rx="19.5"
                        stroke="#2B3E43"
                      />
                      <mask
                        id="mask0"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="12"
                        y="13"
                        width="17"
                        height="17"
                      >
                        <path d="M12 13H28.0005V29.0005H12V13Z" fill="white" />
                      </mask>
                      <g mask="url(#mask0)">
                        <path
                          d="M24.6004 13.7446H27.0541L21.694 19.8865L28.0005 28.2456H23.0632L19.1933 23.1769L14.7704 28.2456H12.3143L18.047 21.674L12 13.7458H17.063L20.5557 18.3779L24.6004 13.7446ZM23.7375 26.7736H25.0975L16.3201 15.1401H14.8618L23.7375 26.7736Z"
                          fill="#E1F5EB"
                        />
                      </g>
                    </svg>
                  </a>
                  <a href="https://t.me/WeMakeIt2Gether" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_1040_134)">
                        <path
                          d="M7.06288 11.3856L6.76513 15.5736C7.19113 15.5736 7.37563 15.3906 7.59688 15.1708L9.59413 13.2621L13.7326 16.2928C14.4916 16.7158 15.0264 16.4931 15.2311 15.5946L17.9476 2.86557L17.9484 2.86482C18.1891 1.74282 17.5426 1.30407 16.8031 1.57932L0.835633 7.69257C-0.254117 8.11557 -0.237617 8.72307 0.650383 8.99832L4.73263 10.2681L14.2149 4.33482C14.6611 4.03932 15.0669 4.20282 14.7331 4.49832L7.06288 11.3856Z"
                          fill="#E1F5EB"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1040_134">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </div>
              </div>
              <p className="endpara">Feel like you missed out?</p>
              <p className="endpara"> You probably have… for now 🤫</p>
            </Modal.Body>
          </Modal>

          <Modal
            className="common-modal-style wallet-modal"
            show={showwallet}
            onHide={handleCloseWallet}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Connect wallet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="parent">
                <a
                  onClick={() => {
                    connectWallet();
                    // setConnect(true);
                    // handleShow();
                    handleCloseWallet();
                  }}
                  className="metamask-hideonmobile"
                >
                  <img
                    src="\assets\navbar\metamask.svg"
                    alt="img"
                    className="img-fluid"
                  />
                  Metamask
                </a>
                <a
                  onClick={() => {
                    trustWallet();
                    // setConnect(true);
                    handleCloseWallet();
                  }}
                >
                  <img
                    src="\assets\navbar\wallet.svg"
                    alt="img"
                    className="img-fluid"
                  />
                  WalletConnect
                </a>
                {isBrowser && (
                  <a
                    onClick={() => {
                      coinbase();
                      // setConnect(true);
                      handleCloseWallet();
                      // handleClose1();
                      // handleShow();
                    }}
                  >
                    <img
                      src="\assets\navbar\base.svg"
                      alt="img"
                      className="img-fluid"
                    />
                    CoinBase
                  </a>
                )}
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            className="common-modal-style wallet-modal"
            show={showSign}
            onHide={hideSignModal}
            centered
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Sign Required</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="sign-required">
                <p className="upperpara text-center">
                  Please provide sign to continue
                </p>
                <button className="btn-green" onClick={getBearerToken}>
                  Sign
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default GemsCommunityProject;
